.features-slot {

    &--fullwidth {

        &.grid-container.full {

            margin-bottom: rem(32px);

        }

        &--main-content {

            z-index: 2;

        }

        .article-card {

            &--title {
                font-family: Recoleta, serif;
                font-size: 3.75rem;
                line-height: 120%;
            }

            @include breakpoint(small only) {

                &--title {
                    font-size: 3rem;
                }
            }

            &--content {
                $offset: 20px;
                $large-padding: 52px;
                background: $yellow;
                border-radius: 16px;
                margin-top: rem(-$offset);
                padding: rem(32px);

                @include breakpoint(large) {
                    margin-top: inherit;

                    height: 100%;
                    border-radius: 0 16px 16px 0;
                    margin-right: rem(-$offset);
                    padding-right: rem($large-padding);
                    padding-left: rem($large-padding);
                }
            }

            &--meta,
            &--author {

                &,
                a {
                    color: $black;
                }
            }

            &--image {
                border-radius: 0;
                margin-bottom: 0;

                &__wrapper {
                    position: relative;
                }
            }
        }

        .readmore--link {
            align-self: baseline;
        }

        .badge--text {
            background: $black;
            color: $white;
        }

        .article-card--meta__timestamp {
            display: block;
            margin-top: 8px;
            font-size: rem(14px);

            @include breakpoint(medium) {
                display: inline-block;
                margin-top: 0;
            }

            time {
                vertical-align: middle;
            }
        }

    }
}
