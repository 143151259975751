@use "sass:math";

@mixin reset-focus-ring-to-browser-default {
    outline: Highlight auto !important;
    outline: -webkit-focus-ring-color auto !important;
}

/**
* Strip the Unit from a value.
* Examples:
* strip-units(42px) => 42
* strip-units(20rem) => 20
* strip-units(50vw) => 50
* Reference: https://css-tricks.com/snippets/sass/strip-unit-function/
*/
@function strip-units($value) {

    @if unitless($value) {

        @return $value;
    }

    @return math.div($value, ($value * 0 + 1));
}

@function rem($pixels, $base: 16px) { // replace witch foundation configured value.

    @if not unitless($pixels) {
        $pixels: strip-units($pixels);
    }

    @if not unitless($base) {
        $base: strip-units($base);
    }

    @return #{math.div($pixels, $base)}rem;
}

@mixin no-text-decoration {
    text-decoration: none;

    &:hover,
    &:focus {
        text-decoration: none;
    }
}

@mixin starSvgFills($half-star-small-id, $half-star-medium-id, $half-star-large-id) {

    .stars .star.filled polygon {
        fill: $black;
    }

    .stars .star.half-star polygon {

        @include breakpoint(small) {

            fill: url($half-star-small-id) !important;
        }


        @include breakpoint(medium) {

            fill: url($half-star-medium-id) !important;
        }

        @include breakpoint(large up) {

            fill: url($half-star-large-id) !important;
        }
    }
}

@mixin flex-full-height-column {
    display: flex;
    flex-direction: column;
    height: 100%;
}

@mixin light-box-shadows {
    box-shadow: 0 5px 15px #6666664d;
}

@mixin homepage-link {
    color: $black;
    display: inline-flex;

    a {
        color: inherit;
        text-decoration: none;
        padding: rem(10px) 0;

        &:hover {
            text-decoration: underline;
        }
    }

    @include breakpoint(medium) {

        &:not(:last-child) {

            &::after {
                content: "";
                display: inline-block;
                margin: 0 2rem;
                width: 1px;
                height: 26px;
                background: #626262;
            }
        }
    }
}

@mixin side-scrollable {
    flex-flow: row;
    overflow-x: scroll;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
}
