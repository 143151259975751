.got-questions-block {

    &--border {

        border: 1px solid $gray;
        border-radius: 5px;
        padding: rem(24) rem(16);
    }

    &--img-wrapper {

        margin-left: auto;

        @include breakpoint(large) {

            margin-left: 0;
            margin-right: rem(16);
        }
    }
}
