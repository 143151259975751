.top-nav-cart-icon-wrapper {
    transform: translate(0.2rem, 0.6rem);

    @include breakpoint(medium) {
        transform: translateY(0.6rem);
    }

    .top-nav-cart-icon {

        @include breakpoint(medium) {
            padding-top: 0;
        }

        svg {
            width: 2rem;
        }
    }

    .cart-count-wrapper {
        transform: translate(-22px, 2px);
        pointer-events: none;

        @include breakpoint(medium) {
            transform: translate(-22px, -2px);
        }

        .cart-items-count {
            width: rem(20px);
            height: rem(20px);
            background: $yellow;
            color: $black;
            display: inline-block;
            border-radius: 100%;
            text-align: center;
            line-height: rem(20px);
            font-size: rem(12px);
            font-weight: 800;
        }
    }
}


#cart-icon-mobile {
    padding: 0;
    max-width: rem(50px);
    border-right: 0;
}
