.breed-reveal__confidence-bar {

    &--header {

        max-width: 400px;

        @include breakpoint(medium) {
            margin-top: -50px;
        }
    }

    &-icons {
        justify-content: center;
        margin-top: 48px;

        @include breakpoint(medium) {
            justify-content: space-between;
        }
    }

    &--item {
        max-width: 200px;
        margin-bottom: 24px;

        p {
            text-align: center;
            color: #0a0a0a;
            font-feature-settings: "clig" off, "liga" off;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            letter-spacing: 0.15px;
        }

        a {
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }

    }
}
