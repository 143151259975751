.hero {
    position: relative;
    margin-left: auto;
    margin-right: auto;
}

.hero .scroll-direction {
    display: inline-block;
    position: absolute;
    bottom: 50px !important;
    width: 100px;
    margin: 0 auto;
    left: 50%;
    transform: translateX(-50%);
    opacity: 1;
    transition: all 0.5s ease;

    a {
        text-decoration: unset;
    }
}

.hero .scroll-direction.hide {
    opacity: 0;
}

.hero .scroll-direction p {
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: $white;
    padding: 0;
    margin: 0;
    text-align: center;
    text-shadow: 1px 1px #aaa;
}

.hero .scroll-direction span {
    display: block;
    position: absolute;
    bottom: -30px;
    left: 50%;
    width: 17px;
    height: 10px;
    text-indent: -999em;
    margin: 0 auto 0 -9px;
    background: url(//front.embarkvet.com/img/icons/icn-scroll.png) no-repeat center center;
    animation: animate-arrow-down ease-in-out 2s;
    animation-iteration-count: infinite;
    transform-origin: 50% 50%;
}

@keyframes animate-arrow-down {

    0% {
        transform: translate(0, 0);
    }

    50% {
        transform: translate(0, -20px);
    }

    100% {
        transform: translate(0, 0);
    }
}
