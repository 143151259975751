#decoded-products {

    .best-value .product-subtitle {
        color: #12aca0;
    }

    background-color: $light-gray;
    padding: 60px 40px 16px;

    @include breakpoint(medium) {

        padding: 140px 40px 64px;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: $summerSalePrimaryColor;
    }

    > p {
        margin-bottom: 48px;

        @include breakpoint(medium) {

            margin-bottom: 56px;
        }
    }

    @include breakpoint(1650px up) {

        .decoded-products-container {

            @include xy-cell(10);
        }
    }

    .subheader {

        font-size: rem(18);
    }

    .decoded-product {

        margin-bottom: 50px;
        text-align: center;


        &:nth-child(even) {

            .product-info {

                @include breakpoint(medium) {
                    text-align: right;
                }
            }

            .product-cta {

                @include breakpoint(medium only) {

                    justify-content: flex-end;
                }
            }

            .product-wrapper {
                margin-left: auto;
            }

            .new-badge {
                margin-left: auto;
            }

            .pricing {

                @include breakpoint(medium) {
                    flex-direction: row;
                    justify-content: flex-end;
                }
            }

        }


        &-wrapping-link {
            text-decoration: none;
        }

        @include breakpoint(medium) {

            text-align: unset;
            align-items: center;
        }

        ~ .decoded-product {

            @include breakpoint(medium) {

                text-align: left;
            }

            .product-wrapper {

                @include breakpoint(medium) {

                    border-radius: 120px 120px 120px 10px;
                }
            }

            .product-cta {

                .medium-auto {

                    @include breakpoint(large) {

                        margin-left: 0;
                        margin-right: 32px;
                    }
                }
            }

            .pricing {

                .product-price {
                    color: $summerSaleSecondaryColor;
                }
            }
        }

        .product-wrapper {

            background: $white;
            border-radius: 80px 80px 5px 80px;
            padding: 36px 0 16px;

            @include breakpoint(medium) {

                border-radius: 120px 120px 10px 120px;
                padding: 100px 0 64px;
                width: 100%;
                max-width: 560px;
                height: 560px;
            }

            img {

                width: 100%;
            }

            .sub-heads {

                margin-bottom: 0;
            }
        }

        .new-badge {
            background: #000;
            color: #fff;
            display: inline-block;
            text-align: center;
            border-radius: 17px 17px 17px 0;
            margin-bottom: 13px;
            font-weight: 700;
            width: rem(60px);
            font-size: 0.8rem;
            padding: rem(4px) 0;

            @include breakpoint(medium) {
                width: rem(80px);
                padding: rem(7px) 0;
                font-size: 1rem;
            }
        }
    }

    .product-info {

        @include breakpoint(medium) {

            display: flex;
            flex-direction: column;
        }
    }

    .review-stars {

        font-size: rem(12);
        font-weight: 500;
        margin-top: 28px;
        margin-bottom: 8px;

        @include breakpoint(medium only) {
            margin-top: 0;
            font-size: rem(18);
        }

        @include breakpoint(large) {

            font-size: rem(18);
            margin-top: 32px;
            order: 5;
        }

        a,
        a:hover,
        a:focus {
            color: $black;
            text-decoration: none;
        }
    }

    .star-ratings-display {

        display: inline-block;
        margin-right: 12px;
        vertical-align: top;

        svg {

            width: auto;
            height: 17px;

            @include breakpoint(medium) {

                height: 23px;
            }
        }
    }

    .sub-heads,
    .product-subtitle {

        font-size: rem(12);
        font-weight: 700;
    }

    .sub-heads {

        @include breakpoint(medium) {

            font-size: rem(18);
            line-height: 1.1;
        }
    }

    .product-title {

        line-height: 1;
        margin-bottom: 8px;

        @include breakpoint(medium) {

            font-size: rem(48);
        }
    }

    .product-subtitle {

        @include breakpoint(medium) {

            font-size: rem(24);
        }
    }

    .product-features {

        font-size: rem(14);
        list-style-type: none;
        margin-left: 0;

        @include breakpoint(large) {

            column-count: 2;
            font-size: rem(24);
            line-height: rem(42);
            letter-spacing: 1.29px;
        }
    }

    .pricing {

        margin-bottom: 8px;

        @include breakpoint(medium) {

            display: flex;
            align-items: baseline;
            line-height: 1;
            margin-bottom: 0;
        }

        .product-price {

            color: $summerSaleSecondaryColor;
            font-family: $font-family;
            font-size: rem(24);
            font-weight: 700;

            @include breakpoint(medium) {

                font-size: rem(46);
                margin-right: 16px;
            }
        }

        .product-price-strikethrough {

            color: #808080;
            font-size: rem(18);
            font-weight: 600;
            text-decoration: line-through;

            @include breakpoint(medium) {

                align-self: center;
                font-size: rem(34);
            }
        }
    }

    .product-cta {

        @include breakpoint(medium) {

            align-items: center;
        }

        &-pricing {

            @include breakpoint(medium only) {
                padding-left: 0;
                margin-bottom: rem(10);
            }

            @include breakpoint(large) {
                padding-left: rem(32);
            }
        }

        .sub-heads {

            @include breakpoint(medium) {

                margin-bottom: 0;
                margin-top: 12px;
            }
        }

        .button-primary {

            @include breakpoint(medium only) {

                min-width: unset;
                width: 100%;
            }
        }

        .medium-auto {

            @include breakpoint(large) {

                margin-left: 32px;
            }
        }

        .large-auto {

            @include breakpoint(large) {

                flex: 0 1 0;
            }
        }
    }

    &.upgrade-product {

        > p {
            margin-bottom: 24px;

            @include breakpoint(medium) {

                margin-bottom: 64px;
            }
        }

        .decoded-product {
            text-align: left;
        }

        .upgrade-phone-wrap,
        .upgrade-phone,
        .product-info {
            position: relative;
        }

        .upgrade-phone-wrap::before {

            @include breakpoint(small only) {

                background-image: url("https://res.cloudinary.com/embark/images/v1617743003/embarkvet.com/uploads/upgrade-rectangle/upgrade-rectangle.png");
                background-size: cover;
                content: "";
                position: absolute;
                left: -40px;
                top: 20%;
                width: 100vw;
                height: 100%;
            }
        }

        .upgrade-phone {

            @include breakpoint(small only) {

                margin-left: 4%;
                max-width: 70%;
            }

            @include breakpoint(medium only) {

                margin-left: 5%;
                max-width: 60%;
            }
        }

        .product-features {

            h3 {
                color: $black;
                font-size: rem(21);
                line-height: 1.125;
                letter-spacing: 1px;
                margin-top: 24px;
                margin-bottom: 12px;

                @include breakpoint(large) {
                    font-size: rem(38);
                }

                @include breakpoint(xlarge) {
                    font-size: rem(42);
                }
            }

            p {

                font-size: rem(16);
                letter-spacing: 1px;
                margin-bottom: rem(32);

                @include breakpoint(large) {

                    font-size: rem(28px);
                    line-height: 1.2;
                }
            }
        }

        .upgrade-feature-icon {

            text-align: right;
            padding-right: 20px;

            @include breakpoint(large) {

                text-align: center;
                padding: 0 10px;
            }

            svg {

                max-width: 55px;

                @include breakpoint(large) {
                    width: 100%;
                    height: auto;
                    max-width: 80px;
                }
            }
        }

        .sub-heads {

            font-size: rem(18);
            line-height: 1.2;
            margin-bottom: 16px;
            text-align: center;

            @include breakpoint(medium) {

                max-width: unset;
            }

            @include breakpoint(large) {

                font-size: rem(24);
                letter-spacing: 1.43px;
                margin-bottom: 24px;
                text-align: left;
            }
        }

        .product-cta {

            @include breakpoint(small only) {
                text-align: center;
            }
        }
    }
}

@media only screen and (min-width: 640px) and (max-width: 1250px) {

    #decoded-products .decoded-product {

        .product-wrapper {

            max-width: 90%;
            display: flex;
            flex-direction: column;
            margin-left: 0;
            height: 100%;

            img {

                margin-top: auto;
                margin-bottom: auto;
            }
        }
    }
}
