html.is-reveal-open,
html.is-reveal-open body {
    position: inherit !important;
}

/*
* Foundation's reveal component(modal)
* without 100% width on modal on tablet and mobile sizes
*/
@media screen and (max-width: 40em) {

    .reveal.non-full-width-reveal {
        height: 80%;
        display: block;
        min-height: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        position: absolute;
    }
}

.reveal.medium {
    width: 65%;
}

.reveal {
    outline: none;
}
