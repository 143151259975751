.star-rating {
    display: flex;
    gap: 3px;
    flex-wrap: wrap;

    .review-text {
        font-size: rem(14px);
        color: #626262;
    }

    .star {
        width: rem(18px);
        height: rem(18px);
        -webkit-font-smoothing: antialiased;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        margin: auto 0;

        &-full {
            background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzAiIGhlaWdodD0iMzAiIHZpZXdCb3g9IjAgMCAzMCAzMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE1LjUxNjcgMjAuNzMxNEwxNSAyMC40MTk1TDE0LjQ4MzMgMjAuNzMxNEw4Ljc4NzI2IDI0LjE2OTJMMTAuMjk4OSAxNy42ODk3TDEwLjQzNiAxNy4xMDE5TDkuOTc5NzcgMTYuNzA2N0w0Ljk0NjE3IDEyLjM0NjFMMTEuNTcyIDExLjc4MzlMMTIuMTcyOSAxMS43MzI5TDEyLjQwODIgMTEuMTc3N0wxNSA1LjA2MjZMMTcuNTkxOCAxMS4xNzc3TDE3LjgyNzEgMTEuNzMyOUwxOC40MjggMTEuNzgzOUwyNS4wNTM4IDEyLjM0NjFMMjAuMDIwMiAxNi43MDY3TDE5LjU2NCAxNy4xMDE5TDE5LjcwMTEgMTcuNjg5N0wyMS4yMTI3IDI0LjE2OTJMMTUuNTE2NyAyMC43MzE0WiIgZmlsbD0iI0ZGQ0UzNCIgc3Ryb2tlPSIjQjc4RDAwIiBzdHJva2Utd2lkdGg9IjIiLz4KPC9zdmc+Cg==);
        }

        &-half {
            background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzAiIGhlaWdodD0iMzAiIHZpZXdCb3g9IjAgMCAzMCAzMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE1LjUxNjcgMjAuNzMxNEwxNSAyMC40MTk1TDE0LjQ4MzMgMjAuNzMxNEw4Ljc4NzI2IDI0LjE2OTJMMTAuMjk4OSAxNy42ODk3TDEwLjQzNiAxNy4xMDE5TDkuOTc5NzcgMTYuNzA2N0w0Ljk0NjE3IDEyLjM0NjFMMTEuNTcyIDExLjc4MzlMMTIuMTcyOSAxMS43MzI5TDEyLjQwODIgMTEuMTc3N0wxNSA1LjA2MjZMMTcuNTkxOCAxMS4xNzc3TDE3LjgyNzEgMTEuNzMyOUwxOC40MjggMTEuNzgzOUwyNS4wNTM4IDEyLjM0NjFMMjAuMDIwMiAxNi43MDY3TDE5LjU2NCAxNy4xMDE5TDE5LjcwMTEgMTcuNjg5N0wyMS4yMTI3IDI0LjE2OTJMMTUuNTE2NyAyMC43MzE0WiIgc3Ryb2tlPSIjQjc4RDAwIiBzdHJva2Utd2lkdGg9IjIiLz4KPG1hc2sgaWQ9Im1hc2swXzUzNjZfMzU1NSIgc3R5bGU9Im1hc2stdHlwZTphbHBoYSIgbWFza1VuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeD0iLTMiIHk9Ii00IiB3aWR0aD0iMTgiIGhlaWdodD0iMzYiPgo8cmVjdCB4PSItMyIgeT0iLTQiIHdpZHRoPSIxOCIgaGVpZ2h0PSIzNiIgZmlsbD0iI0ZGQ0UzNCIvPgo8L21hc2s+CjxnIG1hc2s9InVybCgjbWFzazBfNTM2Nl8zNTU1KSI+CjxwYXRoIGQ9Ik0xNS41MTY3IDIwLjczMTRMMTUgMjAuNDE5NUwxNC40ODMzIDIwLjczMTRMOC43ODcyNiAyNC4xNjkyTDEwLjI5ODkgMTcuNjg5N0wxMC40MzYgMTcuMTAxOUw5Ljk3OTc3IDE2LjcwNjdMNC45NDYxNyAxMi4zNDYxTDExLjU3MiAxMS43ODM5TDEyLjE3MjkgMTEuNzMyOUwxMi40MDgyIDExLjE3NzdMMTUgNS4wNjI2TDE3LjU5MTggMTEuMTc3N0wxNy44MjcxIDExLjczMjlMMTguNDI4IDExLjc4MzlMMjUuMDUzOCAxMi4zNDYxTDIwLjAyMDIgMTYuNzA2N0wxOS41NjQgMTcuMTAxOUwxOS43MDExIDE3LjY4OTdMMjEuMjEyNyAyNC4xNjkyTDE1LjUxNjcgMjAuNzMxNFoiIGZpbGw9IiNGRkNFMzQiIHN0cm9rZT0iI0I3OEQwMCIgc3Ryb2tlLXdpZHRoPSIyIi8+CjwvZz4KPC9zdmc+Cg==);
        }

        &-empty {
            background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzAiIGhlaWdodD0iMzAiIHZpZXdCb3g9IjAgMCAzMCAzMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE1LjUxNjcgMjAuNzMxNEwxNSAyMC40MTk1TDE0LjQ4MzMgMjAuNzMxNEw4Ljc4NzI2IDI0LjE2OTJMMTAuMjk4OSAxNy42ODk3TDEwLjQzNiAxNy4xMDE5TDkuOTc5NzcgMTYuNzA2N0w0Ljk0NjE3IDEyLjM0NjFMMTEuNTcyIDExLjc4MzlMMTIuMTcyOSAxMS43MzI5TDEyLjQwODIgMTEuMTc3N0wxNSA1LjA2MjZMMTcuNTkxOCAxMS4xNzc3TDE3LjgyNzEgMTEuNzMyOUwxOC40MjggMTEuNzgzOUwyNS4wNTM4IDEyLjM0NjFMMjAuMDIwMiAxNi43MDY3TDE5LjU2NCAxNy4xMDE5TDE5LjcwMTEgMTcuNjg5N0wyMS4yMTI3IDI0LjE2OTJMMTUuNTE2NyAyMC43MzE0WiIgc3Ryb2tlPSIjQjc4RDAwIiBzdHJva2Utd2lkdGg9IjIiLz4KPC9zdmc+Cg==);
        }
    }
}
