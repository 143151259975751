@media screen and (max-width: 39.9375em) {

    .hero-video-cta-container--cta-button {
        display: block;
        text-align: center;
        min-height: 3rem;
    }
}

.hero-video {
    position: relative;

    .background-image-overlay {

        @include breakpoint(612px down) {
            max-height: 350px;
            position: relative;
        }
    }
}

.hero-video video {
    display: block;
    width: 100%;
}

.hero-video .hero-video-cta-container {
    position: absolute;
    display: block;
    top: 0;
    left: 25px;
    bottom: 0;
    padding: 0;
    margin: auto;
}

.hero-video .hero-video-cta-container img {
    display: block;
    max-width: 190px;
}

.hero-video .hero-video-cta-container--txt.hide {
    display: none;
}

.hero-video .hero-video-cta-container--txt .video-play-container {
    position: relative;
    top: 16px;
}

.hero-video-cta-container--txt a {
    margin-bottom: 1rem;
}

@media (max-width: 767px) {

    .hero-video {
        height: auto;
    }

    .hero-mobile-cta {
        text-align: center;
        padding: 1rem;
    }

    .hero-video .background-image-overlay {
        max-height: 390px;
        position: relative;
    }

    .hero-video .background-image-overlay.video {
        height: 520px;
    }

    .hero-video .background-image-overlay.video video {
        display: none;
    }

}

@media (min-width: 768px) {

    .hero-video .background-image-overlay {

        /*
        The Background image URL is set inline so it can be added in the
        admin so !important is required here to prevent it from loading on
        tablets and up
         */
        background-image: none !important;
    }
}

@media (max-width: 830px) {

    .hero-video .hero-video-cta-container {
        top: 15px;
        left: 20px;
    }

    .hero-video-cta-container--txt h1 {
        font-size: 2rem;
    }

    .hero-video-cta-container--txt h2 {
        font-size: 1rem;
    }
}

@media (min-width: 1024px) {

    .hero-video .hero-video-cta-container {
        height: 400px;
        left: 30px;
    }

    .hero-video .hero-video-cta-container img {
        max-width: 275px;
    }

    .hero-video-cta-container--cta-button a {
        font-size: 1.3rem;
    }
}

@media (min-width: 1200px) {

    .hero-video .hero-video-cta-container {
        height: 500px;
        left: 40px;
    }
}

.hero-contents {
    background-repeat: no-repeat;
}

.hero-contents .cta-container img {
    margin: 1rem auto 2.2rem;
}

@media screen and (min-width: 64em) {

    .hero-contents {
        padding-top: 2rem;
    }
}
