/* START review block CSS */

.product-reviews {
    padding: 2rem 0 4.6rem;
    background: #ffce3f;
}

.block-reviews {
    padding: 1.6rem 0 0 0;
}

.block-reviews .reviews-container {
    padding-bottom: 6rem;
}

.block-reviews .review-date {
    font-size: 12px;
    line-height: 26px;
    margin-left: 0.625rem;
}

.block-reviews .review-body p {
    font-style: italic;
}

.block-reviews .review {
    padding: 1.8rem;
    background: $white;
    border-radius: 0.8rem;
    position: relative;
}

.average-stars svg {
    transform: scale(0.8);
    width: 18px;
    height: 20px;
}

.total-reviews .count {
    font-size: 12px;
    margin-left: 0.225rem;
    vertical-align: text-bottom;
}

.stars .star polygon {
    fill: #d8d8d8;
}

.stars .star.filled polygon {
    fill: #ffce3f;
}

.stars .star.half-star polygon {
    fill: url(#half-star) !important;
}

.block-reviews.show-for-small-only {
    text-align: center;
}

/* Above tablet */
@media screen and (min-width: 40em) {

    .block-reviews .review::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 75%;
        width: 0;
        height: 0;
        border: 5.656em solid transparent;
        border-top-color: $white;
        border-bottom: 0;
        border-right: 0;
        margin-left: -2.828em;
        margin-bottom: -3.656em;
        transform: rotate(-13deg);
    }

}

/* Tablet and below */
@media screen, print and (max-width: 40em) {

    .product-reviews {
        padding-bottom: 0;
    }

}

/* END review block CSS */
