.badge {

    @include badge;
    font-size: rem(14px);
    border-radius: rem(16px);
    padding: rem(4px) rem(12px) rem(4px);
    text-decoration: none;

    &--text {
        background: $yellow;
        color: $black;
        text-decoration: none;

        &-small {
            font-size: rem(14px);
        }
    }

    &--grey {
        background: #bdbdbd;
        color: $black;
    }

    &--light {
        background: #e0e0e0;
        padding: 3px 8px;
        margin: rem(5px) 0;
        color: $black;
    }

    &--dark {
        background: $black;
        padding: 3px 8px;
        margin: rem(5px) 0;
        color: #e0e0e0;
    }

    &--white {
        font-size: rem(14px);
        background: $white;
        color: $black;
    }

    &--primary-dimmed {
        background: #fcc3004d;
    }

    &--inline {
        font-weight: 400;
        display: inline-block;
        margin: 0 3px;
    }

    &--icon {
        background: $med-light-gray;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        font-size: rem(20px);
        line-height: rem(24px);
        width: rem(166px);
        height: rem(166px);
        border-radius: 24px;
        margin: rem(9px);
        padding: rem(16px) rem(8px);
        text-align: center;
        color: $black;
        font-weight: 700;

    }
}

.badge--list {
    padding: 0;
    margin-left: 0;
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;

    .badge {
        margin-right: 0.5rem;
    }
}

a.badge--icon {
    text-decoration: none;

    &:hover,
    &:focus {
        text-decoration: underline;
    }
}
