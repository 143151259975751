@mixin headline($size) {
    h#{$size},
    .headline#{$size},
    .h#{$size} {

        @content;
    }
}

@mixin dna-month-announcement-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: $font-family;
    letter-spacing: 1px;
    min-height: 62px;
    margin-bottom: 0;

    p {
        margin-bottom: 0;

        @include breakpoint(small only) {
            padding: 0 10px;
            line-height: 1.4;
        }
    }
}

.dnaMonthPage {
    font-family: Gilroy, serif;

    @include headline(1) {
        font-family: Gilroy, serif;
        font-size: rem(36px);
        font-weight: 700;
        line-height: 1.11;
        letter-spacing: 1.93px;
        margin-bottom: rem(20px);

        @include breakpoint(medium up) {
            font-size: rem(64px);
            line-height: 1.05;
            letter-spacing: 3px;
        }
    }

    @include headline(2) {
        font-family: Gilroy, serif;
        font-size: rem(20px);
        font-weight: 700;
        line-height: 2;
        letter-spacing: 1.07px;

        @include breakpoint(medium up) {
            font-size: rem(62px);
            line-height: 0.81;
            letter-spacing: 1.29px;
        }
    }


    p {
        font-size: rem(18px);
        line-height: 1.33;
        margin-bottom: rem(30px);
        letter-spacing: 0.96px;

        @include breakpoint(medium up) {
            font-size: rem(24px);
            line-height: 1.24;
            letter-spacing: 2.25px;
        }

    }


    .button-primary {

        &,
        &:hover,
        &:focus,
        &:active {
            min-width: rem(216px);
            background: $dnaMonthSecondary;
            padding: 21px 23px 20px;
            border-radius: 60px;
            font-size: rem(14px);
            color: #000;

            @include breakpoint(large) {
                font-size: rem(20px);
            }
        }
    }

    > section {
        padding: 2rem 0;

        &.dna-month-callout {
            padding: rem(65px) 0;
        }

        &.interactive-chromies-block {
            padding: 0;
        }

        @include breakpoint(large) {

            &.dna-month-callout {
                padding: 0;
            }
        }

        &:first-of-type {
            padding-bottom: 0;

            @include breakpoint(small only) {
                max-height: rem(500);
            }
        }
    }

    &-hero {
        background: $dnaMonthPrimaryDark;
        color: #fff;

        p {
            margin-bottom: 8px;
        }

        &-copy {

            &.sale-is-active {

                @include breakpoint(small only) {
                    transform: translate(-15px, -142px);

                    small {
                        margin-top: rem(10);
                    }

                    p {
                        margin-bottom: rem(20);
                        font-size: 1.2rem;
                    }
                }
            }

            padding: 0 1.1rem;

            p {
                margin-bottom: 2rem;
            }

            &-disclaimer {
                display: inline-block;
                font-size: rem(10px);
                margin-top: rem(22px);
            }

            @include breakpoint(small only) {
                transform: translateY(-85px);
            }

            @include breakpoint(medium up) {
                padding-left: 6rem;

                p {
                    font-size: rem(24px);
                    letter-spacing: rem(1.3px);
                    margin: 30px 0 23px 0;
                }
            }

            @include breakpoint(large) {

                p {
                    margin-left: 0;
                }
            }

            @include breakpoint(xlarge) {

                p {
                    margin-left: 120px;
                }
            }
        }

        &-cta {

            @include breakpoint(large) {
                margin: 0.6rem 0;
            }
        }

    }

    .who-says {
        padding: 5rem 0;

        &-copy {
            padding: 0 1.1rem;

            @include breakpoint(large up) {
                padding: 0 3rem;
                padding-right: 0;
            }

            @include breakpoint(1441px) {
                padding-right: 10rem;
            }
        }

        &-image {
            padding-left: 1.1rem;
        }
    }

    .dna-month-callout {

        &-copy {
            padding: 0 1.1rem;

            &-heading {
                margin-bottom: rem(40px);
            }

            @include breakpoint(medium) {
                padding: 1rem 3rem;
            }

            @include breakpoint(large up) {
                padding: 1rem 4rem;
            }
        }
    }

    .dna-month-page-footer {
        background: $dnaMonthLightLinen;
        padding-bottom: 0;

        &-copy {
            color: $dnaMonthPrimaryColor;

            @include breakpoint(small) {
                padding: 3rem 0;
            }
        }

        &-image {
            background: $white;
            height: 92px;
            border-top-left-radius: 111px;
            transform: translateY(15px);

            img {
                max-width: 166px;
                width: 100%;
            }

            @include breakpoint(medium only) {
                height: 120px;
                border-top-left-radius: 121px;

                img {
                    max-width: 240px;
                }
            }
        }

        @include breakpoint(large up) {

            &-copy {
                padding: 5rem 1rem 0 0;
            }

            &-image {
                height: 275px;
                border-top-left-radius: 280px;
                transform: translateY(45px);

                img {
                    width: 100%;
                    max-width: 384px;
                }
            }

        }
    }

    // scoped utilities
    .next-line {
        display: block;
        line-height: inherit;
    }

    .text-color-primary {
        color: $dnaMonthPrimaryColor;
    }

    .text-color-secondary {
        color: $dnaMonthSecondary;
    }

    @include breakpoint(large up) {

        .large-align-left {
            justify-content: flex-start;
        }
    }

    .fadeInUp {
        opacity: 0;
    }

    .animate .fadeInUp {
        animation: fade_in_up 1s cubic-bezier(0.42, 0, 0.58, 1) forwards;
        animation-iteration-count: 1;
    }

    @keyframes fade_in_up {

        from {
            transform: translate3d(0, 40px, 0);
        }

        to {
            transform: translate3d(0, 0, 0);
            opacity: 1;
        }
    }

    .interactive-chromies-block {

        svg {
            width: 100%;
        }
    }
}

@media (min-width: 660px) and (max-width: 1010px) {
    // Required for iPad
    .dnaMonthPage-hero-copy {
        transform: translateY(-110px);
    }

    .dnaMonthPage-hero-copy p {
        margin-left: 0;
    }
}


.page-template-temp-dna-month,
.page-template-temp-dna-month-upgrades {

    #footer {
        position: relative;
    }

    .announcement-bar:not(.dna-month-announcement-bar) {
        display: none;
    }

    .announcement-bar {

        @include dna-month-announcement-bar;
    }


    .dnaMonthPage {

        &-hero {
            background: $dnaMonthPrimaryColor url("https://res.cloudinary.com/embark/image/upload/f_auto,q_auto/v1617122588/embarkvet.com/dist/img/dna-month/placeholder-img-header-copy_3x.png") no-repeat top right;
            background-size: cover;
            color: #fff;

            &[data-has-custom-background] {
                background-image: var(--background-image-url);
            }

            @include breakpoint(small only) {

                padding: 8rem 0 !important;

                &-copy {
                    transform: translateY(-63px) !important;
                }
            }

            @include breakpoint(medium only) {
                padding: 8rem 0 !important;
                background-position: top right;

                &-copy {
                    transform: translateY(0) !important;
                }
            }

            @include breakpoint(large up) {
                background: $dnaMonthPrimaryDark;
                background: linear-gradient(90deg, rgba(226, 95, 71, 1) 36%, rgba(238, 115, 98, 1) 62%);
                padding-top: 2.8rem;

                &-image {
                    padding-left: 2rem;
                    align-self: flex-end;
                }
            }

        }
    }
}

.page-template-temp-dna-month-upgrades {

    .dnaMonthPage {

        &-hero {

            &-copy {

                @include breakpoint(small only) {
                    transform: translateY(-20px) !important;
                }
            }
        }
    }
}

@import "../sections/section-countdown-timer";
@import "../sections/section-dna-month-testimonial-slider";
@import "../sections/section-dna-month-products";
