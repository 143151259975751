.author-block {

    background: $light-gray;
    border-radius: rem(8px);
    margin-bottom: rem(16px);
    padding: rem(32px) rem(8px);

    .avatar {
        display: block;
        margin: 0 auto rem(8px);
        max-width: 150px;
        height: auto;

        @include breakpoint(medium) {
            margin-bottom: 0;
            width: 100%;
        }
    }

    &--name {
        line-height: 1.5;
        margin-bottom: rem(16px);
        text-align: center;

        @include breakpoint(medium) {
            text-align: inherit;
        }
    }

    &--role {
        color: $gray;
        display: block;
        font-weight: 500;
        font-size: rem(16px);
    }

    &--bio {
        margin-bottom: 0;
    }

    .readmore--link {
        color: $black;
        text-decoration: none;
        font-weight: 700;
        display: inline-block;
        margin-top: 1rem;

        &:hover {
            text-decoration: underline;
        }
    }

    &--social-list {
        margin: rem(12px) 0 0;

        li {
            display: inline-block;
            margin-right: rem(8px);
        }
    }
}
