.page.page-template-temp-custom-page {

    .block-two-columns-text:last-of-type .container {
        align-items: center;
        justify-content: center;
    }

    @media screen and (min-width: 64em) {

        .block-two-columns-text:last-of-type .container {

            .right-column-text {
                width: 25%;
            }
        }
    }
}

.visual-breed-id {

    @media screen and (max-width: 39.9375em) {

        .block-two-columns-text:nth-of-type(even) {

            .left-column-text {
                order: 2;
            }

            .right-column-text {
                order: 1;
            }
        }
    }

    .diva-hero .container > :only-child {
        margin: rem(16) 0;
    }
}
