.top-stories a {

    @include no-text-decoration;
    color: $black;
    display: block;
    min-height: 38px;
    padding: 15px 0;
}


.post-meta {
    color: #797d82;
    margin-top: 15px;
    margin-bottom: 20px;
}

.post-entry {
    margin-bottom: 0;
}

.tags a {
    background-color: $yellow;
    color: $black;
    margin: 0 0.5rem 0.5rem 0;
    padding: 0.3rem 0.7rem;
    display: inline-block;
    text-transform: uppercase;
    font-size: 0.8rem;
    font-weight: 800;
}

.post-meta .author-img {
    width: 24px;
    height: 24px;
    float: left;
    margin-right: 9px;
}

.hero.image {
    max-height: 30rem;
    margin-bottom: 5rem;
    overflow: hidden;
}

.post-excerpt {
    margin-bottom: 2rem;
}


.top-stories ol {
    list-style: none;
    margin: 0;
    padding: 0;
}

.top-stories li {
    position: relative;
    display: block;
    margin: 0 0 2px 0;
    overflow: hidden;
}

.top-stories .post-image {
    width: 100%;
    padding: 60px 45px;
    background-color: #797d82;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.top-stories-title {
    font-weight: 800;
    margin-top: 10px;
}

.top-stories-date {
    color: $gray;
    font-weight: 400;
}

.single-post-entry {
    padding-bottom: 40px;
    border-bottom: 1px solid $very-light-gray;
}

.post-header {
    margin-bottom: 30px;
}

@include breakpoint(large) {
    //@media print, screen and (min-width: 64em) {

    /* Blog styles brought here from style.css */
    .top-stories .post-image {
        float: left;
        margin-right: 20px;
        width: auto;
        padding: 40px 45px;
    }

    .top-stories-title {
        margin-top: 0;
    }
    //}
}

.sidebar {
    padding-top: 0;
}

/*
*
* Blog specific classes
* /category/blog
*/

.blog-menu {
    border-bottom: 1px solid #ccc;
}

.blog-menu .nav-menu ul li a {

    font-weight: 800;
}

@media screen and (min-width: 40em) {

    .blog-menu .nav-menu ul li a {
        font-size: 1.15rem;
    }

    /* bigger content on featured block */
    .blog .featured-big .featured-media-container {
        height: 402px;
    }

}

.blog-menu .menu {
    display: flex;
}

.blog-menu .responsive-nav-group {
    margin: 0;
}

.category-blog .blog .post-title {
    font-weight: 800;
    line-height: 1.2;
}

.blog-conclusion .conclusion-block {
    margin-top: 3em;
}

.category.breeder-section .blog-conclusion section.grid-container.get-started-sidebar .button-large {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.blog .featured-small .featured-media-container {
    height: 112px;
    width: 100%;
    background-size: cover;
    background-position: center;
    background-color: #666;
    margin-bottom: 14px;
}

.featured-small + .featured-small {

    @include xy-gutters( $gutter-type: margin, $gutter-position: top);
}

@media screen and (max-width: 64em) {

    .blog .featured-small:nth-child(n+2) .featured-media-container {
        margin-top: 14px;
    }
}

.blog .featured-big .tags > a {
    margin: 0;
}

.blog .featured-big h1.post-title {
    margin: 1rem 0;
}

@media screen and (max-width: 40em) {

    .blog .common-category .post-title {
        margin-top: 0.5rem;
    }
}

.blog .featured-small .post-entry {
    font-weight: 800;
}

.blog .featured-small .top-stories li {
    display: flex;
}

.blog .common-category .post-meta,
.blog .featured-big .post-meta,
.blog .featured-small .post-meta {
    text-transform: uppercase;
}

.blog .featured-category {
    border-bottom: 1px solid #f7f7f7;
    padding-bottom: 30px;
}

.blog .common-category .post-entry {
    padding: 35px 0;
    border-bottom: 1px solid #f7f7f7;
}

.blog .common-category .item .featured-media-container {
    height: 206px;
}

.blog .posts-navigation {
    margin-top: 35px;
}

.blog .posts-navigation .nav-links > .nav-next,
.blog .posts-navigation .nav-links > .nav-previous {
    display: inline-block;
}

.blog .posts-navigation .nav-links > div:nth-child(2) {
    margin-left: 20px;
}

/* Small only */
@media screen and (max-width: 39.9375em) {

    .blog .nav-next > a,
    .blog .nav-previous > a {
        padding: 10px 10px;
    }
}

.blog .sidebar {
    padding-left: 0;
    padding-right: 0;
}

.blog .sidebar .top-stories li:first-child a {
    padding-top: 0;
}

.blog .sidebar .top-stories li a {
    padding: 15px 15px 0 0;
}

.blog .sidebar .top-stories-title > p {
    margin-bottom: 10px;
    font-weight: 400;
}

.blog-menu .nav-menu-desktop {
    display: none;
}

.blog-menu .nav-menu-desktop ul > li.menu-item-has-children > a::after {
    content: "\25bc ";
    padding-left: 8px;
}

.blog-menu .nav-menu-mobile {
    display: block;
}

.blog-menu .nav-menu-mobile .submenu-toggle::after {
    border-color: $black transparent transparent;
}

@media screen and (min-width: 64em) {

    .blog-menu .nav-menu-desktop {
        display: flex;
    }

    .blog-menu .nav-menu-mobile {
        display: none;
    }
}

/*
* Styles for single post
* Tempate: single.php
*/

.single-post .hero.image {
    max-height: 30rem;
    margin-bottom: 5rem;
    overflow: hidden;
    min-height: rem(176);

    @include breakpoint(medium) {
        min-height: rem(360);
    }

    @include breakpoint(large) {
        min-height: rem(480);
    }
}

.single-post .hero {
    max-width: 75rem;
}

.widget h4 {
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 800;
    border-bottom: 1px solid $black;
    padding-bottom: 10px;
}

// Category icons swiper slider

.category-icon-nav {
    position: relative;
}

.swiper-category-icons {
    padding-bottom: 50px;
    margin-bottom: 20px;
    --swiper-navigation-size: 30px;
    --swiper-navigation-color: #000;
    --swiper-pagination-color: #000;
    overflow-x: hidden;

    margin-inline: auto;

    @include breakpoint(large) {
        max-width: clamp(85%, 900px, 1200px);
    }

    @media only screen and (max-width: 1024px) {
        max-width: 800px;
    }

    @include breakpoint(xlarge) {
        max-width: clamp(88%, 1270px, 1625px);
    }

    .swiper-slide {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .swiper-button-next,
    .swiper-button-prev {
        background: #f5f5f5;
        padding: 38px;
        border-radius: 50%;
        translate: 0 -38px;

        @include breakpoint(small only) {
            display: none;
        }

        @include breakpoint(medium only) {
            display: none;
        }
    }

    @at-root .category-icon-nav--bottom {

        .swiper-button-next,
        .swiper-button-prev {
            translate: 0;
        }
    }
}
