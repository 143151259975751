.news-block {
    margin-bottom: 3rem;

    img {
        margin-bottom: 3rem;
        width: 100%;
        height: auto;
    }

    a {

        @extend .link--dotted--green;
    }
}

/*
* Styles for Newsroom page
* Template: temp-newsroom.php
*/
.newsroom h3.tvshow a {
    font-weight: 700;
    color: #222;
}

.newsroom h3.tvshow a:hover {
    color: #1585cf;
}

.newsroom .section-social h3 {
    margin-bottom: 4rem;
}

.newsroom .as-seen-on-tv .tvshow > a {

    @include no-text-decoration;
    color: $black;
}
