/* Mutt Madness */

.page-template-temp-brackets-promo {

    .diva-hero {

        &--image {

            @include breakpoint (small only) {
                order: 2;
            }
        }

        .large-6 {
            max-width: 39.5rem;
        }
    }
}

.page-template-temp-brackets-promo .section {
    margin-top: 2rem;

    @include breakpoint(medium) {
        margin-top: 3rem;
    }

    @include breakpoint(large) {
        margin-top: 4rem;
    }
}

.sectionAbout {
    align-items: flex-end;

    &-image {
        text-align: center;
    }

    &-imageTag {
        display: block;
        margin: 0 auto;
        max-width: 10rem;
    }
}

.sectionBracket {

    &-header {
        margin-bottom: 2rem;
    }

    &-art {
        margin: 1.5rem 0;

        @include breakpoint(medium) {
            padding: 2rem 0;
        }

        @include breakpoint(large) {
            padding: 3rem 0;
        }
    }

    &-instruction {
        font-size: rem(16);
        font-weight: 700;
        margin-top: rem(-16);

        @include breakpoint(medium) {
            font-size: rem(20);
            margin-top: rem(-48);
        }

        @include breakpoint(large) {
            font-size: rem(24);
            margin-top: rem(-80);
        }
    }

    .bracket-vote {
        background: #57c1e9;
        font-size: 1rem;
        font-weight: 700;
    }
}

.sectionMatches {
    background: #eafaff;
    background-image: url("/wp-content/themes/embarkvet-theme/img/mutt-madness/basketball-small-01.svg");
    background-position: 10% 100%;
    background-repeat: no-repeat;
    background-size: 10rem;
    padding: 3rem 0 5rem;

    .grid-margin-x {
        align-items: flex-start;
        justify-content: center;
        margin: 0 auto;
    }

    .tabs,
    .tabs-panel,
    .tabs-content {
        border: none;
    }

    &-competitor {

        &.is-active ~ .matchCollapse[aria-hidden="true"] {
            display: none;
        }

        .competitorInfo {
            padding: 0 1rem 1rem;

            @include breakpoint(medium) {
                padding: 0 2rem 2rem;
            }

            @include breakpoint(large) {
                padding: 0 3rem 3rem;
            }
        }
    }

    &-competitorTabs {
        background-image: url("/wp-content/themes/embarkvet-theme/img/mutt-madness/versus.png");
        background-position: 50% 4rem;
        background-repeat: no-repeat;
        background-size: 4rem;

        @include breakpoint(medium) {
            background-position: 50% 6.75rem;
        }

        @include breakpoint(large) {
            background-position: 50% 7.75rem;
        }
    }

    &-competitorTitle {

        &:hover .sectionMatches-competitorImage,
        &:focus .sectionMatches-competitorImage {
            box-shadow: 0 0.25rem 1rem rgba(0, 0, 0, 0.32);
        }

        &.is-active {
            background: none;

            .sectionMatches-competitorImage {
                box-shadow: 0 0.25rem 1rem rgba(0, 0, 0, 0.48);
            }

            ~ .matchCollapse-tab {
                display: initial;
            }
        }
    }

    &-competitorImage {
        border-radius: 0.5rem;
        margin: 1.25rem 2rem;
        overflow: hidden;
        padding: 0;

        @include breakpoint(medium) {
            border-radius: 1rem;
        }
    }

    .tabs-title > a {
        padding: 0;
    }

    &-match {

        margin-bottom: rem(32);
        border-radius: rem(5);

        & + & {
            margin-top: rem(24);

            @include breakpoint(medium) {
                margin-top: 0;
            }
        }

        &:nth-child(n+4) {
            margin-top: 2rem;
        }
    }

    .matchCollapse {

        &-tab {
            display: none;

            &.is-active {
                display: none;
            }
        }

        &-tabLabel {
            font-weight: 700;
            margin: 1rem;
            text-align: center;
            text-transform: uppercase;

            .paragraph {
                font-size: 1rem;
            }
        }
    }
}

.competitorInfo {
    justify-content: center;

    &-namePlace {
        border-bottom: 0.25rem solid $yellow;
        margin-bottom: 1rem;

        @include breakpoint(medium) {
            margin-bottom: 1.5rem;
        }

        @include breakpoint(large) {
            margin-bottom: 2rem;
        }

        .competitorInfo-name {
            font-size: 2rem;
            font-weight: 700;
        }
    }

    &-image {
        align-self: flex-start;
    }

    &-action {
        display: block;
        width: 100%;
    }

    &-actionButton {
        display: block;

        @include breakpoint(medium) {
            display: inline-block;
            width: initial;
        }
    }

    .statLabel {
        color: #868a8e;
        font-size: 0.875rem;
        font-weight: 700;
        margin: 0;
        text-transform: uppercase;
    }

    .statValue {
        color: #000;
        font-weight: 700;
    }

    &-about {

        .statValue {
            font-weight: 500;
        }
    }
}

.sectionColorfulCTA {
    align-items: center;
    background: #f5ebce;
    background-size: cover;
    border-radius: 0.25rem;
    justify-content: center;
    padding: 1.5rem;

    & + & {
        display: none;
    }

    &-text {
        margin: 2rem 0 3rem;

        .headline3,
        .headline4 {
            margin-bottom: 0.75rem;
        }
    }

    &-image {
        max-width: 20rem;
        width: 100%;
    }
}

.sectionCompetitors {

    &-grid {
        justify-content: center;
    }

    &-individual {

        & + & {
            border-top: 0.125rem solid #eeeeef;
            margin-top: 0.75rem;
            padding-top: 0.5rem;
        }
    }

    .competitorInfo {

        &-imageWrap {
            justify-content: center;
            margin-top: 1rem;

            @include breakpoint(medium) {
                margin-top: 2rem;
            }

            @include breakpoint(large) {
                margin-top: 3rem;
            }
        }

        &-image {
            border-radius: 1rem;
            box-shadow: 0 0.25rem 1rem rgba(0, 0, 0, 0.32);
            overflow: hidden;
        }

        &-list {

            padding: 1rem;

            @include breakpoint(medium) {
                padding: 2rem;
            }

            @include breakpoint(large) {
                padding: 3rem;
            }
        }
    }
}

.sectionSocial {

    .headline1 {
        font-size: 1.8125rem;

        @include breakpoint(medium) {
            font-size: 2.4375rem;
        }

        @include breakpoint(large) {
            font-size: 3.125rem;
        }
    }

    &-socialList {
        justify-content: center;
        list-style-type: none;
        margin: 0;
    }

    &-socialItem {
        list-style-type: none;
        text-align: center;
    }

    &-image {
        display: block;
        height: auto;
        margin: 0 auto;
        max-width: 7rem;
        padding: 1rem;
        width: 100%;
    }

    &-label {
        color: $text-link-blue;
        display: block;
    }
}
