.page-template-temp-consumer-health-search {

    background-color: $light-gray;

    .consumer-search-max-container {

        padding-left: rem(26);
        padding-right: rem(26);
    }

    .section-head {

        max-width: 550px;
        margin-left: auto;
        margin-right: auto;
    }

    .consumer-health-conditions-hero {

        background: $blue-purple;
        color: $white;
        margin-bottom: rem(32);
        padding: rem(32) 0 rem(24);
    }

    .conditions-search,
    .featured-list,
    .got-questions-block {

        margin-bottom: rem(32);
    }

}
