.breed-page {
    font-size: 16px;
    min-width: 320px;
    position: relative;
}

.breed-page html {
    font-family: sans-serif;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}

.breed-page body {
    margin: 0;
}

.breed-page article,
.breed-page aside,
.breed-page details,
.breed-page figcaption,
.breed-page figure,
.breed-page footer,
.breed-page header,
.breed-page hgroup,
.breed-page main,
.breed-page menu,
.breed-page nav,
.breed-page section,
.breed-page summary {
    display: block;
}

.breed-page audio,
.breed-page canvas,
.breed-page progress,
.breed-page video {
    display: inline-block;
    vertical-align: baseline;
}

.breed-page audio:not([controls]) {
    display: none;
    height: 0;
}

.breed-page [hidden],
.breed-page template {
    display: none;
}

.breed-page a {
    background-color: transparent;
}

.breed-page a:active,
.breed-page a:hover {
    outline: 0;
}

.breed-page abbr[title] {
    border-bottom: 1px dotted;
}

.breed-page b,
.breed-page strong {
    font-weight: bold;/* stylelint-disable-line font-weight-notation */
}

.breed-page dfn {
    font-style: italic;
}

.breed-page h1 {
    font-size: 2em;
    margin: 0.67em 0;
}

.breed-page mark {
    background: #ff0;
    color: $black;
}

.breed-page small {
    font-size: 80%;
}

.breed-page sub,
.breed-page sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

.breed-page sup {
    top: -0.5em;
}

.breed-page sub {
    bottom: -0.25em;
}

.breed-page img {
    border: 0;
}

.breed-page svg:not(:root) {
    overflow: hidden;
}

.breed-page figure {
    margin: 1em 40px;
}

.breed-page hr {
    box-sizing: content-box;
    height: 0;
}

.breed-page pre {
    overflow: auto;
}

.breed-page code,
.breed-page kbd,
.breed-page pre,
.breed-page samp {
    font-family: monospace;
    font-size: 1em;
}

.breed-page button,
.breed-page input,
.breed-page optgroup,
.breed-page select,
.breed-page textarea {
    color: inherit;
    font: inherit;
    margin: 0;
}

.breed-page button {
    overflow: visible;
}

.breed-page button,
.breed-page select {
    text-transform: none;
}

.breed-page button,
.breed-page html input[type="button"],
.breed-page input[type="reset"],
.breed-page input[type="submit"] {
    -webkit-appearance: button;
    cursor: pointer;
}

.breed-page button[disabled],
.breed-page html input[disabled] {
    cursor: default;
}

.breed-page button::-moz-focus-inner,
.breed-page input::-moz-focus-inner {
    border: 0;
    padding: 0;
}

.breed-page input {
    line-height: normal;
}

.breed-page input[type="checkbox"],
.breed-page input[type="radio"] {
    box-sizing: border-box;
    padding: 0;
}

.breed-page input[type="number"]::-webkit-inner-spin-button,
.breed-page input[type="number"]::-webkit-outer-spin-button {
    height: auto;
}

.breed-page input[type="search"] {
    -webkit-appearance: textfield;
    box-sizing: content-box;
}

.breed-page input[type="search"]::-webkit-search-cancel-button,
.breed-page input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

.breed-page fieldset {
    border: 1px solid #c0c0c0;
    margin: 0 2px;
    padding: 0.35em 0.625em 0.75em;
}

.breed-page legend {
    border: 0;
    padding: 0;
}

.breed-page textarea {
    overflow: auto;
}

.breed-page optgroup {
    font-weight: bold;/* stylelint-disable-line font-weight-notation */
}

.breed-page table {
    border-collapse: collapse;
    border-spacing: 0;
}

.breed-page td,
.breed-page th {
    padding: 0;
}

.breed-page * {
    box-sizing: border-box;
}

.breed-page *::before,
.breed-page *::after {
    box-sizing: border-box;
}

.breed-page html {/* stylelint-disable-line no-duplicate-selectors */
    font-size: 10px;
}

.breed-page body {/* stylelint-disable-line no-duplicate-selectors */
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 1.42857143;
    color: #333;
    background-color: $white;
}

.breed-page input,
.breed-page button,
.breed-page select,
.breed-page textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

.breed-page a {/* stylelint-disable-line no-duplicate-selectors */
    color: #337ab7;
    text-decoration: none;
}

.breed-page a:hover,
.breed-page a:focus {
    color: #23527c;
    text-decoration: underline;
}

.breed-page a:focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
}

.breed-page figure {/* stylelint-disable-line no-duplicate-selectors */
    margin: 0;
}

.breed-page img {/* stylelint-disable-line no-duplicate-selectors */
    vertical-align: middle;
}

.breed-page .img-responsive {
    display: block;
    max-width: 100%;
    height: auto;
}

.breed-page .img-rounded {
    border-radius: 6px;
}

.breed-page .img-thumbnail {
    padding: 4px;
    line-height: 1.42857143;
    background-color: $white;
    border: 1px solid #ddd;
    border-radius: 4px;
    transition: all 0.2s ease-in-out;
    display: inline-block;
    max-width: 100%;
    height: auto;
}

.breed-page .img-circle {
    border-radius: 50%;
}

.breed-page hr {/* stylelint-disable-line no-duplicate-selectors */
    margin-top: 20px;
    margin-bottom: 20px;
    border: 0;
    border-top: 1px solid #eee;
}

.breed-page .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

.breed-page .sr-only-focusable:active,
.breed-page .sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    clip: auto;
}

.breed-page [role="button"] {
    cursor: pointer;
}

.breed-page .container {
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
}

@media (min-width: 768px) {

    .breed-page .container {
        width: 750px;
    }
}

@media (min-width: 992px) {

    .breed-page .container {
        width: 970px;
    }
}

@media (min-width: 1200px) {

    .breed-page .container {
        width: 1170px;
    }
}

.breed-page .container-fluid {
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
}

.breed-page .row {
    margin-left: -15px;
    margin-right: -15px;
}

.breed-page .col-xs-1,
.breed-page .col-sm-1,
.breed-page .col-md-1,
.breed-page .col-lg-1,
.breed-page .col-xs-2,
.breed-page .col-sm-2,
.breed-page .col-md-2,
.breed-page .col-lg-2,
.breed-page .col-xs-3,
.breed-page .col-sm-3,
.breed-page .col-md-3,
.breed-page .col-lg-3,
.breed-page .col-xs-4,
.breed-page .col-sm-4,
.breed-page .col-md-4,
.breed-page .col-lg-4,
.breed-page .col-xs-5,
.breed-page .col-sm-5,
.breed-page .col-md-5,
.breed-page .col-lg-5,
.breed-page .col-xs-6,
.breed-page .col-sm-6,
.breed-page .col-md-6,
.breed-page .col-lg-6,
.breed-page .col-xs-7,
.breed-page .col-sm-7,
.breed-page .col-md-7,
.breed-page .col-lg-7,
.breed-page .col-xs-8,
.breed-page .col-sm-8,
.breed-page .col-md-8,
.breed-page .col-lg-8,
.breed-page .col-xs-9,
.breed-page .col-sm-9,
.breed-page .col-md-9,
.breed-page .col-lg-9,
.breed-page .col-xs-10,
.breed-page .col-sm-10,
.breed-page .col-md-10,
.breed-page .col-lg-10,
.breed-page .col-xs-11,
.breed-page .col-sm-11,
.breed-page .col-md-11,
.breed-page .col-lg-11,
.breed-page .col-xs-12,
.breed-page .col-sm-12,
.breed-page .col-md-12,
.breed-page .col-lg-12 {
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
}

.breed-page .col-xs-1,
.breed-page .col-xs-2,
.breed-page .col-xs-3,
.breed-page .col-xs-4,
.breed-page .col-xs-5,
.breed-page .col-xs-6,
.breed-page .col-xs-7,
.breed-page .col-xs-8,
.breed-page .col-xs-9,
.breed-page .col-xs-10,
.breed-page .col-xs-11,
.breed-page .col-xs-12 {
    float: left;
}

.breed-page .col-xs-12 {
    width: 100%;
}

.breed-page .col-xs-11 {
    width: 91.66666667%;
}

.breed-page .col-xs-10 {
    width: 83.33333333%;
}

.breed-page .col-xs-9 {
    width: 75%;
}

.breed-page .col-xs-8 {
    width: 66.66666667%;
}

.breed-page .col-xs-7 {
    width: 58.33333333%;
}

.breed-page .col-xs-6 {
    width: 50%;
}

.breed-page .col-xs-5 {
    width: 41.66666667%;
}

.breed-page .col-xs-4 {
    width: 33.33333333%;
}

.breed-page .col-xs-3 {
    width: 25%;
}

.breed-page .col-xs-2 {
    width: 16.66666667%;
}

.breed-page .col-xs-1 {
    width: 8.33333333%;
}

.breed-page .col-xs-pull-12 {
    right: 100%;
}

.breed-page .col-xs-pull-11 {
    right: 91.66666667%;
}

.breed-page .col-xs-pull-10 {
    right: 83.33333333%;
}

.breed-page .col-xs-pull-9 {
    right: 75%;
}

.breed-page .col-xs-pull-8 {
    right: 66.66666667%;
}

.breed-page .col-xs-pull-7 {
    right: 58.33333333%;
}

.breed-page .col-xs-pull-6 {
    right: 50%;
}

.breed-page .col-xs-pull-5 {
    right: 41.66666667%;
}

.breed-page .col-xs-pull-4 {
    right: 33.33333333%;
}

.breed-page .col-xs-pull-3 {
    right: 25%;
}

.breed-page .col-xs-pull-2 {
    right: 16.66666667%;
}

.breed-page .col-xs-pull-1 {
    right: 8.33333333%;
}

.breed-page .col-xs-pull-0 {
    right: auto;
}

.breed-page .col-xs-push-12 {
    left: 100%;
}

.breed-page .col-xs-push-11 {
    left: 91.66666667%;
}

.breed-page .col-xs-push-10 {
    left: 83.33333333%;
}

.breed-page .col-xs-push-9 {
    left: 75%;
}

.breed-page .col-xs-push-8 {
    left: 66.66666667%;
}

.breed-page .col-xs-push-7 {
    left: 58.33333333%;
}

.breed-page .col-xs-push-6 {
    left: 50%;
}

.breed-page .col-xs-push-5 {
    left: 41.66666667%;
}

.breed-page .col-xs-push-4 {
    left: 33.33333333%;
}

.breed-page .col-xs-push-3 {
    left: 25%;
}

.breed-page .col-xs-push-2 {
    left: 16.66666667%;
}

.breed-page .col-xs-push-1 {
    left: 8.33333333%;
}

.breed-page .col-xs-push-0 {
    left: auto;
}

.breed-page .col-xs-offset-12 {
    margin-left: 100%;
}

.breed-page .col-xs-offset-11 {
    margin-left: 91.66666667%;
}

.breed-page .col-xs-offset-10 {
    margin-left: 83.33333333%;
}

.breed-page .col-xs-offset-9 {
    margin-left: 75%;
}

.breed-page .col-xs-offset-8 {
    margin-left: 66.66666667%;
}

.breed-page .col-xs-offset-7 {
    margin-left: 58.33333333%;
}

.breed-page .col-xs-offset-6 {
    margin-left: 50%;
}

.breed-page .col-xs-offset-5 {
    margin-left: 41.66666667%;
}

.breed-page .col-xs-offset-4 {
    margin-left: 33.33333333%;
}

.breed-page .col-xs-offset-3 {
    margin-left: 25%;
}

.breed-page .col-xs-offset-2 {
    margin-left: 16.66666667%;
}

.breed-page .col-xs-offset-1 {
    margin-left: 8.33333333%;
}

.breed-page .col-xs-offset-0 {
    margin-left: 0;
}

@media (min-width: 768px) {

    .breed-page .col-sm-1,
    .breed-page .col-sm-2,
    .breed-page .col-sm-3,
    .breed-page .col-sm-4,
    .breed-page .col-sm-5,
    .breed-page .col-sm-6,
    .breed-page .col-sm-7,
    .breed-page .col-sm-8,
    .breed-page .col-sm-9,
    .breed-page .col-sm-10,
    .breed-page .col-sm-11,
    .breed-page .col-sm-12 {
        float: left;
    }

    .breed-page .col-sm-12 {
        width: 100%;
    }

    .breed-page .col-sm-11 {
        width: 91.66666667%;
    }

    .breed-page .col-sm-10 {
        width: 83.33333333%;
    }

    .breed-page .col-sm-9 {
        width: 75%;
    }

    .breed-page .col-sm-8 {
        width: 66.66666667%;
    }

    .breed-page .col-sm-7 {
        width: 58.33333333%;
    }

    .breed-page .col-sm-6 {
        width: 50%;
    }

    .breed-page .col-sm-5 {
        width: 41.66666667%;
    }

    .breed-page .col-sm-4 {
        width: 33.33333333%;
    }

    .breed-page .col-sm-3 {
        width: 25%;
    }

    .breed-page .col-sm-2 {
        width: 16.66666667%;
    }

    .breed-page .col-sm-1 {
        width: 8.33333333%;
    }

    .breed-page .col-sm-pull-12 {
        right: 100%;
    }

    .breed-page .col-sm-pull-11 {
        right: 91.66666667%;
    }

    .breed-page .col-sm-pull-10 {
        right: 83.33333333%;
    }

    .breed-page .col-sm-pull-9 {
        right: 75%;
    }

    .breed-page .col-sm-pull-8 {
        right: 66.66666667%;
    }

    .breed-page .col-sm-pull-7 {
        right: 58.33333333%;
    }

    .breed-page .col-sm-pull-6 {
        right: 50%;
    }

    .breed-page .col-sm-pull-5 {
        right: 41.66666667%;
    }

    .breed-page .col-sm-pull-4 {
        right: 33.33333333%;
    }

    .breed-page .col-sm-pull-3 {
        right: 25%;
    }

    .breed-page .col-sm-pull-2 {
        right: 16.66666667%;
    }

    .breed-page .col-sm-pull-1 {
        right: 8.33333333%;
    }

    .breed-page .col-sm-pull-0 {
        right: auto;
    }

    .breed-page .col-sm-push-12 {
        left: 100%;
    }

    .breed-page .col-sm-push-11 {
        left: 91.66666667%;
    }

    .breed-page .col-sm-push-10 {
        left: 83.33333333%;
    }

    .breed-page .col-sm-push-9 {
        left: 75%;
    }

    .breed-page .col-sm-push-8 {
        left: 66.66666667%;
    }

    .breed-page .col-sm-push-7 {
        left: 58.33333333%;
    }

    .breed-page .col-sm-push-6 {
        left: 50%;
    }

    .breed-page .col-sm-push-5 {
        left: 41.66666667%;
    }

    .breed-page .col-sm-push-4 {
        left: 33.33333333%;
    }

    .breed-page .col-sm-push-3 {
        left: 25%;
    }

    .breed-page .col-sm-push-2 {
        left: 16.66666667%;
    }

    .breed-page .col-sm-push-1 {
        left: 8.33333333%;
    }

    .breed-page .col-sm-push-0 {
        left: auto;
    }

    .breed-page .col-sm-offset-12 {
        margin-left: 100%;
    }

    .breed-page .col-sm-offset-11 {
        margin-left: 91.66666667%;
    }

    .breed-page .col-sm-offset-10 {
        margin-left: 83.33333333%;
    }

    .breed-page .col-sm-offset-9 {
        margin-left: 75%;
    }

    .breed-page .col-sm-offset-8 {
        margin-left: 66.66666667%;
    }

    .breed-page .col-sm-offset-7 {
        margin-left: 58.33333333%;
    }

    .breed-page .col-sm-offset-6 {
        margin-left: 50%;
    }

    .breed-page .col-sm-offset-5 {
        margin-left: 41.66666667%;
    }

    .breed-page .col-sm-offset-4 {
        margin-left: 33.33333333%;
    }

    .breed-page .col-sm-offset-3 {
        margin-left: 25%;
    }

    .breed-page .col-sm-offset-2 {
        margin-left: 16.66666667%;
    }

    .breed-page .col-sm-offset-1 {
        margin-left: 8.33333333%;
    }

    .breed-page .col-sm-offset-0 {
        margin-left: 0;
    }
}

@media (min-width: 992px) {

    .breed-page .col-md-1,
    .breed-page .col-md-2,
    .breed-page .col-md-3,
    .breed-page .col-md-4,
    .breed-page .col-md-5,
    .breed-page .col-md-6,
    .breed-page .col-md-7,
    .breed-page .col-md-8,
    .breed-page .col-md-9,
    .breed-page .col-md-10,
    .breed-page .col-md-11,
    .breed-page .col-md-12 {
        float: left;
    }

    .breed-page .col-md-12 {
        width: 100%;
    }

    .breed-page .col-md-11 {
        width: 91.66666667%;
    }

    .breed-page .col-md-10 {
        width: 83.33333333%;
    }

    .breed-page .col-md-9 {
        width: 75%;
    }

    .breed-page .col-md-8 {
        width: 66.66666667%;
    }

    .breed-page .col-md-7 {
        width: 58.33333333%;
    }

    .breed-page .col-md-6 {
        width: 50%;
    }

    .breed-page .col-md-5 {
        width: 41.66666667%;
    }

    .breed-page .col-md-4 {
        width: 33.33333333%;
    }

    .breed-page .col-md-3 {
        width: 25%;
    }

    .breed-page .col-md-2 {
        width: 16.66666667%;
    }

    .breed-page .col-md-1 {
        width: 8.33333333%;
    }

    .breed-page .col-md-pull-12 {
        right: 100%;
    }

    .breed-page .col-md-pull-11 {
        right: 91.66666667%;
    }

    .breed-page .col-md-pull-10 {
        right: 83.33333333%;
    }

    .breed-page .col-md-pull-9 {
        right: 75%;
    }

    .breed-page .col-md-pull-8 {
        right: 66.66666667%;
    }

    .breed-page .col-md-pull-7 {
        right: 58.33333333%;
    }

    .breed-page .col-md-pull-6 {
        right: 50%;
    }

    .breed-page .col-md-pull-5 {
        right: 41.66666667%;
    }

    .breed-page .col-md-pull-4 {
        right: 33.33333333%;
    }

    .breed-page .col-md-pull-3 {
        right: 25%;
    }

    .breed-page .col-md-pull-2 {
        right: 16.66666667%;
    }

    .breed-page .col-md-pull-1 {
        right: 8.33333333%;
    }

    .breed-page .col-md-pull-0 {
        right: auto;
    }

    .breed-page .col-md-push-12 {
        left: 100%;
    }

    .breed-page .col-md-push-11 {
        left: 91.66666667%;
    }

    .breed-page .col-md-push-10 {
        left: 83.33333333%;
    }

    .breed-page .col-md-push-9 {
        left: 75%;
    }

    .breed-page .col-md-push-8 {
        left: 66.66666667%;
    }

    .breed-page .col-md-push-7 {
        left: 58.33333333%;
    }

    .breed-page .col-md-push-6 {
        left: 50%;
    }

    .breed-page .col-md-push-5 {
        left: 41.66666667%;
    }

    .breed-page .col-md-push-4 {
        left: 33.33333333%;
    }

    .breed-page .col-md-push-3 {
        left: 25%;
    }

    .breed-page .col-md-push-2 {
        left: 16.66666667%;
    }

    .breed-page .col-md-push-1 {
        left: 8.33333333%;
    }

    .breed-page .col-md-push-0 {
        left: auto;
    }

    .breed-page .col-md-offset-12 {
        margin-left: 100%;
    }

    .breed-page .col-md-offset-11 {
        margin-left: 91.66666667%;
    }

    .breed-page .col-md-offset-10 {
        margin-left: 83.33333333%;
    }

    .breed-page .col-md-offset-9 {
        margin-left: 75%;
    }

    .breed-page .col-md-offset-8 {
        margin-left: 66.66666667%;
    }

    .breed-page .col-md-offset-7 {
        margin-left: 58.33333333%;
    }

    .breed-page .col-md-offset-6 {
        margin-left: 50%;
    }

    .breed-page .col-md-offset-5 {
        margin-left: 41.66666667%;
    }

    .breed-page .col-md-offset-4 {
        margin-left: 33.33333333%;
    }

    .breed-page .col-md-offset-3 {
        margin-left: 25%;
    }

    .breed-page .col-md-offset-2 {
        margin-left: 16.66666667%;
    }

    .breed-page .col-md-offset-1 {
        margin-left: 8.33333333%;
    }

    .breed-page .col-md-offset-0 {
        margin-left: 0;
    }
}

@media (min-width: 1200px) {

    .breed-page .col-lg-1,
    .breed-page .col-lg-2,
    .breed-page .col-lg-3,
    .breed-page .col-lg-4,
    .breed-page .col-lg-5,
    .breed-page .col-lg-6,
    .breed-page .col-lg-7,
    .breed-page .col-lg-8,
    .breed-page .col-lg-9,
    .breed-page .col-lg-10,
    .breed-page .col-lg-11,
    .breed-page .col-lg-12 {
        float: left;
    }

    .breed-page .col-lg-12 {
        width: 100%;
    }

    .breed-page .col-lg-11 {
        width: 91.66666667%;
    }

    .breed-page .col-lg-10 {
        width: 83.33333333%;
    }

    .breed-page .col-lg-9 {
        width: 75%;
    }

    .breed-page .col-lg-8 {
        width: 66.66666667%;
    }

    .breed-page .col-lg-7 {
        width: 58.33333333%;
    }

    .breed-page .col-lg-6 {
        width: 50%;
    }

    .breed-page .col-lg-5 {
        width: 41.66666667%;
    }

    .breed-page .col-lg-4 {
        width: 33.33333333%;
    }

    .breed-page .col-lg-3 {
        width: 25%;
    }

    .breed-page .col-lg-2 {
        width: 16.66666667%;
    }

    .breed-page .col-lg-1 {
        width: 8.33333333%;
    }

    .breed-page .col-lg-pull-12 {
        right: 100%;
    }

    .breed-page .col-lg-pull-11 {
        right: 91.66666667%;
    }

    .breed-page .col-lg-pull-10 {
        right: 83.33333333%;
    }

    .breed-page .col-lg-pull-9 {
        right: 75%;
    }

    .breed-page .col-lg-pull-8 {
        right: 66.66666667%;
    }

    .breed-page .col-lg-pull-7 {
        right: 58.33333333%;
    }

    .breed-page .col-lg-pull-6 {
        right: 50%;
    }

    .breed-page .col-lg-pull-5 {
        right: 41.66666667%;
    }

    .breed-page .col-lg-pull-4 {
        right: 33.33333333%;
    }

    .breed-page .col-lg-pull-3 {
        right: 25%;
    }

    .breed-page .col-lg-pull-2 {
        right: 16.66666667%;
    }

    .breed-page .col-lg-pull-1 {
        right: 8.33333333%;
    }

    .breed-page .col-lg-pull-0 {
        right: auto;
    }

    .breed-page .col-lg-push-12 {
        left: 100%;
    }

    .breed-page .col-lg-push-11 {
        left: 91.66666667%;
    }

    .breed-page .col-lg-push-10 {
        left: 83.33333333%;
    }

    .breed-page .col-lg-push-9 {
        left: 75%;
    }

    .breed-page .col-lg-push-8 {
        left: 66.66666667%;
    }

    .breed-page .col-lg-push-7 {
        left: 58.33333333%;
    }

    .breed-page .col-lg-push-6 {
        left: 50%;
    }

    .breed-page .col-lg-push-5 {
        left: 41.66666667%;
    }

    .breed-page .col-lg-push-4 {
        left: 33.33333333%;
    }

    .breed-page .col-lg-push-3 {
        left: 25%;
    }

    .breed-page .col-lg-push-2 {
        left: 16.66666667%;
    }

    .breed-page .col-lg-push-1 {
        left: 8.33333333%;
    }

    .breed-page .col-lg-push-0 {
        left: auto;
    }

    .breed-page .col-lg-offset-12 {
        margin-left: 100%;
    }

    .breed-page .col-lg-offset-11 {
        margin-left: 91.66666667%;
    }

    .breed-page .col-lg-offset-10 {
        margin-left: 83.33333333%;
    }

    .breed-page .col-lg-offset-9 {
        margin-left: 75%;
    }

    .breed-page .col-lg-offset-8 {
        margin-left: 66.66666667%;
    }

    .breed-page .col-lg-offset-7 {
        margin-left: 58.33333333%;
    }

    .breed-page .col-lg-offset-6 {
        margin-left: 50%;
    }

    .breed-page .col-lg-offset-5 {
        margin-left: 41.66666667%;
    }

    .breed-page .col-lg-offset-4 {
        margin-left: 33.33333333%;
    }

    .breed-page .col-lg-offset-3 {
        margin-left: 25%;
    }

    .breed-page .col-lg-offset-2 {
        margin-left: 16.66666667%;
    }

    .breed-page .col-lg-offset-1 {
        margin-left: 8.33333333%;
    }

    .breed-page .col-lg-offset-0 {
        margin-left: 0;
    }
}

.breed-page .clearfix::before,
.breed-page .clearfix::after,
.breed-page .container::before,
.breed-page .container::after,
.breed-page .container-fluid::before,
.breed-page .container-fluid::after,
.breed-page .row::before,
.breed-page .row::after {
    content: " ";
    display: table;
}

.breed-page .clearfix::after,
.breed-page .container::after,
.breed-page .container-fluid::after,
.breed-page .row::after {
    clear: both;
}

.breed-page .center-block {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.breed-page .pull-right {
    float: right !important;
}

.breed-page .pull-left {
    float: left !important;
}

.breed-page .hide {
    display: none !important;
}

.breed-page .show {
    display: block !important;
}

.breed-page .invisible {
    visibility: hidden;
}

.breed-page .text-hide {
    font: 0/0 a;/* stylelint-disable-line */
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
}

.breed-page .hidden {
    display: none !important;
}

.breed-page .affix {
    position: fixed;
}
/* stylelint-disable */
@-ms-viewport {

    .breed-page {
        width: device-width;
    }
}
/* stylelint-enable */

.breed-page .visible-xs,
.breed-page .visible-sm,
.breed-page .visible-md,
.breed-page .visible-lg {
    display: none !important;
}

.breed-page .visible-xs-block,
.breed-page .visible-xs-inline,
.breed-page .visible-xs-inline-block,
.breed-page .visible-sm-block,
.breed-page .visible-sm-inline,
.breed-page .visible-sm-inline-block,
.breed-page .visible-md-block,
.breed-page .visible-md-inline,
.breed-page .visible-md-inline-block,
.breed-page .visible-lg-block,
.breed-page .visible-lg-inline,
.breed-page .visible-lg-inline-block {
    display: none !important;
}

@media (max-width: 767px) {

    .breed-page .visible-xs {
        display: block !important;
    }

    .breed-page table.visible-xs {
        display: table !important;
    }

    .breed-page tr.visible-xs {
        display: table-row !important;
    }

    .breed-page th.visible-xs,
    .breed-page td.visible-xs {
        display: table-cell !important;
    }
}

@media (max-width: 767px) {

    .breed-page .visible-xs-block {
        display: block !important;
    }
}

@media (max-width: 767px) {

    .breed-page .visible-xs-inline {
        display: inline !important;
    }
}

@media (max-width: 767px) {

    .breed-page .visible-xs-inline-block {
        display: inline-block !important;
    }
}

@media (min-width: 768px) and (max-width: 991px) {

    .breed-page .visible-sm {
        display: block !important;
    }

    .breed-page table.visible-sm {
        display: table !important;
    }

    .breed-page tr.visible-sm {
        display: table-row !important;
    }

    .breed-page th.visible-sm,
    .breed-page td.visible-sm {
        display: table-cell !important;
    }
}

@media (min-width: 768px) and (max-width: 991px) {

    .breed-page .visible-sm-block {
        display: block !important;
    }
}

@media (min-width: 768px) and (max-width: 991px) {

    .breed-page .visible-sm-inline {
        display: inline !important;
    }
}

@media (min-width: 768px) and (max-width: 991px) {

    .breed-page .visible-sm-inline-block {
        display: inline-block !important;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {

    .breed-page .visible-md {
        display: block !important;
    }

    .breed-page table.visible-md {
        display: table !important;
    }

    .breed-page tr.visible-md {
        display: table-row !important;
    }

    .breed-page th.visible-md,
    .breed-page td.visible-md {
        display: table-cell !important;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {

    .breed-page .visible-md-block {
        display: block !important;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {

    .breed-page .visible-md-inline {
        display: inline !important;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {

    .breed-page .visible-md-inline-block {
        display: inline-block !important;
    }
}

@media (min-width: 1200px) {

    .breed-page .visible-lg {
        display: block !important;
    }

    .breed-page table.visible-lg {
        display: table !important;
    }

    .breed-page tr.visible-lg {
        display: table-row !important;
    }

    .breed-page th.visible-lg,
    .breed-page td.visible-lg {
        display: table-cell !important;
    }
}

@media (min-width: 1200px) {

    .breed-page .visible-lg-block {
        display: block !important;
    }
}

@media (min-width: 1200px) {

    .breed-page .visible-lg-inline {
        display: inline !important;
    }
}

@media (min-width: 1200px) {

    .breed-page .visible-lg-inline-block {
        display: inline-block !important;
    }
}

@media (max-width: 767px) {

    .breed-page .hidden-xs {
        display: none !important;
    }
}

@media (min-width: 768px) and (max-width: 991px) {

    .breed-page .hidden-sm {
        display: none !important;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {

    .breed-page .hidden-md {
        display: none !important;
    }
}

@media (min-width: 1200px) {

    .breed-page .hidden-lg {
        display: none !important;
    }
}

.breed-page .visible-print {
    display: none !important;
}

@media print {

    .breed-page .visible-print {
        display: block !important;
    }

    .breed-page table.visible-print {
        display: table !important;
    }

    .breed-page tr.visible-print {
        display: table-row !important;
    }

    .breed-page th.visible-print,
    .breed-page td.visible-print {
        display: table-cell !important;
    }
}

.breed-page .visible-print-block {
    display: none !important;
}

@media print {

    .breed-page .visible-print-block {
        display: block !important;
    }
}

.breed-page .visible-print-inline {
    display: none !important;
}

@media print {

    .breed-page .visible-print-inline {
        display: inline !important;
    }
}

.breed-page .visible-print-inline-block {
    display: none !important;
}

@media print {

    .breed-page .visible-print-inline-block {
        display: inline-block !important;
    }
}

@media print {

    .breed-page .hidden-print {
        display: none !important;
    }
}

.breed-page * {/* stylelint-disable-line no-duplicate-selectors */
    font-size: 16px;
    font-family: "Helvetica Neue";/* stylelint-disable-line font-family-no-missing-generic-family-keyword */
    box-sizing: border-box;
}
/* stylelint-disable-next-line*/
.breed-page *::before,
.breed-page *::after {
    box-sizing: border-box;
}

.breed-page p {
    line-height: 22px;
    text-align: justify;
    text-justify: inter-word;
}

.breed-page .img-responsive {/* stylelint-disable-line no-duplicate-selectors */
    display: block;
    max-width: 100%;
    height: auto;
}

.breed-page span.yellow {
    background: $yellow;
}

.breed-page img {/* stylelint-disable-line no-duplicate-selectors */
    max-width: 100%;
    max-height: 100%;
}

.breed-page .hero {
    background-size: cover;
    height: 150px;
    position: relative;
    margin-top: 0;
}

@media only screen and (min-width: 480px) {

    .breed-page .hero {
        height: 430px;
    }
}

.breed-page .hero h1 {
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 20px;
    line-height: 18px;
    margin-top: 20px;
    width: 80%;
    margin-left: -20px;
}

@media only screen and (min-width: 480px) {

    .breed-page .hero h1 {
        font-size: 48px;
        line-height: 28px;
        margin-top: 100px;
        width: 100%;
    }
}

@media only screen and (min-width: 769px) {

    .breed-page .hero h1 {
        font-size: 48px;
        line-height: 28px;
        margin-top: 100px;
    }
}

@media only screen and (min-width: 1200px) {

    .breed-page .hero h1 {
        font-size: 68px;
        line-height: 28px;
        margin-top: 100px;
    }
}

.breed-page .hero h1 small {
    text-transform: none;
    font-weight: 100;
    color: #333;
    font-size: 15px;
}

@media only screen and (min-width: 480px) {

    .breed-page .hero h1 small {
        font-size: 33px;
    }
}

@media only screen and (min-width: 769px) {

    .breed-page .hero h1 small {
        font-size: 36px;
    }
}

@media only screen and (min-width: 1200px) {

    .breed-page .hero h1 small {
        font-size: 46px;
    }
}

.breed-page .hero h1 .smart-guide {
    font-size: 48px;
    line-height: 41px;
}

.breed-page .hero h1 span {
    font-size: 28px;
}

.breed-page .hero h1 span.embark {
    display: inline-block;
    padding: 3px 5px;
    margin-bottom: 10px;
}

@media only screen and (min-width: 480px) {

    .breed-page .hero h1 span.embark {
        margin-bottom: 30px;
        padding: 10px 12px;
    }
}

.breed-page .hero h1 span.embark > span {
    display: inline-block;
    position: relative;
    font-size: 20px;
    top: 7px;
    left: -5px;
}

@media only screen and (min-width: 480px) {

    .breed-page .hero h1 span.embark > span {
        font-size: 30px;
    }
}

.breed-page .hero h1 span.embark img {
    width: 100px;
}

@media only screen and (min-width: 480px) {

    .breed-page .hero h1 span.embark img {
        width: auto;
    }
}

.breed-page .hero h1 span.rotate {
    display: inline-block;
    transform: rotate(-18deg);
    text-transform: none;
    padding: 2px 9px;
    font-weight: 600;
    font-size: 20px;
}

@media only screen and (min-width: 480px) {

    .breed-page .hero h1 span.rotate {
        padding: 2px 9px;
    }
}

.breed-page h1 {/* stylelint-disable-line no-duplicate-selectors */
    font-size: 20px;
    line-height: 22px;
    font-weight: 300;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 10px;
}

@media only screen and (min-width: 480px) {

    .breed-page h1 {
        margin-top: 40px;
        margin-bottom: 20px;
        font-size: 40px;
        line-height: 39px;
    }
}

.breed-page .center-align {
    text-align: center;
}

.breed-page .right-align {
    text-align: right;
}

.breed-page section {
    margin: 50px 0;
}

@media only screen and (min-width: 480px) {

    .breed-page section {
        margin: 50px 0;
    }
}

.breed-page #five-things-to-know .center-align {
    margin-bottom: 60px;
}

.breed-page #five-things-to-know .item {
    font-size: 17px;
    line-height: 20px;
    margin-bottom: 20px;
    margin-top: 20px;
}

@media only screen and (min-width: 480px) {

    .breed-page #five-things-to-know .item {
        font-size: 23px;
        line-height: 32px;
        margin-bottom: 50px;
    }
}

.breed-page #five-things-to-know .item p {
    font-weight: 600;
}

.breed-page #five-things-to-know .item .number {
    font-size: 29px;
    font-weight: 700;
    width: 45px;
    height: 40px;
    line-height: 40px;
    padding-left: 5px;
    display: block;
    float: left;
    margin-right: 15px;
    margin-bottom: 5px;
    margin-top: 5px;
}

@media only screen and (min-width: 480px) {

    .breed-page #five-things-to-know .item .number {
        font-size: 59px;
        width: 85px;
        height: 80px;
        line-height: 90px;
        padding-left: 10px;
        margin-right: 30px;
        margin-bottom: 0;
        margin-top: 0;
    }
}

.breed-page .bg-purple {
    background: #a776a6;
    color: $white;
    padding-top: 25px;
    padding-bottom: 25px;
}

@media only screen and (min-width: 480px) {

    .breed-page .bg-purple {
        padding-top: 50px;
        padding-bottom: 50px;
    }
}

.breed-page .bg-red {
    background: #f15b5c;
    color: $white;
    padding-top: 25px;
    padding-bottom: 25px;
}

@media only screen and (min-width: 480px) {

    .breed-page .bg-red {
        padding-top: 50px;
        padding-bottom: 50px;
    }
}

.breed-page .bg-yellow {
    background: $yellow;
}

.breed-page .bg-grey {
    background: #e5e5e5;
}

.breed-page .bg-light-grey {
    background: #e7e7e7;
    padding-top: 25px;
    padding-bottom: 25px;
}

@media only screen and (min-width: 480px) {

    .breed-page .bg-light-grey {
        padding-top: 50px;
        padding-bottom: 50px;
    }
}

.breed-page .bg-blue {
    background: #57c1ea;
}

.breed-page #faq {
    margin-right: -15px;
    margin-left: -15px;
    padding: 15px 15px 30px;
    margin-bottom: 0;
}

@media only screen and (min-width: 480px) {

    .breed-page #faq {
        margin-right: 0;
        margin-left: 0;
    }
}

.breed-page #faq .question b {
    font-size: 20px;
    line-height: 25px;
    margin-top: 20px;
    display: block;
}

@media only screen and (min-width: 480px) {

    .breed-page #faq .question b {
        margin-top: 50px;
    }
}

.breed-page #key-characteristics {
    overflow: hidden;
    margin-right: -15px;
    margin-left: -15px;
    margin-top: -9px;
    padding: 15px;
}

@media only screen and (min-width: 768px) {

    .breed-page #key-characteristics {
        margin-right: 0;
        margin-left: 0;
        padding-bottom: 100px;
    }
}

.breed-page #key-characteristics .item {
    margin-top: 20px;
}

@media only screen and (min-width: 768px) {

    .breed-page #key-characteristics .item {
        margin-top: 35px;
    }
}

.breed-page #key-characteristics .item p {
    margin: 0;
    padding: 0;
    text-align: center;
}

@media only screen and (min-width: 768px) {

    .breed-page #key-characteristics .item p {
        text-align: left;
    }
}

.breed-page #key-characteristics .item b {
    text-align: center;
    width: 100%;
    display: block;
}

@media only screen and (min-width: 768px) {

    .breed-page #key-characteristics .item b {
        text-align: left;
    }
}

.breed-page #key-characteristics .item img {
    margin: 0 auto;
    display: block;
    width: 40%;
    margin-top: 30px;
    margin-bottom: 10px;
}

@media only screen and (min-width: 768px) {

    .breed-page #key-characteristics .item img {
        margin: auto;
        width: 100%;
        margin-top: 0;
    }
}

.breed-page #fun-facts {
    font-size: 20px;
    line-height: 27px;
}

.breed-page #fun-facts .right-column {
    padding-top: 20px;
}

@media only screen and (min-width: 769px) {

    .breed-page #fun-facts .right-column {
        padding-top: 80px;
    }
}

.breed-page #fun-facts .breed-icon {
    margin-right: -15px;
}

@media only screen and (min-width: 480px) {

    .breed-page #fun-facts .breed-icon {
        margin: 60px 0;
        margin-right: 0;
    }
}

.breed-page #fun-facts .fun-facts-img {
    max-height: 290px;
}

.breed-page #fun-facts .right-align {
    padding-top: 10px;
    text-align: left;
    margin-bottom: 10px;
}

@media only screen and (min-width: 480px) {

    .breed-page #fun-facts .right-align {
        text-align: right;
        padding-top: 30px;
        margin-bottom: 0;
    }
}

.breed-page #fun-facts .bg-yellow {
    padding: 50px 40px;
    margin-top: 20px;
}

@media only screen and (min-width: 480px) {

    .breed-page #fun-facts .bg-yellow {
        margin-top: 0;
    }
}

.breed-page #fun-facts .bg-yellow a {
    color: $black;
    font-weight: 700;
    text-decoration: underline;
}

.breed-page #social-feed {
    overflow: hidden;
    padding-bottom: 50px;
    margin-right: -15px;
    margin-left: -15px;
    padding-right: 15px;
    padding-left: 15px;
    background: #84888d;
    margin-top: -10px;
}

@media only screen and (min-width: 480px) {

    .breed-page #social-feed {
        margin-right: 0;
        margin-left: 0;
        background: #e5e5e5;
        margin-top: 100px;
    }
}

.breed-page #social-feed .top {
    background: #84888d;
    color: $white;
    padding: 30px 0 130px 0;
    margin-right: -30px;
    margin-left: -30px;
}

@media only screen and (min-width: 769px) {

    .breed-page #social-feed .top {
        margin-left: -15px;
        margin-right: -15px;
    }
}

.breed-page #social-feed .insta-photo {
    display: none;
}

@media only screen and (min-width: 480px) {

    .breed-page #social-feed .insta-photo {
        display: block;
        width: 50%;
        margin-top: 20px;
        float: left;
    }
}

.breed-page #social-feed .profiles {
    margin-top: -100px;
}

.breed-page #social-feed .profiles .foto {
    display: inline-block;
    margin-right: 5px;
}

@media only screen and (min-width: 480px) {

    .breed-page #social-feed .profiles .foto {
        margin-right: 10px;
    }
}

.breed-page #social-feed .profiles .nome {
    font-size: 16px;
    font-weight: 700;
    display: inline-block;
    margin-top: 10px;
}

@media only screen and (min-width: 769px) {

    .breed-page #social-feed .profiles .nome {
        font-size: 24px;
    }
}

.breed-page #social-feed .profiles a.insta-btn {
    display: block;
    float: right;
    width: 50px;
    height: 45px;
    text-align: center;
    line-height: 45px;
}

.breed-page #social-feed .profiles a.insta-btn img {
    margin: 0 auto;
}

.breed-page #social-feed .profiles .clear-profile {
    color: $white;
    margin-bottom: 30px;
}

.breed-page #social-feed .profiles .clear-profile a {
    background: $white;
}

@media only screen and (max-width: 767px) {

    .breed-page #social-feed .profiles .clear-profile:nth-of-type(2) {
        color: #333;
    }
}

@media only screen and (max-width: 480px) {

    .breed-page #social-feed .profiles .clear-profile:nth-of-type(2) {
        color: $white;
    }
}

.breed-page #social-feed .profiles .dark-profile {
    color: $white;
    margin-bottom: 30px;
}

@media only screen and (min-width: 480px) {

    .breed-page #social-feed .profiles .dark-profile {
        color: #333;
        margin-bottom: 0;
    }
}

.breed-page #social-feed .profiles .dark-profile a {
    background: $white;
}

@media only screen and (min-width: 480px) {

    .breed-page #social-feed .profiles .dark-profile a {
        background: #84888d;
    }
}

.breed-page #social-feed .profiles .dark-profile .icon-dark {
    display: block;
    margin: 0 auto;
    margin-top: 10px;
}

@media only screen and (min-width: 480px) {

    .breed-page #social-feed .profiles .dark-profile .icon-dark {
        display: none;
    }
}

.breed-page #social-feed .profiles .dark-profile .icon-clear {
    display: none;
    margin-top: 10px;
}

@media only screen and (min-width: 480px) {

    .breed-page #social-feed .profiles .dark-profile .icon-clear {
        display: block;
    }
}

.breed-page #health-issues h1 {
    margin-bottom: 50px;
}

.breed-page #health-issues .health-issues-list {
    list-style: none;
    padding: 0;
}

.breed-page #health-issues .health-issues-list li {
    border-bottom: 1px solid #c6c6c6;
    padding-bottom: 20px;
    margin-top: 30px;
}

.breed-page #health-issues .health-issues-list h2 {
    margin-bottom: 0;
    font-size: 20px;
    line-height: 25px;
}

.breed-page #health-issues .health-issues-list p.excerpt {
    margin: 0;
}

.breed-page #health-issues .health-issues-list .show-more,
.breed-page #health-issues .health-issues-list .show-less {
    color: #51c0eb;
}

.breed-page #health-issues .health-issues-list .info {
    padding: 0 30px 30px 30px;
    border-bottom: 0;
}

.breed-page #health-issues .health-issues-list .info p {
    text-align: center;
}

.breed-page #health-issues .health-issues-list .info img {
    display: block;
    margin: 0 auto 20px auto;
}

.breed-page #health-issues .health-issues-list .hidden-content {
    display: none;
}

.breed-page #what-to-ask-breeders {
    margin-right: -15px;
    margin-left: -15px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 34px;
    margin-bottom: 0;
}

@media only screen and (min-width: 480px) {

    .breed-page #what-to-ask-breeders {
        margin-left: 0;
        margin-right: 0;
    }
}

.breed-page #what-to-ask-breeders h2 {
    line-height: 25px;
    font-size: 1.3rem;
}

.breed-page #what-to-ask-breeders .questions-for-breeders {
    list-style: none;
    padding: 0;
}

.breed-page #what-to-ask-breeders .questions-for-breeders li {
    counter-increment: item;
    margin-bottom: 5px;
}

.breed-page #what-to-ask-breeders .questions-for-breeders li::before {
    margin-right: 10px;
    content: counter(item);
    border-radius: 100%;
    background: $yellow;
    width: 24px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    display: block;
    float: left;
    font-weight: 700;
    font-size: 15px;
}

.breed-page #what-to-ask-breeders .recommended-breeders {
    background: #a776a6;
    color: $white;
    padding: 30px 40px;
    list-style: none;
    margin-right: -30px;
    margin-left: -30px;
}

@media only screen and (min-width: 480px) {

    .breed-page #what-to-ask-breeders .recommended-breeders {
        margin-left: 0;
    }
}

@media only screen and (min-width: 769px) {

    .breed-page #what-to-ask-breeders .recommended-breeders {
        margin-right: -150px;
    }
}

.breed-page #what-to-ask-breeders .recommended-breeders strong {
    font-size: 25px;
}

.breed-page #what-to-ask-breeders .recommended-breeders a {
    color: $white;
    text-decoration: underline;
    font-weight: 800;
}

.breed-page #dog-profiles {
    color: $white;
    margin-top: -25px;
    padding-top: 30px;
}

@media only screen and (min-width: 480px) {

    .breed-page #dog-profiles {
        padding-top: 50px;
        padding-bottom: 50px;
    }
}

.breed-page #dog-profiles h1 {
    margin-bottom: 50px;
}

.breed-page #dog-profiles ul.embark-profiles {
    list-style: none;
    padding: 0;
    text-align: center;
    margin-top: 50px;
}

.breed-page #dog-profiles ul.embark-profiles li {
    display: inline-block;
    text-align: center;
    margin: 10px;
}

@media only screen and (min-width: 1200px) {

    .breed-page #dog-profiles ul.embark-profiles li {
        margin: 0 40px;
    }
}

.breed-page #dog-profiles ul.embark-profiles li img,
.breed-page #dog-profiles ul.embark-profiles li strong,
.breed-page #dog-profiles ul.embark-profiles li a {
    display: block;
}

.breed-page #dog-profiles ul.embark-profiles li img {
    margin: 0 auto;
}

.breed-page #dog-profiles ul.embark-profiles li strong {
    text-transform: uppercase;
    font-size: 28px;
}

.breed-page #dog-profiles ul.embark-profiles li a {
    color: $white;
    font-size: 15px;
}

.breed-page #why-embark-test {
    margin-top: 50px;
    margin-bottom: 50px;
}

.breed-page #why-embark-test h1 {
    margin-top: 10px;
}

.breed-page #cta a {
    display: block;
    background: $yellow;
    color: $black;
    text-align: center;
    padding: 30px 0;
}

.breed-page #cta a h2,
.breed-page #cta a h1 {
    margin: 0;
}

.breed-page #cta a h1 {
    font-weight: 700;
    margin-top: 10px;
}

.breed-page #cta a .price {
    font-size: 20px;
    font-weight: 700;
}

.breed-page #cta a:hover {
    text-decoration: none;
    background: #ffc920;
}
