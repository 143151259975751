.breedRelatedContent {

    &--list {
        margin: 0;
    }

    &--list-item {
        border-bottom: 1px solid #ccc;
        display: flex;
        gap: 16px;
        width: 100%;
        justify-content: space-between;
        padding: 0 0 16px;
        margin: 0;

        @include xy-gutters($gutter-position: top bottom);
    }

    &--thumb-wrapper {
        width: 100%;

        @include xy-gutters($gutter-position: right);
    }

    &--thumb {
        border-top-left-radius: 16px;
        border-bottom-right-radius: 16px;
    }

    &--link {
        text-decoration: none;
        color: $black;
    }
}

.article-img {
    width: 25%;
    align-self: center;
    margin: auto;

    @include breakpoint (small down) {
        width: 35%;
        padding-top: 20px;
    }
}

.article-content {
    width: 70%;
    text-align: left;

    @include breakpoint (small down) {
        width: 60%;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 7;
        -webkit-box-orient: vertical;
        word-wrap: break-word;
    }
}
