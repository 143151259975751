#decoded-testimonials {

    margin: rem(28) 0;

    .decoded-headline-text,
    .decoded-subheadline-text,
    .testimonial-slide-name,
    .testimonial-slide-dog-name {
        font-family: $font-family;
    }

    .decoded-headline-text,
    .decoded-subheadline-text {

        @include breakpoint(small only) {
            max-width: 235px;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .decoded-headline-text {

        @extend .text-color-primary;
        font-size: rem(36);
        line-height: 1.11;
        letter-spacing: 1.93px;
        margin-bottom: rem(20);

        @include breakpoint(large) {
            font-size: rem(64);
            line-height: 1.17;
            letter-spacing: 3.43px;
            margin-bottom: rem(20);
        }
    }

    .decoded-subheadline-text {
        font-size: rem(18);
        line-height: 1.33;
        letter-spacing: 0.96px;
        margin-bottom: rem(50);

        @include breakpoint(large) {
            font-size: rem(24);
            line-height: 1.62;
            letter-spacing: 1.29px;
            margin-bottom: rem(64);
        }
    }

    .swiper-container {
        width: 100%;

        .swiper-slide {
            text-align: center;
            font-size: rem(18);
            background: $white;
            width: 200px;

            @include breakpoint(large) {
                width: 275px;
            }

            @include breakpoint(xlarge) {
                width: 320px;
            }
        }
    }

    .swiper-wrapper {
        margin: 0 auto;
        width: 200px;

        @include breakpoint(large) {
            width: 85%;
        }
    }

    .testimonial-slide-container {
        margin: 0 auto;
        width: 180px;

        @include breakpoint(large) {
            width: 275px;
        }

        @include breakpoint(xlarge) {
            width: 320px;
        }
    }

    .testimonial-slide-image {
        position: relative;

        img {
            border-radius: 40px 40px 40px 5px;
            width: 100%;

            @include breakpoint(large) {
                border-radius: 100px 100px 100px 15px;
            }
        }
    }

    .testimonial-slide-text {
        text-align: initial;

        @include breakpoint(large) {
            padding-bottom: rem(32);
        }
    }


    .testimonial-slide-name,
    .testimonial-slide-dog-name {
        font-size: rem(14);
        font-weight: 700;
        letter-spacing: 0.75px;
    }

    .testimonial-slide-dog-name {
        position: absolute;
        top: 20px;
        left: 20px;

        @include breakpoint(large) {

            font-size: rem(20);
            top: 25px;
            left: 40px;
        }
    }

    .testimonial-slide-meta {

        @include breakpoint(large) {
            display: flex;
        }

        .testimonial-slide-name {
            margin: rem(16) 0 0;

            @include breakpoint(large) {
                margin: rem(16) 0 rem(4);
                font-size: rem(20);
                line-height: 1;
                letter-spacing: 1.45px;
            }
        }

        .testimonial-slide-stars {

            @include breakpoint(large) {
                margin: rem(16) 0 rem(4) auto;
                min-width: rem(112);
                text-align: right;
            }

            svg {
                width: rem(16);
                margin-right: rem(2);

                @include breakpoint(large) {
                    width: rem(20);
                    height: auto;
                    margin-left: rem(2);
                    margin-right: 0;
                }
            }
        }
    }

    .testimonial-slide-info p {
        font-family: $font-family;
        font-size: rem(12);
        font-weight: 500;
        line-height: 1.17;
        letter-spacing: 0.64px;
        padding-bottom: rem(32);

        @include breakpoint(large) {
            font-size: rem(16);
            font-weight: 400;
            letter-spacing: 1px;
        }
    }

    .swiper-pagination-bullet {

        @include breakpoint(large) {
            margin: 0 rem(8);
            width: rem(12);
            height: rem(12);
        }

        &:focus {
            outline: 2px solid $black;
        }

        &-active {
            background-color: $summerSaleSecondaryColor;
        }
    }

    .swiper-button-prev,
    .swiper-container-rtl .swiper-button-next,
    .swiper-button-next,
    .swiper-container-rtl .swiper-button-prev {
        display: none;

        @include breakpoint(large) {
            display: flex;
            top: 250px;
            background: #f6f6f6;
            border-radius: 50px;
            width: 60px;
            height: 60px;
            color: #979797;
        }
    }

    .swiper-button-prev::after,
    .swiper-container-rtl .swiper-button-next::after,
    .swiper-button-next::after,
    .swiper-container-rtl .swiper-button-prev::after {
        font-size: 25px;
        font-weight: 800;
    }

    .swiper-button-prev {
        left: 42px;
    }

    .swiper-button-next {
        right: 42px;
    }
}
