:root {
    scroll-behavior: smooth;
    scroll-padding-top: 70px;
}

@media (prefers-reduced-motion) {

    :root {
        scroll-behavior: auto;
    }
}

.diva-hero {

    .container {
        justify-content: center;

        > :only-child {
            margin: 1.5em 0;
            max-width: 50em;
            padding: 1em 0;
            text-align: center;
            width: 100%;

            >* {
                margin-left: 0;
                margin-right: 0;
            }

            @media screen and (max-width: 63.9375em) {
                width: 100%;
            }
        }
    }
}

.background {

    &--gradient-radial {

        &--purple {
            background: #8b4dff;
            background: radial-gradient(circle at 0 100%, #8b4dff, #5529a4 100%);
        }
    }

    &--gradient-linear {

        &--purple {
            background: #8b4dff;
            background: linear-gradient(45deg, #8b4dff 0%, #5529a4 50%);
        }
    }

}

.add-default-focus-styles {

    &:focus {

        @include reset-focus-ring-to-browser-default;
    }
}

.text-black {
    color: #0a0a0a;
}

.text-blue {
    color: $blue;
}

.text-yellow {
    color: $yellow;
}

.text-white {
    color: $white;
}

.bg-blue {
    background-color: $blue;
}

.bg-red {
    background-color: #f15b5c;
}

.bg-yellow {
    background-color: $yellow;
}

.bg-app-gray {
    background-color: #f7f7f7;
}

.inline {
    display: inline;
}

.pointer {
    cursor: pointer;
}

.space-below-responsive {
    margin-bottom: 20px;
}


@media print,
    screen and (min-width: 40em) {

    .space-below-responsive {
        margin-bottom: 35px;
    }
}

.space-below-lg-responsive {
    margin-bottom: 20px;
}

@media print,
    screen and (min-width: 64em) {

    .space-below-lg-responsive {
        margin-bottom: 70px;
    }
}


.space-above-lg-responsive {
    margin-top: 30px;
}

@media print,
    screen and (min-width: 40em) {

    .space-above-lg-responsive {
        margin-top: 80px;
    }
}

.module .module-item:nth-child(n+3),
.space-above-md-responsive {
    margin-top: 20px;
}

@media print,
    screen and (min-width: 40em) {

    .module .module-item:nth-child(n+3),
    .space-above-md-responsive {
        margin-top: 40px;
    }
}

.space-above-sm-responsive {
    margin-top: 10px;
}

@media print,
    screen and (min-width: 40em) {

    .space-above-sm-responsive {
        margin-top: 30px;
    }
}

.padding-md-responsive {
    padding: 6px;
}

@media print,
    screen and (min-width: 40em) {

    .padding-md-responsive {
        padding: 20px;
    }
}

.md-caps-responsive {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 800;
    letter-spacing: 1px;
}

@media print,
    screen and (min-width: 40em) {

    .md-caps-responsive {
        font-size: 20px;
    }
}

.space-above {
    margin-top: 30px;
}

.scroll-space-above {

    &--sm {
        scroll-margin-top: 30px;
    }

    &--md {
        scroll-margin-top: 40px;
    }

    &--lg {
        scroll-margin-top: 80px;
    }
}


.text-muted {
    color: #666a70;
}

.font-weight-bold {
    font-weight: 700;
}

@media print,
    screen and (max-width: 20em) {

    /* CSS for smaller devices like iPhone 5 and SE */
    .hide-for-smaller-only {
        display: none !important;
    }
}

/* START Utility Classes */

.round {
    border-radius: 100%;
}

.margin-center {
    margin-left: auto;
    margin-right: auto;
}

.pl-0,
.px-0 {
    //! no usages found for rules below
    padding-left: 0 !important;
}

.pl-1,
.px-1 {
    //! no usages found for rules below
    padding-left: 0.25rem !important;
}

.mb-0,
.my-0 {
    margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
    //! no usages found for rules below
    margin-left: 0 !important;
}

.mt-0,
.my-0 {
    //! no usages found for rules below
    margin-top: 0 !important;
}

.ml-2,
.mx-2 {
    margin-left: 0.5rem !important;
}

.ml-3,
.mx-3 {
    //! no usages found for rules below
    margin-left: 1rem !important;
}

.mt-3,
.my-3 {
    margin-top: 1rem !important;
}

.mt-4,
.my-4 {
    margin-top: 1.5rem !important;
}

.pt-2,
.py-2 {
    //! no usages found for rules below
    padding-top: 0.5rem !important;
}

.mr-3,
.mx-3 {
    //! no usages found for rules below
    margin-right: 1rem !important;
}

.pt-5,
.py-5 {
    //! no usages found for rules below
    padding-top: 3rem !important;
}

.pt-4,
.py-4 {
    //! no usages found for rules below
    padding-top: 1.5rem !important;
}

.pb-1,
.py-1 {
    //! no usages found for rules below
    padding-bottom: 0.25rem !important;
}

.pb-2,
.py-2 {
    //! no usages found for rules below
    padding-bottom: 0.5rem !important;
}

.pl-5,
.px-5 {
    //! no usages found for rules below
    padding-left: 3rem !important;
}

.pr-5,
.px-5 {
    //! no usages found for rules below
    padding-right: 3rem !important;
}

.screen-reader-text {
    display: none;
}

/* END Utility Classes */

.nowrap {
    white-space: nowrap;
}

// this utility is being used in Elementor
.background--white--rounded {
    background: #fff;
    border-radius: 10px;

    @include breakpoint(medium) {
        border-radius: 16px;

        @include xy-gutters($gutter-position: top);
    }

    @include breakpoint(small only) {
        margin: 1rem 0;
    }

    padding: 1rem 2rem;
}

.hidden {
    display: none;
    opacity: 0;
}

section.module {
    padding: 2rem 1.4rem;
}

@media print,
    screen and (min-width: 40em) {

    section.module {
        padding-top: 5rem;
    }
}

$grid-1400-max-width: 1400px;
$grid-1400-margins: 40px;

.grid-container-1400 {
    width: 100%;

    @include xy-grid-container($grid-1400-max-width, $grid-1400-margins);
}

$grid-1100-max-width: 1100px;
$grid-1100-margins: 40px;

.grid-container-1100-1400 {
    width: 100%;

    @include breakpoint(xlarge) {

        @include xy-grid-container($grid-1400-max-width, $grid-1400-margins);
    }

    @include xy-grid-container($grid-1100-max-width, $grid-1100-margins);
}


$grid-1100-f-max-width: 1100px;
$grid-1100-f-margins: 40px;

.grid-container-1100 {
    width: 100%;

    @include xy-grid-container($grid-1100-f-max-width, $grid-1100-f-margins);
}

.rounded-medium {
    border-radius: 8px;
    overflow: hidden;
}

.bolder-link {
    font-weight: 600;
    text-decoration: none;

    &:hover,
    &:focus {
        text-decoration: underline;
    }
}

$backgrounds: ("yellow": $yellow, "soft-yellow": $soft-yellow);

@each $name, $color in $backgrounds {
    .background__#{$name} {
        background-color: $color;
    }
}
