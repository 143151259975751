/*
* Styles for partner page
* Template: temp-breeder-partnership.php
*/

.breeder-partnership > section:not(:first-child) {
    margin-top: 1rem;
}

.breeder-partnership .section-banner {
    color: $white;
    background-color: $black;
}

.breeder-partnership .section-banner .diva-hero--headline,
.breeder-partnership .section-partner-banner h1,
.breeder-partnership .section-did-you-know h2 {
    letter-spacing: normal;
}

.breeder-partnership .section-partner-banner {
    padding: 1rem 1rem 65%;
    background-color: #dce0e1;
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: 60%;
}

.breeder-partnership .section-partner-banner .button-large {
    letter-spacing: 1px;
}

.breeder-partnership .section-partner-banner .button-large,
.breeder-partnership .section-partner-banner .button-medium {
    margin: 1rem 0;
}

.breeder-partnership .section-partner-banner .survey-button:hover {
    cursor: pointer;
}

.breeder-partnership .section-three-column .right-column {
    margin-top: 2rem;
}

#survey-consultation-modal .close-button {
    z-index: 10;
    margin-top: 10px;
    margin-right: 10px;
}

@media screen and (max-width: 40em) {

    /*
  * This forces the image to have a specific height so that
  * Orbit would render the proper height of the .orbit-container on mobile view.
  */
    .breeder-partnership .slider-quotes .orbit-container img.lazyload {
        height: 100px;
    }
}

@media print, screen and (min-width: 40em) {

    .breeder-partnership > section:not(:first-child) {
        margin-top: 4rem;
    }

    .breeder-partnership .section-partner-banner {
        background-position: right bottom;
        background-size: 50%;
        padding: 4rem 0;
    }

    #survey-consultation-modal {
        width: 500px;
    }
}

@media print, screen and (min-width: 64em) {

    .breeder-partnership .section-partner-banner {
        background-position: 95% 0;
        background-size: contain;
    }

    #survey-consultation-modal {
        max-width: 500px;
    }
}

.tools-and-services {

    section.section-partner-banner {
        display: none;
    }

    .slider-quotes,
    .section-partner-banner,
    .section-faq .section-faq .faq-bg,
    .section-faq .section-faq .faq {
        display: none;
    }

    .section-did-you-know {

        h2 {
            margin-top: rem(16);
        }
    }

    .breeder-partnership > section:not(:first-child) {
        margin-top: 0;
    }
}

section.slider-tabbed.grid-container.fluid {
    padding-top: 4rem;
    margin-top: 2rem;
}

/* Small only */
@media screen and (max-width: 39.9375em) {

    section.slider-tabbed.grid-container.fluid {
        padding-top: 1rem;
        margin-top: 0;
    }
}

section.slider-tabbed .slide-body {
    background-color: $white;
    border: solid 1px rgba(0, 0, 0, 0.1);
    padding: 1em;
}

section.slider-tabbed .orbit-bullets button {
    cursor: pointer;
    width: 6rem;
    height: 6rem;
    margin: 0 0 0 1rem;
    border-radius: unset;
    background-color: transparent;
}

section.slider-tabbed .orbit-previous {
    padding-left: 0;
}

section.slider-tabbed .orbit-next {
    padding-right: 0;
}

section.slider-quotes {
    padding-bottom: 1rem;
    padding-top: 1rem;
}

/* The following rules are shared between partnerships and research page */
section.slider-media-object img,
section.slider-quotes img {
    margin-bottom: 2rem;
}


section.slider-media-object img {
    max-width: 200px;
}

.stylized-quote {
    font-size: 1.5rem;
}

.stylized-quote,
section.slider-quotes blockquote {
    color: $black;
    border: none;
    position: relative;
    padding: 0.5em 1em 0.5em 2em;
}

.stylized-quote::before,
section.slider-quotes blockquote::before {
    position: absolute;
    font-size: 4em;
    line-height: 1;
    top: 0;
    left: 0;
    content: "\201C";
}

.stylized-quote::after,
section.slider-quotes blockquote::after {
    position: absolute;
    float: right;
    font-size: 4em;
    line-height: 1;
    right: 0;
    bottom: -0.5em;
    content: "\201D";
}

.stylized-quote footer,
section.slider-quotes blockquote footer {
    padding: 0 2em 0 0;
    text-align: right;
}

.stylized-quote cite,
section.slider-quotes blockquote cite {
    color: $black;
    text-align: left;
    font-weight: bolder;
    margin-top: 1em;
}

.stylized-quote cite {
    text-align: right;
}

.stylized-quote .stylized-quote::before,
section.slider-quotes blockquote cite::before {
    content: none;
}

/* Medium and up */
@media screen and (min-width: 40em) {

    section.slider-tabbed .slide-body {
        padding: 2em;
    }

    section.slider-quotes blockquote {
        padding: 0.5em 2em 0.5em 3em;
    }

    section.slider-quotes {
        padding-bottom: 3rem;
        padding-top: 3rem;
    }

    section.slider-media-object img {
        max-width: 300px;
    }
}

@media screen and (min-width: 64em) {

    section.slider-quotes {
        padding-bottom: 6rem;
    }
}

