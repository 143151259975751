$font-family: "Gilroy", sans-serif;

$dnaMonthPrimaryColor: #ee7362;
$dnaMonthPrimaryDark: #d56f5e;
$dnaMonthSecondary: #fcc300;
$dnaMonthLightLinen: #f8e8e5;
$yellow: #ffce34;
$soft-yellow: #ffe8a5;
$soft-blue: #e3fdff;
$blue: #57c1e9;
$red: #f00;
$light-blue: #bce6f6;
$purple: #8b4dff;
$purple-white: #dedcf3;
$purple-dark: #5529a4;
$teal: #066652;
$light-teal: #0a9f95;
$light-teal--vet: #28a2ad;
$brand-teal: #377f8c;
$text-link-blue: #007db8;
$darkest-blue: #00457b;
$blue-purple: #511cb4;
$white: #fff;
$contrast-white: #f7f7f7;
$black: #000;
$light-black: #0a0a0a;
$dark-gray: #5d5d5d;
$gray: #626262;
$med-light-gray: #f5f5f5;
$light-gray: #f6f6f6;
$very-light-gray: #f0f0f0;
$mid-gray: #ccc;
$treat: #7a5552;
$mojo: #c74447;

$brand-breeder-darkGreen: #00444b;

$spaced_plus_sign: "\00A0\00A0\002B";
$spaced_minus_sign: "\00A0\00A0\2013";
