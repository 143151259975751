.product-card {
    max-width: unset;

    * {
        pointer-events: none;
    }

    &__image {
        aspect-ratio: 4 / 3;
        border-radius: 8px;
        background: var(--card-theme-color, #eee);
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: auto;
            max-height: 80%;
            height: 80%;
            border-radius: 8px;
        }
    }

    @include breakpoint(medium) {
        max-width: unset;
    }

    &__title {
        margin-bottom: 0;
    }


    &__body {
        display: grid;
        grid-template-rows: auto auto auto;
        gap: 8px;
        margin-top: 12px;
    }

    &__meta {
        display: flex;
        gap: 4px;

        .muted {
            color: rgba(0, 0, 0, 0.7);
        }
    }

    &__tags {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        gap: 6px;

        .badge {
            background: rgba(252, 195, 0, 0.3);
            height: 20px;
            padding: 0 6px;
        }
    }
}

.recommended-products {

    .product-card {
        text-decoration: none;

        &__image {

            img {
                width: 80%;
            }
        }

        @include breakpoint(medium) {
            max-width: unset;

            &__image {

                img {
                    width: 320px;
                    max-width: 80%;
                }
            }
        }
    }
}
