.section-faq .faq-bg {
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.section-faq .faq {
    padding: 30px;
    background-color: $light-gray;
}

.section-faq .faq h2,
.section-faq .faq h4 {
    margin-bottom: 20px;
}

.section-faq .question-answer-set h5 {
    font-weight: 700;
}

@media print, screen and (min-width: 40em) {

    .section-faq .faq {
        padding: 50px;
    }
}

.section-faq img,
.section-faq .button-large {
    margin: 2.5rem auto;
}
