/** START Breed Health List ShortCode */

.breed-health-list .button-medium {
    font-size: 12px;
    padding: 0.44rem 0.62rem;
    margin: 0 0 1rem;
}

.breed-health-list .disorder-description {
    display: none;
    font-weight: 400;
}

.breed-health-list .disorder-container {
    flex-grow: 1;
}

.breed-health-list .show-more-button {
    min-width: 6.4rem;
}

/** END Breed Health List ShortCode */
