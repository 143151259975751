/* Section Featured Video */

.custom-block-container.section-featured-video {
    width: 100%; // override foundation cell layout to accommodate iframe video content
}


.sectionFeaturedVideo {
    width: 100%;
}

.covid-19 {

    .section-media-object {

        @include breakpoint(large) {

            .button-large {
                display: inline-block;
                margin: 1rem 0;
                width: initial;
            }
        }
    }

    .announcement-bar {
        display: none;
    }

    .two-columns-text > .medium-10 {
        width: 100% !important;
    }
}
