.section-countdown {

    #timer {
        color: $white;

        ul {
            margin-left: 0;
            transform: translateX(-9px);
        }

        li {
            position: relative;
            display: inline-block;
            list-style-type: none;
            text-align: center;
            font-weight: 700;
            font-size: rem(8px);
            min-width: 50px;
            line-height: rem(21px);

            @include breakpoint(medium up) {
                font-size: rem(11px);
                margin-left: 10px;
                margin-right: 10px;
                line-height: rem(30px);

                &:last-of-type {
                    margin-right: 0;
                }
            }

            &:not(:last-child)::after {
                content: ":";
                font-size: rem(28);
                position: absolute;
                top: 0;
                left: 49px;

                @include breakpoint(medium up) {
                    font-size: rem(39px);
                    left: 55px;
                }
            }
        }
    }

    .timer-value {
        display: block;
        font-size: rem(28);
        min-width: 50px;

        @include breakpoint(medium up) {
            font-size: rem(39px);
            letter-spacing: rem(2px);
        }
    }
}
