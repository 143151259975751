.navReduced {
    margin: 1rem auto;

    &--logo {
        width: 9.75rem;
        margin: 1rem auto;

        @include breakpoint(medium) {
            margin: 1rem;
        }
    }

    @include breakpoint(medium) {

        @include xy-gutters(1.25em);
    }

    @include breakpoint(large) {

        @include xy-gutters(4em);
    }
}
