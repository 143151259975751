@mixin position-styles($prefix, $property, $values, $breakpoints) {
    // Desktop styles (global, without media query)
    @each $key, $value in $values {
        .#{$prefix}-#{$key} .swiper-slide-inner {
            #{$property}: $value;
        }
    }

    // Styles for other devices (tablet and mobile)
    @each $device, $max-width in $breakpoints {

        @media only screen and (max-width: $max-width) {

            @each $key, $value in $values {
                .#{$prefix}-#{$device}-#{$key} .swiper-slide-inner {
                    #{$property}: $value;
                }
            }
        }
    }
}

// Define position values for horizontal and vertical alignment
$horizontal-values: (
    left: flex-start,
    center: center,
    right: flex-end
);

$vertical-values: (
    top: flex-start,
    middle: center,
    bottom: flex-end
);

// Define breakpoints
$breakpoints: (
    mobile: 639px,
    tablet: 1024px
);

// Generate styles for horizontal and vertical positions
@include position-styles("slider-h-position-", "justify-content", $horizontal-values, $breakpoints);
@include position-styles("slider-v-position-", "align-items", $vertical-values, $breakpoints);

.elementor-rich-slide-heading {
    font-family: Recoleta, sans-serif;
}

.elementor-rich-slide-description {

    &,
    p {

        @include h(5);
    }
}

.elementor-widget-rich_slides.elementor-element .swiper .elementor-swiper-button {
    width: 44px;
    border: 1px solid;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.6);
    color: #000;
}
