.page-health-condition-individual {

    .accordion-title::before {
        content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEyIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMC41OSAwLjU4OTk2Nkw2IDUuMTY5OTdMMS40MSAwLjU4OTk2NkwwIDEuOTk5OTdMNiA3Ljk5OTk3TDEyIDEuOTk5OTdMMTAuNTkgMC41ODk5NjZaIiBmaWxsPSIjMDcwMDAwIi8+Cjwvc3ZnPgo=) !important;
        font-weight: 300;
        transition: all 0.5s ease;
        position: absolute;
        top: 60%;
        right: 1rem;
        margin-top: -2.5rem !important;
    }

    .lists.accordion .is-active .accordion-title::before {
        transform: rotate(-180deg) !important;
        margin-top: -1.2rem !important;
    }

    cite {
        color: initial;
        display: inline;
        font-size: initial;
        font-style: normal;

        &::before {
            content: "";
            display: none;
        }
    }

    .section {
        padding: 1.125em 0.75em 1.5em;
    }

    .grid-container {

        .grid-y {
            align-items: center;
            justify-content: center;
        }
    }

    .genetic_info_container {
        display: flex;
        gap: 10px;
        align-items: flex-end;
    }

    li.action_item {
        border-bottom: none !important;
    }

    a.breed_link {
        text-decoration: none;
    }

    ul.affected_breed_list {
        margin-left: 0;
    }

    li.affected_breed_item {
        list-style: none;
        border-bottom: none !important;

        a.affected_breed_container {
            display: flex;
            height: 130px;
            align-items: center;
            border-radius: 8px;
            background-color: #f7f7f7;
            margin-bottom: 16px;
            text-decoration: none;
        }

        a.affected_breed_container:hover {
            text-decoration: underline;
        }

        h3 {
            margin-left: 16px;
            width: 50%;
            margin-bottom: 0;
        }

        .affected_breed_image {
            width: 30%;
            margin: auto;
            text-align: right;
        }

        .arrow_container {
            width: 15%;
            text-align: right;
            margin-right: 16px;
        }
    }

    .health-header,
    .top-page-banner {
        align-items: center;
        background: $white;
        color: $black;
        padding: 1em 0.625em;

        .badge {
            margin: 24px 0;
            background-color: #fcc3004d;
        }

        .health-image {
            order: 2;

            .image-bubble {
                background: #fff;
                border-radius: 50em;
                box-shadow: 0 0.125em 1.5em rgba(0, 125, 184, 0.12);
                min-height: 2em;
                min-width: 2em;
            }

            .system-description {
                font-weight: 600;
                font-size: 0.75em;
                margin: 1em 0 1em 0.5em;

                .system-name {
                    text-transform: lowercase;
                }

            }
        }

        .health-title-excerpt {
            margin: 1em 0;
            order: 1;

            .h1 {
                font-size: 2.25em;
                overflow-wrap: break-word;
                word-break: break-word;

                &.long-input {
                    font-size: 1.5em;
                }
            }

            p {
                margin-bottom: 0;
            }
        }

        .nav-back {
            color: $black;
            text-align: left;
            text-decoration: none;
            font-weight: 600;
        }

        .nav-back:hover {
            text-decoration: underline;
        }

    }

    .description-block + .description-block {
        margin-top: 2em;
    }

    .raised {
        background: #fff;
        border-radius: 0.25em;
        box-shadow: 0 0.125em 1.5em rgba(0, 0, 0, 0.1);
        padding: 1.25em 1.25em 1.5em;
    }

    .more-information {

        &:last-child {
            margin-bottom: 0;
        }
    }

    .articles-health {
        max-width: 900px;
        margin: auto;
        padding-top: 50px;
        text-align: center;

        @include breakpoint (small only) {
            padding: 30px 50px 0;
        }

        @include breakpoint (medium down) {
            padding: 30px 40px 0;
        }
    }

    .articles-header {
        border-bottom: 1px solid #ccc;
        padding-bottom: 16px;
    }


    .health-conclusion-block {
        max-width: 1150px;
        margin: auto;
        padding-top: 70px;

        @media (min-width: 40em) and (max-width: 63.9375em) {
            padding-left: 24px;
            padding-right: 24px;
        }

        @include breakpoint (small only) {
            padding-top: 50px;
        }
    }

    @media (min-width: 40em) {

        .section {
            padding: 2em 0;
        }

        .health-header,
        .top-page-banner {

            .health-image {
                max-width: 41em;
                padding: 0 2em 1em;

                .system-description {
                    font-size: 1em;
                }
            }

            .health-title-excerpt {
                max-width: 600px;
                padding: 1em 2em 1em;

                .h1 {
                    font-size: 2.5em;

                    &.long-input {
                        font-size: 2em;
                    }
                }
            }
        }

        .health-content {
            padding: 0 3em 1em;
        }

        .raised {
            padding: 3em;
        }
    }

    @media (min-width: 40em) and (max-width: 63.9375em) {

        .health-about {
            max-width: 600px;
        }
    }

    @media print, screen and (min-width: 64em) {

        .health-header,
        .top-page-banner {

            .health-image {
                align-self: flex-start;
                order: 1;
                padding: 1em 1em 0 0;

                .system-description {
                    font-size: 1em;
                    margin: 1em;
                    text-align: center;
                }
            }

            .health-title-excerpt {
                order: 2;
                padding: 1em 0 0 1.25em;

                .h1 {
                    font-size: 3.125em;

                    &.long-input {
                        font-size: 2.4375em;
                    }
                }
            }
        }

        .health-content {
            order: 2;
            max-width: 600px;
            padding: 0;
        }

        .raised {
            order: 1;
        }
    }
}
