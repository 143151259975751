/* START Relative Finder block */

.block-relative-finder.has-multiple-backgrounds {
    background-position: 0 top, 0 bottom;
    background-repeat: repeat-x, repeat-x;
    background-size: 100%, 100%;
}

.block-relative-finder {
    padding: 4rem 0;
}

.block-relative-finder iframe {
    width: 100%;
}

@media only screen and (max-width: 40em) {

    iframe {
        height: 100%;
    }

    .block-relative-finder.has-multiple-backgrounds {
        background-position: center top, center bottom;
        background-size: 335%; /* ಠ_ಠ */
    }

}

/* END Relative Finder block */
