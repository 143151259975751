/*
* Styles for product comparison page
* Template: temp-product-comparison.php
*/

.section-comparison-table h5,
.section-comparison-table h6,
.product-comparison .section-comparison-footer h2 {
    font-weight: 700;
}

.section-comparison-table p {
    color: #666a70;
}

.section-comparison-table .price-desc {
    margin-top: 1rem;
    width: 100%;
    display: block;
}

.section-comparison-table h6 {
    width: 80%;
    display: inline-block;
    color: #666a70;
}

.product-comparison .section-comparison-table table thead {
    background: transparent;
    border: none;
}

.product-comparison .section-comparison-table table tbody {
    border: none;
}

.product-comparison .section-comparison-table table td {
    padding: 2rem;
}

.product-comparison .section-comparison-table table tbody tr:nth-child(odd) {
    background-color: #f7f7f7;
}

.product-comparison .section-comparison-table table tbody tr:nth-child(even) {
    background-color: $white;
}

.product-comparison .section-comparison-table .kit-type-text {
    display: none;
}

.product-comparison .section-comparison-table,
.product-comparison .section-comparison-footer {
    margin-top: 4rem;
}

.product-comparison .section-comparison-table .mobile-hidden {
    display: none;
}

.product-comparison .section-comparison-footer h6 {
    text-transform: uppercase;
    margin-top: 1rem;
}

.product-comparison .section-comparison-footer h2.product-price {
    margin-bottom: 0;
}

.product-comparison .section-comparison-footer sub {
    display: block;
    line-height: 1.2;
    font-size: 1rem;
}

.product-comparison .section-comparison-footer .product-background {
    background-repeat: no-repeat;
    background-size: 50%;
    background-position: left;
    padding: 4rem 1rem;
}

/* Start CSS for Compare Two Products Page*/

.section-comparison-table {
    padding-right: 0;
    padding-left: 0;
}

.product-comparison .check-mark > .compare-feature-us::before,
.product-comparison .check-mark > .compare-feature-them::before {
    display: block;
    transform: scale(1.2);
    padding-right: 0;
    pointer-events: none;
}

.product-comparison .recommended-badge {
    max-width: 220px;
    min-height: 47px;
    margin: 1.5rem auto auto;
}

.chevron {
    transition: transform 0.4s ease;
    display: inline-block;
    font-size: 1.25rem;
    margin-top: 4px;
}

.chev-right {
    transform: rotate(0);
}

.chev-down {
    transform: rotate(90deg);
}

.feature-row {
    background-color: $white !important;
    box-sizing: border-box;
}

.feature-row > div:first-child {
    padding: 1rem 0 0 2rem;
}

.feature-row:nth-child(even) {
    background-color: #f7f7f7 !important;
}

.product-comparison .comparison-footer {
    margin: 1rem;
}

.common-features {
    margin-top: 6rem;
    background-color: #f7f7f7;
    padding: 2rem;
}

.common-features h3 {
    margin-bottom: 0;
}

/* End CSS for Compare Two Products Page*/

@media (max-width: 63.94em) {

    .product-comparison .section-comparison-table,
    .product-comparison .section-comparison-footer {
        margin-top: 2rem;
    }

    .product-comparison .section-comparison-table .kit-type-text {
        display: block;
        font-weight: 700;
        text-transform: uppercase;
        color: $black;
        width: 100%;
    }

    .product-comparison .section-comparison-table table td {
        padding: 1rem;
    }

    .product-comparison .section-comparison-table .mobile-hidden {
        display: inline-block;
    }

    .product-comparison .section-comparison-footer .product-background {
        background-image: none;
        background-size: 0;
    }

    .product-comparison .section-comparison-footer .product-detail {
        text-align: center;
    }
}

@media screen and (max-width: 40em) {

    .compare-feature-name {
        width: 50%;
    }

    .product-comparison .product-name h4 {
        font-size: 1rem;
    }

    .product-comparison .comparison-header > div,
    .product-comparison .comparison-footer > div {
        padding: 6px;
    }

    .product-comparison .comparison-header .button-large,
    .product-comparison .comparison-footer .button-large {
        width: 100%;
        padding: 15px 4px;
        font-size: 1rem;
    }

    .product-comparison .recommended-badge {
        min-width: 136px;
    }

    .product-comparison .check-mark > .compare-feature-us::before,
    .product-comparison .check-mark > .compare-feature-them::before {
        transform: scale(0.8);
    }

    .feature-row {
        padding: 0;
    }

    .feature-row > div {
        padding: 1rem 1rem;
        flex: 1;
    }

    .feature-row > div:first-child {
        padding: 1rem 0 1rem 0.5rem;
    }


    .feature-row .feature-name {
        font-size: 1rem;
        margin-bottom: 0;
    }

    .common-features {
        margin-top: 1rem;
        background-color: #f7f7f7;
        padding: 1rem;
    }

    .common-features-heading {
        margin-bottom: 1rem;
    }

    .common-features-feature {
        margin-bottom: 0.75rem;
    }

}

/**
* iPhone 5/SE
 */
@media only screen and (max-width: 22em) {

    .product-comparison .recommended-badge {
        min-width: 110px;
    }

    .product-comparison .product-name h4,
    .product-comparison .feature-row h4 {
        font-size: 0.9rem;
    }

    .chevron {
        font-size: 1rem;
    }
}
