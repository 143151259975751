$chart_boarder_color: #eeeeef;
$divider_color: #979797;

.compare-products-widget {

    .expand-all,
    .collapse-all {
        margin-left: rem(10px);
        background: none;

        @extend .link--dotted--green;

        @include breakpoint(medium) {

            margin-left: rem(20px);
            font-size: rem(14px);
        }

    }

    .left-image {
        transform: translate(10px);
    }

    .right-image {
        transform: translate(-5px);
    }

    @include breakpoint(medium only) {

        .left-image {
            transform: translate(20px);
        }
    }

    @include breakpoint(large) {

        .left-image {
            transform: translate(28px);
        }

        .right-image {
            transform: translate(-1vw);
        }
    }

    .stackup-chart {

        margin-top: 2rem;

        .content-divider {
            color: $divider_color;
            margin: rem(12px) auto rem(15px) auto;

            @include breakpoint(medium) {
                margin: rem(15px) auto rem(20px) auto;
            }
        }

        .included-icon,
        .not-included-icon {
            padding-left: 1rem;

            @include breakpoint(small only) {

                // This is necessary because some of the
                // accordion titles are long on mobile.
                padding-top: rem(20px);
            }

            @include breakpoint(medium up) {
                padding-left: 0.3rem;
            }

            + .included-icon,
            + .not-included-icon {

                @include breakpoint(small) {
                    padding-left: 30px;
                }

                @include breakpoint(medium up) {
                    padding-left: 70px;
                }

                @include breakpoint(large) {
                    padding-left: 6vw;
                }
            }
        }

        &-header {

            .product-title {

                @extend .headline5;
                text-align: center;
                margin-top: 1rem;
                transform: translate(10px);
            }

            &-title {
                margin-bottom: rem(50px);
                padding-left: rem(10px);

                @include breakpoint(medium) {

                    padding-left: rem(20px);
                }

                @include breakpoint(large) {

                    margin-bottom: rem(116px);
                }
            }
        }

        .accordion {
            margin: 1rem 0 0;

            .right-pointing-angle-quote {

                letter-spacing: initial;

                &::after {
                    padding-left: 0.2rem;
                    content: "›";
                }
            }

            .accordion-title,
            .accordion-content {
                padding: rem(10px);

                @include breakpoint(medium) {
                    padding: rem(18px) rem(20px);
                }

                h1,
                h2,
                h3,
                h4,
                h5 {
                    margin-bottom: 0.5rem;
                }
            }

            .toggle-symbol {

                font-family: inherit;

                &::after {
                    padding-left: 0.375rem;
                    content: url("data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjkiIHdpZHRoPSIxMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxwYXRoCiAgICAgICAgZD0iTTEgMWw1IDUgNS01IgogICAgICAgIGZpbGw9Im5vbmUiCiAgICAgICAgc3Ryb2tlPSIjMDAwIgogICAgICAgIHN0cm9rZS13aWR0aD0iMyI+CiAgICA8L3BhdGg+Cjwvc3ZnPgo=");
                }
            }

            .is-active {

                .toggle-symbol {

                    &::after {
                        content: url("data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjkiIHdpZHRoPSIxMyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxwYXRoCiAgICAgICAgZD0iTTIgOGw1LTUgNSA1IgogICAgICAgIGZpbGw9Im5vbmUiCiAgICAgICAgc3Ryb2tlPSIjMDAwIgogICAgICAgIHN0cm9rZS13aWR0aD0iMyI+CiAgICA8L3BhdGg+Cjwvc3ZnPgo=");
                    }
                }

                .accordion-content {
                    padding-top: 0;
                }
            }

            .accordion-title-text {
                color: $black;
                font-weight: 600;
                position: relative;
                padding-right: 2rem;
                margin-bottom: 0;
            }

            .accordion-content-title-text {
                font-weight: 600;
                margin-bottom: rem(8px);
            }

            .accordion-title {

                border-color: $chart_boarder_color;

                &:focus,
                &:hover {
                    background-color: unset;
                }

                &::before {
                    content: "";
                }
            }

            button.more-info-link {
                font-size: rem(14px);
                line-height: rem(20px);
                margin-top: rem(3px);
                background: none;

                @extend .link--dotted--green;

                @include breakpoint(medium) {
                    margin-top: rem(8px);
                }
            }

            .accordion-sub-title-text {
                font-size: rem(14px);
                line-height: rem(20px);
                color: $black;
                margin-bottom: 0;
            }

            .accordion-item {

                &:nth-child(odd) {
                    background-color: #f5f5f5;
                }
            }

            .accordion-content {
                background-color: unset;
                border-color: $chart_boarder_color;
                border-top: none;

                iframe {
                    width: 100%;
                    height: 178px;
                    max-width: 100%;
                    margin-left: auto;
                    margin-right: auto;

                    @include breakpoint(medium) {
                        width: 400px;
                        height: 203px;
                    }
                }
            }
        }
    }
}
