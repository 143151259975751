
.selfIDModal {
    border: none;
    padding: 0;
    border-radius: 8px;
    box-shadow: 0 2px 32px 0 $black;

    @include breakpoint(small only) {
        min-height: auto;
        height: auto;
        width: grid-column(11);
        margin: auto;
    }

    .close-button {
        top: 2rem;
        right: 2rem;
    }

    &-icon {

        /* Using position absolute to match the
        * styles and alignment with Reveal close button.
        **/
        position: absolute;
        top: 2rem;
        left: 2rem;
        line-height: 1;

        &-image {
            max-width: 70px;
        }
    }

    .question {
        display: none;

        &-text {

            @include xy-gutters($gutter-position: top, $gutters: 10rem);
        }

        &-header {
            background: $light-blue;
            padding: 2rem 2rem 1rem;
        }


        &-active {
            display: block;
        }

        .question-options {

            @include xy-gutters(
                $gutter-type: padding,
                $gutter-position: top right bottom left,
                $gutters: 4rem
            );
        }

        .question-option {

            &-button {
                font-size: rem(20px);
                font-weight: 700;
                color: $teal;
                width: 100%;
                position: relative;
                text-align: left;

                @include xy-gutters(
                    $gutter-type: padding,
                    $gutter-position: top bottom,
                    $gutters: 1.4rem
                );


                &::after {
                    content: "›";
                    position: absolute;
                    right: 0;
                    font-size: rem(30px);
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }
    }

}
