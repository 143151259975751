.elementor-button.button-primary {

    &--link {
        background-color: transparent;
        color: $light-teal--vet;
        fill: $light-teal--vet;
        text-align: left;
        padding: 0;
        font-family: $font-family;
        font-size: rem(20px);
        letter-spacing: rem(0.46px);
        line-height: rem(32px);
        font-weight: 600;

        .elementor-button-text {
            display: inline;
        }

        .h1 {
            font-size: rem(40px);
            line-height: 1.1;
            letter-spacing: -0.4px;
        }

        .h2 {
            font-size: rem(34px);
            line-height: 1.2;
            letter-spacing: 0;
        }

        .h3 {
            font-size: rem(28px);
            line-height: 1.22;
            letter-spacing: 0;
        }

        .h4 {
            font-size: rem(24px);
            line-height: 1.25;
            letter-spacing: 0.15px;
        }

        .h5 {
            font-size: rem(20px);
            line-height: 1.25;
            letter-spacing: 0.15px;
        }

        .h6 {
            font-size: rem(16px);
            line-height: 1.5;
            letter-spacing: 0.15px;
        }

        &:hover,
        &:active,
        &:visited,
        &:focus {
            color: $light-teal--vet;
            text-decoration: underline;
        }
    }
}

.refresh-button {

    @include no-text-decoration;
    border-radius: 24px;
    padding: 4px 16px;
    font-family: Gilroy, sans-serif;
    font-weight: 700;
    transition: 0.3s ease-in-out;
    text-align: center;
    line-height: unset;
    min-height: 44px;
    display: inline-flex;
    align-items: center;
    text-decoration: inherit;

    &:hover:not(:disabled):not([disabled]),
    &:focus:not(:disabled):not([disabled]) {
        transform: translateY(-0.25rem);
        text-decoration: inherit;
    }

    &[disabled],
    &:disabled {
        background-color: #e0e0e0;
        border: 2px solid #626262;
        color: #626262;
        cursor: not-allowed;
    }

    &-hero {

        min-height: 60px;
        padding: 12px 26px;
        border-radius: 32px;
        font-size: rem(20px);
        line-height: 1.5;
        letter-spacing: 0.15px;
    }

}

.button-primary--small {
    background-color: #ffce34;
    color: #000;
    display: inline-flex;
    gap: 5px;
    min-height: unset;
    padding: 10px 16px;
    font-size: 14px;
    font-weight: 600;
}

@mixin refresh-button-primary {

    @extend .refresh-button;
    color: $white;
    background-color: $black;

    &:hover,
    &:focus {

        color: $white;
        background-color: $black;
    }

}

@mixin refresh-button-primary-light {

    @extend .refresh-button;
    color: $black;
    background-color: $white;

    &:hover,
    &:focus {

        color: $black;
        background-color: $white;
    }

}

@mixin refresh-button-primary--shop {

    @extend .refresh-button;
    color: $black;
    background-color: $yellow;

    &:hover,
    &:focus {

        color: $black;
        background-color: $yellow;
    }

}

@mixin refresh-button-primary--vet {

    @extend .refresh-button;
    color: $white;
    background-color: $light-teal--vet;

    &:hover,
    &:focus {

        color: $white;
        background-color: $light-teal--vet;
    }

}

@mixin refresh-button-secondary {

    @extend .refresh-button;
    color: $black;
    background-color: $white;
    border: 2px solid $black;
    padding: 8px rem(14);

    &:hover,
    &:focus {

        color: $black;
        background-color: $white;
    }

}

@mixin refresh-button-secondary-alt {

    @extend .refresh-button;
    color: $white;
    background-color: transparent;
    border: 2px solid $white;
    padding: 8px rem(14);

    &:hover,
    &:focus {

        color: $white;
        background-color: transparent;
    }

}

@mixin refresh-text-link {

    @extend .link--dotted--green;
}

a.helper-link {
    color: unset;
    text-decoration: none;

    &:hover {
        color: unset;
    }
}

a,
button {
    cursor: pointer;
}
/* stylelint-disable color-named, font-weight-notation */

.button.black-border {
    border: 1px solid #0a0a0a;
    border-radius: 2px;
}

.button {

    &-primary {

        @include refresh-button-primary;

        &-light {

            @include refresh-button-primary-light;
        }

        &--shop {

            @include refresh-button-primary--shop;
        }

        &--vet {

            @include refresh-button-primary--vet;
        }
    }

    &-secondary {

        @include refresh-button-secondary;

        &-alt {

            @include refresh-button-secondary-alt;
        }
    }

    &-treat {

        @include refresh-button-primary;
    }

    .breeder-section &-primary {

        @include refresh-button-primary;
    }

    &--link {
        background-color: transparent;
        color: inherit;
        fill: currentColor;
        text-align: left;
        padding: 0;
        font-weight: 600;
        text-decoration: none;

        .elementor-button-text {
            display: inline;
        }

        &:hover,
        &:active,
        &:visited,
        &:focus {
            background: none;
            color: inherit;
            text-decoration: underline;
        }
    }

    &--icon-left,
    &--icon-right {
        display: inline-flex;
        gap: 6px;
        align-items: center;
    }
}

.button.small {
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 1px;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 2px;
}

.button.medium {
    font-weight: bold;
    font-size: 18px;
    letter-spacing: 2px;
}

.button.shadow {
    background-color: #ffcd34;
    box-shadow: -3px 3px 0 0 rgba(198, 198, 198, 0.21);
}

/*-------------------NEW STYLING---------------------*/

a:hover,
a:focus {
    color: initial;
    text-decoration: underline;
}

.button-small,
.button-small-rounded {
    font-size: 12px;
}

/*#endregion FoundationMediaQueries*/
.button-small {
    padding: 7px 10px 7px 10px;
    border-radius: 2px;
    display: block;
}

.button-small-rounded {
    padding: 7px 10px 7px 10px;
    border-radius: 13px;
}

.slick-arrow {
    border: 1px solid $white;
    font-family: "Maison Neue", sans-serif;
    font-weight: bolder;
    border-radius: 2px;
    font-size: 16px;
    color: $white;
    padding: 10px 30px;
    text-transform: uppercase;
}

.breeder-section .button-medium,
.breeder-section .button-large {

    @include refresh-button-primary;
}

.button-medium,
.button-large {

    @include refresh-button-primary;

    &.button-blue,
    &.button-primary-light {

        @include refresh-button-secondary;
    }

    &.button-primary--shop {

        @include refresh-button-primary--shop;
    }

    &.button-primary--vet {

        @include refresh-button-primary--vet;
    }
}

.button-yellow {

    @include refresh-button-primary;
}

.button-blue {

    @include refresh-button-secondary;
}

.nav-links .nav-previous a,
.nav-links .nav-next a {

    display: inline-block;

    @include refresh-button-primary;
}

.button-wide {
    width: 90%;
    max-width: 600px;
    display: block;
}

.button-full-width {
    width: 100%;
    justify-content: center;
}
/* stylelint-enable */

.sidebar .widget .svg-rss {

    @include breakpoint(medium) {
        width: 16px;
        height: 16px;
    }
}

[role="button"] {
    cursor: pointer;
}
