.nowrap {
    white-space: nowrap;
}

.about-summary {
    max-width: 600px;
    padding-bottom: 45px;
}

.lists.accordion .accordion-title {
    padding: 1rem !important;
}

.breed-conclusion-block {
    max-width: 1150px;
    margin: auto;
    padding-top: 30px;
    width: 100%;

    @include breakpoint (small only) {
        padding-top: 15px;
    }
}

.page-breed-individual {

    .credits {
        font-size: 0.875rem;
        font-weight: 500;
        font-style: italic;
    }

    .breed-header,
    .top-page-banner {
        align-items: center;
        background: $white;
        padding: 0;

        .h1 {
            font-size: 1.75rem;
        }

        .grid-container {
            margin: 0 auto;
            max-width: 600px;
        }

        .image-container {
            background-color: #fcc3004d;
            border-radius: 24px;
            margin-bottom: 24px;
            padding: 15px;
            width: 100%;
        }

        .image-bubble {
            display: block;
            height: auto;
            text-align: center;
            width: 100%;
        }

        .breed-title-excerpt {
            margin: 1em auto 0;
            padding: 0 !important;

            .breed-description-excerpt {
                font-size: 1em;
                font-weight: 500;
                margin-bottom: 0;
            }
        }

        .back-nav-container {
            margin: 24px 0;
        }

        .back-nav-breeds {
            color: $black;
            font-weight: 600;
            text-decoration: none;
        }

        a.back-nav-breeds:hover {
            text-decoration: underline;
        }
    }

    .singleBreed-signupForm {
        padding: 0;
        margin: 0.75rem 1.25rem;
        border-radius: 5px;

        @include breakpoint(medium) {
            margin: 0.5rem 0 0;
            border-radius: 0;

        }

        .singleBreed-signupForm-container {

            @extend .grid-container;
        }

        form {
            padding: 1.5rem;

            p:first-child {
                margin-bottom: 0.5rem !important;

            }

            [class*="Row__FormRow"] {
                display: block;

                @include breakpoint(medium) {
                    display: flex;

                }
            }

            [class*="FormComponent__StyledFormComponentWrapper"] {
                padding: 0.25em 0 !important;

                @include breakpoint(medium) {
                    padding: 0.25rem 0.25rem !important;

                }

                &:nth-child(1) {
                    flex-basis: 15%;

                }

                > div {

                    align-self: center;
                }

                > button {

                    font-weight: 400 !important;
                    align-self: center !important;
                }

            }

            [class*="Button__FormStyledButton"] {
                width: 100%;
            }

            @include breakpoint(medium) {
                padding: 1rem 2.5rem;

            }

        }

    }

    .raised {
        background: $white;
        border-radius: 0.25em;
        padding: 1.25em 0 1.5em;

        p {
            margin-bottom: 0;
        }

        dt,
        dd {
            display: inline-block;
        }

        dt {
            font-weight: 400;
        }
    }

    .breed-fun-fact {
        margin: 1em 0 0;
        padding-bottom: 0;
    }

    .data-summary {
        background: #bce6f6;

        ol,
        ul {

            li {
                margin-bottom: 0.5em;
                margin-right: 1em;
            }
        }

        .breed-names {
            order: 1;

            .h2 {
                margin-bottom: 1.5em;
            }

            ul {
                margin-left: 0;
                list-style-type: none;

                li {
                    margin-bottom: 5vw;

                    a {
                        font-weight: 600;

                        &::after {
                            content: "\203A";
                            display: inline;
                        }
                    }

                    p {
                        color: $black;
                        font-weight: 600;
                    }
                }
            }

            &.has-only-child {
                padding: 0 1em 2em 0;

                a {
                    color: #007db8;
                    font-weight: 600;

                    &::after {
                        content: "\203A";
                        display: inline;
                        margin-left: 0.25em;
                    }
                }

                .h2 {
                    margin-bottom: 0.5em;
                }
            }
        }

        .breed-images {
            margin: 5em 0 3em;
            order: 2;

            &.has-only-child {
                margin: 2em 0 2em;
            }

            :not(.has-only-child) .cell.auto {

                @include breakpoint(small only) {
                    flex: 0 0 auto;
                }
            }

            .related-breed-bubble {
                background: $white;
                border-radius: 50em;
                margin-bottom: 0.1em;
                overflow: hidden;
                padding: 7%;
                text-align: center;

                &:nth-child(odd) {
                    margin-bottom: 2.5em;
                }

                &:nth-child(even) {
                    margin-top: 2.5em;
                }

                @include breakpoint(medium only) {

                    height: max-content;
                }

            }

            &.has-only-child .related-breed-bubble {
                align-self: flex-start;
                padding: 16%;
            }
        }

    }

    .on-embark {
        align-items: center;

        .on-embark-cta {
            padding: 0 0.75em;
        }

        .embark-dog img {
            border-radius: 999em;
            max-width: 12em;
            margin-bottom: -1em;
            padding: 1.5em;
            width: 100%;
        }
    }

    .orbit-wrapper {
        overflow: hidden;
    }

    .orbit-previous,
    .orbit-next {
        color: $black !important;

        &:hover {
            color: #666a70;
            background-color: transparent;
        }
    }

    .adjacent-pages-nav {
        align-items: center;
        background-color: #666a70;
        display: flex;
        padding: 1.5em 0;

        a {
            color: $white;

            &:hover {
                color: $white;
            }
        }

        .headline {
            font-weight: 600;
            margin-bottom: 1.5em;
            text-align: center;
        }

        .previous-page {
            align-items: center;
            display: flex;
            justify-content: flex-start;

            &::before {
                color: $white;
                font-size: 200%;
                content: "\2039";
                margin: 0 0.25em 0 0;
            }
        }

        .next-page {
            align-items: center;
            display: flex;
            text-align: right;
            justify-content: flex-end;

            &::after {
                color: $white;
                font-size: 200%;
                content: "\203A";
                margin: 0 0 0 0.25em;
            }
        }

    }

    .articles-breed {
        padding-top: 46px;
        max-width: 900px;
        margin: auto;
    }

    .articles-header {
        border-bottom: 1px solid #ccc;
        padding-bottom: 16px;
    }

    @media (max-width: 39.9375em) {

        .adjacent-pages-nav.end-page .cell:last-of-type {
            justify-content: center;
            width: 100%;
        }
    }

    @media (min-width: 40em) {

        section {
            padding: 2em 0;
        }

        .grid-container {
            padding: 0;
        }

        .breed-header,
        .top-page-banner {

            .breed-title-excerpt {
                margin-top: 0;
            }

            .h1 {
                font-size: 2.75em;
            }

            .breed-description-excerpt {
                font-size: 1em;
            }
        }

        .breed-fun-fact {
            margin: 0;

            p {
                margin-bottom: 0;
            }
        }

        .raised {
            margin-bottom: 0 !important;
            padding-bottom: 0;
        }

        .data-summary {
            background: #bce6f6;
            padding: 2em 3em;

            .breed-common-locations.two-col ol {
                column-count: 2;
            }

            .breed-names {
                order: 2;
                padding-right: 1em;

                .h2 {
                    margin-bottom: 1em;
                }

                ul {
                    margin-left: 0;
                    list-style-type: none;

                    li {
                        margin-bottom: 0.5em;
                    }
                }
            }

            .breed-images {
                margin-top: 0;
                order: 1;
                padding: 0 3em 1em 0;

                .related-breed-bubble {
                    background: $white;
                    border-radius: 50em;
                    overflow: hidden;

                    &:nth-child(odd) {
                        margin-bottom: 1em;
                    }

                    &:nth-child(even) {
                        margin-top: 0;
                        margin-bottom: 1em;
                    }
                }

                &.has-only-child {
                    padding: 0 1em 1em 0;

                    .related-breed-bubble,
                    .related-breed-bubble:nth-child(odd) {
                        margin-bottom: 3em;
                    }
                }

                &.has-four-children {

                    .related-breed-bubble {
                        margin-right: 1em;
                        width: calc(50% - 1em);
                    }
                }
            }
        }

        .on-embark {

            .on-embark-cta {
                padding: 0.5em 4em 2em;
            }
        }

        .adjacent-pages-nav {
            padding: 1em 1em;

            a {
                color: $white;

                &:hover {
                    color: $white;
                }
            }

            .headline {
                align-items: center;
                font-weight: 600;
                margin-top: 0.75em;
                margin-bottom: 0;
                order: 2;
                text-align: center;
                vertical-align: middle;
            }

            .previous-page {
                order: 1;

                &::before {
                    color: $white;
                    content: "\2039";
                    display: inline;
                }
            }

            .next-page {
                order: 3;
                text-align: right;

                &::after {
                    color: $white;
                    content: "\203A";
                    display: inline;
                }
            }
        }
    }

    @media print, screen and (min-width: 64em) {

        .breed-header,
        .top-page-banner {

            .h1 {
                font-size: 3.125rem;
            }

            .breed-title-excerpt .breed-description-excerpt {
                font-size: 1.25em;
            }
        }

        .raised {
            margin-right: 3em;

            .breed-weight {
                margin-top: 0;
            }

            .breed-fun-fact {
                margin-top: 2em;
            }
        }

        .about-breed {
            padding: 0;
        }

        .about-summary {
            min-height: 14em;
        }

        .data-summary {
            padding: 3em 2em 2em;

            .no-relatives {
                margin-bottom: 1em;
            }

            .breed-images {
                align-items: center;
                align-self: flex-start;
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                grid-template-rows: repeat(4, 1fr);
                grid-column-gap: 0;
                grid-row-gap: 0;
                justify-content: center;
                margin: 0;

                .related-breed-bubble {
                    box-shadow: 0 0.125em 1.5em rgba(0, 125, 184, 0.12);
                    padding: 15%;
                    width: 100%;

                    &:nth-child(odd),
                    &:nth-child(even) {
                        margin-bottom: 0.5em;
                    }

                    &:nth-child(even) {
                        margin-top: 0;
                    }
                }

                #related-breed-01 {
                    grid-area: 1 / 2 / 3 / 4;
                }

                #related-breed-02 {
                    grid-area: 3 / 1 / 5 / 3;
                }

                #related-breed-03 {
                    grid-area: 3 / 3 / 4 / 4;
                }

                #related-breed-04 {
                    grid-area: 2 / 1 / 3 / 2;
                }

                #related-breed-05 {
                    grid-area: 1 / 1 / 2 / 2;
                }

                #related-breed-06 {
                    grid-area: 4 / 3 / 5 / 4;
                }

                &.many-breeds {
                    grid-template-columns: repeat(3, 1fr);
                    grid-template-rows: repeat(6, 1fr);

                    #related-breed-01 {
                        grid-area: 1 / 2 / 3 / 4;
                    }

                    #related-breed-02 {
                        grid-area: 3 / 1 / 5 / 3;
                    }

                    #related-breed-03 {
                        grid-area: 5 / 2 / 7 / 4;
                    }

                    #related-breed-04 {
                        grid-area: 3 / 3 / 4 / 4;
                    }

                    #related-breed-05 {
                        grid-area: 4 / 3 / 5 / 4;
                    }

                    #related-breed-06 {
                        grid-area: 2 / 1 / 3 / 2;
                    }

                    #related-breed-07 {
                        grid-area: 5 / 1 / 6 / 2;
                    }

                    #related-breed-08 {
                        grid-area: 1 / 1 / 2 / 2;
                    }

                    #related-breed-09 {
                        grid-area: 6 / 1 / 7 / 2;
                    }

                }
            }

            .has-only-child {
                display: flex;

                &.breed-names {
                    padding-right: 3em;
                }

                &.related-breed-bubble,
                &.related-breed-bubble:nth-child(odd) {
                    padding: 7%;
                    margin-bottom: 0;
                }
            }

            .breed-names ul li {
                margin-bottom: 2em;
            }

            .favorite-names {
                margin-bottom: 0;
            }
        }

        .breed-common-locations ol {
            column-count: 2;
        }

        .on-embark {
            padding: 2em 0 1em;

            .on-embark-cta {
                padding: 0 0 0 1em;
            }
        }
    }

    .breeder-callout-link {

        @extend .link--dotted--green;
        display: block;
        margin: 0 rem(24) rem(16);

        @include breakpoint(medium up) {
            text-align: center;
        }
    }

    .breed-quick-facts {
        padding-top: 16px;

        .grid-x {
            justify-content: space-between;
        }

        @include breakpoint(medium up) {

            margin-right: 0;
        }

        border-radius: rem(8px);
        margin-bottom: rem(24);

        &--label {

            @extend .headline5;
            font-size: rem(16px);
            font-weight: 600;
        }

        &--header {
            display: flex;
            gap: 12px;
        }

        .breed-height dl,
        .breed-weight dl,
        .breed-lifespan dl,
        .breed-ukc-group dl {
            margin-left: 36px;
        }


        .breed-fun-fact {
            margin-top: rem(10px);
        }

    }

    .on-embark-v2 {
        padding: 1rem 1rem 0;
        border-radius: rem(7);
        align-items: center;
        margin-bottom: 2rem;

        @include breakpoint(medium) {

            @include float(left);
            margin-right: rem(24px);
            margin-bottom: rem(40px);
        }

        .on-embark-cta {
            padding: 0 0 1rem 0;
        }

        .embark-dog {

            &--image {
                max-width: 12em;
                margin-bottom: 0.5rem;
                width: 100%;
                border-radius: rem(5);
            }

            &:nth-child(even) {
                padding-left: 1em;
            }

            &:nth-child(odd) {
                padding-right: 1em;
            }

        }

        .embark-dog--thumb {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            overflow: hidden;
            margin-right: 1rem;

            img {
                width: 100%;
            }
        }

        .orbit-wrapper {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column-reverse;

            .orbit-controls {
                display: flex;
                justify-content: space-between;

                .orbit-previous,
                .orbit-next {
                    position: static;
                    padding: 1rem 0 1rem;
                    transform: translateY(0);
                }
            }
        }

        .name-and-breed {
            margin-bottom: 1rem;

            .pet-name-and-breed-percent {
                padding-left: 0.5rem;

                @include breakpoint(small) {
                    padding-left: 1rem;
                }

                .pet-name {
                    margin-bottom: 0;
                    font-weight: 600;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2; /* https://stackoverflow.com/a/13924997/2641971 */
                    -webkit-box-orient: vertical;
                }

                .pet-breed-percent {
                    text-transform: uppercase;
                }
            }

        }
    }

}
