.page-template-temp-compare {

    section {
        margin-top: 4rem;
    }

    .section-banner {

        .hero {
            background: #5529a4;
            background-image: none !important;
            color: $white;
        }
    }

    .stand-alone-text h2 {
        margin: 0 50px 25px 50px;
    }

    .has-bg-color {
        background: $light-gray;
        border: 0;
        border-radius: 5px;
        padding: 50px;
    }

    .compare-embark-logo {
        padding-left: 20%;
        margin-top: -20px;
    }
}


.compare-competition-title,
.compare-embark-title {
    text-align: center;
}

.compare-chart ul {
    list-style: none;
}

.compare-chart li {
    margin: 0.85rem 0;
}

.compare-chart li h5 {
    display: table-cell;
}

.compare-feature-us > h5,
.compare-feature-them > h5 {
    font-weight: 700;
}

.compare-feature-us::before {
    content: url("data:image/svg+xml,%3Csvg height='20' viewBox='0 0 27 20' width='27' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m214 8 7.5 8 16-16' fill='none' stroke='%23057b5d' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' transform='translate(-212 2)'/%3E%3C/svg%3E");
    padding-right: 10px;
    display: table-cell;
    vertical-align: middle;
}

.compare-feature-them::before {
    content: url("data:image/svg+xml,%3Csvg height='20' viewBox='0 0 20 20' width='20' xmlns='http://www.w3.org/2000/svg'%3E%3Cg style='stroke:%23aaa;stroke-width:3;fill:none;fill-rule:evenodd;stroke-linecap:round;stroke-linejoin:round' transform='translate(2 2)'%3E%3Cpath d='m0 16 16-16'/%3E%3Cpath d='m0 16 16-16' transform='matrix(1 0 0 -1 0 16)'/%3E%3C/g%3E%3C/svg%3E");
    padding-right: 10px;
    display: table-cell;
    vertical-align: middle;
}

@media print, screen and (max-width: 640px) {

    .page-template-temp-compare section.comparison-page-header {
        margin-top: 0;
        padding-top: 2em;
    }

    .compare-chart ul {
        margin-left: 0.5rem;
    }

    .compare-chart li h5 {
        font-size: 0.9em;
        line-height: 1.5;
        margin-top: 0;
        margin-bottom: 0.5rem;
    }

    .compare-competition-title {
        margin-top: 1rem;
        text-transform: uppercase;
    }

}

/* Medium and up */
@media screen and (min-width: 40em) {

    .compare-chart li {
        margin: 1.7em 0;
    }

}

/* image-set */
.section-image-set h4 {
    margin: 2rem;
}

.section-image-set .image-set {
    justify-content: space-evenly;
}

@media screen and (max-width: 39.9375em) {

    .section-image-set .image-set > .cell {
        margin-top: 1rem;
    }
}

/*
* Styles for Compare page
* Template: temp-compare.php
*/

.compare .section-banner .section-bg {
    width: 100%;
}

