/*
* Styles for Career page
*/
.job-listings-wrapper {

    .job-listings {

        .job-listing {

            a.job-apply {
                color: $purple;

                &:focus,
                &:hover {
                    color: $purple-dark;
                    text-decoration: underline;
                }
            }
        }
    }
}

#jobFilters {
    margin-bottom: rem(32);
    align-items: center;

    .sr-only {
        border: 0 !important;
        clip: rect(1px, 1px, 1px, 1px) !important;
        -webkit-clip-path: inset(50%) !important;
        clip-path: inset(50%) !important;
        height: 1px !important;
        margin: -1px !important;
        overflow: hidden !important;
        padding: 0 !important;
        position: absolute !important;
        width: 1px !important;
        white-space: nowrap !important;
        display: inherit !important;
    }

    .jazzhr-filter {

        @include breakpoint(medium) {
            margin-right: rem(16);
        }

        &:first-child {

            @include breakpoint(medium) {
                margin-left: auto;
            }

        }

        &:last-child {

            @include breakpoint(medium) {
                margin-right: auto;
            }
        }

        select {
            border-radius: 5px;
            border-color: $gray;

            @include breakpoint(large) {
                margin-bottom: 0;
            }
        }
    }

    #clearFilters {
        width: 100%;

        @include breakpoint(large) {
            margin-top: 0;
            width: auto;
        }
    }

}


.job-listing-wrapper {

    .job-listing {

        @include breakpoint(medium) {
            align-items: center;
        }

        .job-type,
        .job-location {
            color: $gray;
            margin-bottom: 0;
        }

        .job-apply {

            .job-apply-link {
                color: $purple;

                &:focus,
                &:hover {
                    color: $purple-dark;
                    text-decoration: underline;
                }
            }
        }

    }
}
