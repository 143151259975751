.filter-group {
    margin: 0 0 32px 0;

    &__controls {
        display: flex;
        gap: 8px;
        align-items: center;
    }

    &__item {
        flex: 1;
    }

    input[type="text"],
    input[type="search"],
    select {
        border: 1px solid #949494;
        border-radius: 8px;
        min-height: 46px;
    }

    &__item:has(input[type="checkbox"]) {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        gap: 8px;

        [type="checkbox"] {
            -webkit-appearance: none;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            cursor: pointer;
        }

        label {
            position: relative;
            background: #fff;
            display: inline-flex;
            color: #000;
            border: 1px solid #000;
            border-radius: 35px;
            padding: 3px 16px;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            z-index: 2;
        }

        label:has(input:checked) {
            background: #000;
            color: #fff;
            border: 1px solid transparent;
        }
    }
}


.drawer__footer--marketplace {
    padding: 32px 24px;
    box-shadow: 0 -9px 20px 0 #00000014;

    &-buttons {
        display: grid;
        grid-template-columns: 4fr 8fr;
        gap: 16px;
    }
}
