$dnaMonthBreederGreen: #193637;

.page-template-temp-dna-month-breeder {

    .announcement-bar:not(.dna-month-announcement-bar) {
        display: none;
    }

    .announcement-bar {

        @include dna-month-announcement-bar;
    }

    #footer {
        position: relative;
    }

    #dna-testimonials .swiper-pagination-bullet-active {
        background-color: $dnaMonthBreederGreen;
    }

    .who-says-copy {
        padding-right: rem(32);
    }
}


.dnaMonthBreederPage {

    #dna-testimonials .dna-headline-text,
    .text-color-primary {
        color: $dnaMonthBreederGreen !important;
    }

    .who-says-image img {
        float: right;
    }

    #dna-products {

        .product-cta .sub-heads {

            color: #02818e;
            margin-top: 0;
            font-size: 1.25rem;
            letter-spacing: 1.12px;

            @include breakpoint(large) {
                max-width: 230px;
            }
        }

        .dna-product .product-wrapper img {

            @include breakpoint(small only) {
                margin-bottom: -10%;
                margin-top: -5%;
            }
        }

        .product-wrapper {

            @include breakpoint(small only) {
                margin-top: 2rem;
            }
        }

        .product-info {

            @include breakpoint(small only) {
                margin-top: 28px;
            }
        }
    }

    .dna-month-page-footer {

        background-color: $dnaMonthBreederGreen;

        h1 {

            color: $white;
        }
    }
}

.dnaMonthBreederPage#dna-month-breeder-landing-page {

    .dnaMonthBreederPage-hero {
        background-color: #b5a167;
        background-image: url(https://res.cloudinary.com/embark/image/upload/f_auto,q_auto,a_hflip/v1617045817/embarkvet.com/dist/img/dna-month/breeders/Cairn_Terrier_Mobile.jpg);
        background-size: cover;
        background-repeat: no-repeat;
        padding: 6rem 0;
        position: relative;

        &-copy {
            transform: translateY(25px);

            p {

                @media (min-width: 660px) and (max-width: 1010px) {
                    max-width: 300px;
                }
            }
        }

        @include breakpoint(large) {
            background-image: url(https://res.cloudinary.com/embark/image/upload/f_auto,q_auto/v1617044740/embarkvet.com/dist/img/dna-month/breeders/Cairn_Terrier.jpg);
            background-position: bottom left;
            background-repeat: no-repeat;
            padding: 9rem 0;
            position: relative;
            background-size: cover;
        }

        @include breakpoint(xlarge) {
            padding: 14rem 0 !important;
        }

    }

    .decode-your-program {
        background-image: url("https://res.cloudinary.com/embark/image/upload/f_auto,q_auto,a_hflip/v1617053629/embarkvet.com/dist/img/dna-month/breeders/Maltese.jpg");
        background-size: cover;
        background-position: center;

        .dna-month-callout-copy {

            @include breakpoint(small only) {
                padding: 0 1.1rem;
            }

            &-heading {
                color: $white;

                @include breakpoint(small only) {
                    max-width: 300px;
                }

                @include breakpoint(medium only) {
                    max-width: 445px;
                }
            }
        }


        @include breakpoint(medium up) {
            padding: 9.5rem 0;
        }

        @include breakpoint(large up) {
            background-image: url("https://res.cloudinary.com/embark/image/upload/f_auto,q_auto/v1617112614/embarkvet.com/dist/img/dna-month/breeders/Maltese_Photo_Resized.jpg");
        }

        @include breakpoint(xlarge up) {
            min-height: 650px;
        }

    }
}
