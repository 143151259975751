.author-box {
    background: $light-gray;
    border-radius: 24px;
    padding: rem(32);
    margin: 1rem;
    flex: 1;
    max-width: 718px;
    text-align: center;

    @include breakpoint(medium) {
        padding: rem(60);
        margin: rem(16px) auto;
    }

    img {
        max-width: rem(150px);
        border-radius: 50%;
    }

    &__long-bio {
        text-align: left;
        line-height: rem(24px);
        letter-spacing: rem(0.14px);
    }

    &__role {
        margin-top: rem(32px);
    }
}
