/*
* Styles for Breeders feature page
* Template :temp-breeder-feature.php
*/

.breeder-feature .section-breeder-banner {
    background-color: #24262a;
    margin-top: 0;
}

.breeder-feature .section-breeder-banner h1.title {
    color: $white;
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.breeder-feature .section-breeder-banner img.hero-category--image {
    width: 100%;
    height: auto;
}

.breeder-feature .section-genetic-content {
    justify-content: center;
}

.breeder-feature .section-genetic-insights {
    padding-top: 1rem;
}

.breeder-feature .section-genetic-insights .column-right {
    padding-bottom: 2rem;
    margin-top: 0;
}

.breeder-feature .section-genetic-insights .column-left {
    padding-top: 0;
}

.breeder-feature .section-health-tests {
    background-color: $black;
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: 360px;
    padding: 1rem 0 250px;
    color: $white;
    height: auto;
}

.breeder-feature .section-health-tests .health-tests-content {
    justify-content: center;
}

.breeder-feature .section-send-results {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.breeder-feature .section-send-results .column-right {
    margin-top: 2rem;
}

.breeder-feature .slider-tabbed {
    padding-top: 1rem;
}

.breeder-feature .section-genetic-coefficient {
    background-color: #f7f7f7;
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: 380px;
    padding-top: 1rem;
    padding-bottom: 200px;
}

.breeder-feature .section-health-tests h1 {
    margin-top: 0;
}

.breeder-feature .section-genetic-coefficient,
.breeder-feature .section-health-tests {
    position: relative;
}

.get-started-dog-bg h1 {
    margin: 0;
}

@media print, screen and (min-width: 1024px) {

    .responsive-nav-group {
        margin-bottom: 0;
    }
}

@media print, screen and (min-width: 40em) {

    .breeder-feature .section-breeder-banner img.hero-category--image {
        max-height: 380px;
        height: 100%;
        width: auto;
    }

    .breeder-feature .section-breeder-banner h1.title {
        text-align: left;
        margin-top: 0;
        margin-bottom: 0;
    }

    .breeder-feature .section-health-tests {
        background-position: bottom right;
        background-size: 50%;
        padding: 4rem 0;
    }

    .breeder-feature .section-genetic-content {
        justify-content: left;
    }

    .breeder-feature .section-genetic-coefficient {
        padding: 4rem 0;
        background-position: center left;
        background-size: 50%;
    }

    .breeder-feature .section-health-tests .health-tests-content {
        justify-content: flex-start;
    }

    .breeder-feature .section-send-results {
        padding-top: 4rem;
        padding-bottom: 4rem;
    }

    .breeder-feature .section-send-results .column-right {
        margin-top: 0;
    }
}

@media print, screen and (min-width: 64em) {

    .breeder-feature .section-health-tests {
        background-size: contain;
    }
}
