.conditions-search {

    .breed-health-search {

        max-width: 565px;
        margin: 0 auto;

    }

    #health-list-results {

        .accordion {

            background: $white;
            margin-top: rem(24);
        }

        a.accordion-title {

            border: 1px solid $gray;
            border-radius: 5px;
            color: $black;

            &::before {

                content: "\203A";
                font-size: 3rem;
                margin-top: -1.75rem;
                transition: all 0.5s ease;
                transform: rotate(90deg);
            }
        }

        .is-active {

            .accordion-title::before {

                transform: rotate(-90deg) translate(0, -6.5px);
            }
        }
    }

    .breed-search-form {

        background-color: $white;
        border: 1px solid $gray;
        border-radius: 50px;
        display: flex;
        padding: 0.25rem;
        position: relative;
        width: 100%;

        .search-form-size {

            position: absolute;
            width: calc(100% - 38px);

        }

        &__input {

            flex-grow: 1;

            input {

                border: none;
                background: none;
                box-shadow: none;
                margin-left: 15px;
                padding-left: 0;
                padding-right: 20px;

                &::placeholder {
                    color: $gray;
                    opacity: 1;
                }
            }
        }
    }
}
