.sectionCenteredText {

    @include xy-gutters( $gutter-type: padding, $gutter-position: top bottom, $gutters: (
        small: 2rem
    ));

    &--ctas {
        text-align: center;
    }
}

.section-centered-text {

    &.grid-container > .grid-container {
        padding: 0;
    }

}
