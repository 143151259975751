.site-width {
    max-width: 1920px;
    margin: 0 auto;
}

a {
    color: $black;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
    font-family: $font-family;
}

body {
    font-family: $font-family;
    font-display: swap;
    font-size: rem(16px);
    line-height: 1.5;
    letter-spacing: 0.15px;
}

a.text-link {

    @include refresh-text-link;
}

.grecaptcha-badge {
    visibility: hidden;
}
