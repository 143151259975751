.top-nav-account-icon-wrapper {

    &__icon {
        width: 20px;
        margin-top: rem(9px);
        margin-right: rem(16px);

        @include breakpoint(large) {
            margin-right: rem(4px);
        }
    }
}
