article div {

    &.fca_qc_quiz button.fca_qc_button {

        font-size: rem(18);
        letter-spacing: 2px;
        background-color: #ffce34 !important;
        padding: 15px 0;
        color: $black;
        display: inline-block;
        border: 0;
        box-shadow: none !important;

        &:hover {
            background-color: #f2ba28 !important;
        }
    }

    &.fca_qc_answer_div {

        &.fakehover,
        &:active {
            background-color: #f2ba28 !important;
        }
    }
}

/* quiz /intro-to-genetics-quiz */
div#fca_qc_quiz_32943 {

    @include breakpoint(small only) {
        min-width: 300px;
    }

    @include breakpoint(medium) {
        min-width: 600px;
        margin-left: -180px;
    }

    .fca_qc_button {

        color: $black;
        display: inline-block;
        text-transform: uppercase;

        @include breakpoint(small) {
            padding: rem(12.8) rem(20) rem(12) !important;
        }

        @include breakpoint(medium) {
            width: initial;
        }
    }

    .fca_qc_quiz_title {
        display: none;
    }

    #fca_qc_answer_container {

        @include breakpoint(small only) {
            margin-left: -25px;
        }
    }

    #fca_qc_back_container {

        @include breakpoint(small only) {
            margin-left: 20px;
        }
    }
}
