/*
* Styles for Our-story page
* Template: temp-our_story.php
*/

#page-ourstory {

    h3 {
        text-align: center;
        margin-bottom: rem(50);
    }

    .carousel-text {
        margin-top: rem(80);
        margin-bottom: rem(80);

        a,
        h3 {

            color: $black;
        }

    }

    #missiontext {

        img {
            margin-bottom: rem(30);
        }
    }

    #scientific-vision {
        max-width: 50rem;
        margin: rem(80) auto 0 auto;

        img {
            margin-bottom: 30px;
        }
    }

    #embark-with-us {
        margin: rem(80) auto 0 auto;

        .solid-background {
            background: #e8e4d9;
            padding: 50px 50px 200px 50px;

            p {
                max-width: 350px;
            }

            h3 {
                color: $white;
                text-align: left;
                margin-bottom: rem(20);
            }
        }

        .embark-photo {
            width: 100%;
            height: 100%;
        }

        .embark-photo-container {
            margin-top: -180px;
        }
    }

    #who-we-are {

        img {
            max-width: 218px;
            height: auto;
            display: block;
            margin-left: auto;
            margin-right: auto;
        }

        .profile {
            text-align: center;
            margin-bottom: 50px;
        }

        .profile.profile_gap {
            margin-bottom: 50px;
            float: left;
        }
    }

    #bottom-column {

        margin-bottom: 50px;

        h3 {
            text-align: center;
            margin-bottom: 20px;
        }

        .full-width-image img {
            max-width: 100%;
            height: auto;
            margin-bottom: 30px;
        }
    }

    #research-carousel {
        position: relative;

        h3 {
            text-align: left;
            margin-bottom: rem(10);
        }

        li {
            height: 100%;
        }

        .carousel-text {
            padding: 0 50px;
        }

        .row {
            margin-right: 0;
            margin-left: 0;
        }

        .slider-photo {
            position: relative;
            height: 100%;
            background-size: cover;
            background-position: center;
        }

        .orbit-row {
            height: 100%;
            background-color: #95d600;
        }

        .orbit-container {
            background: #95d600;
            overflow: hidden;
        }

        .orbit-slide:nth-child(1) .orbit-row {
            background: #e8bb8f;
        }

        .orbit-slide:nth-child(2) .orbit-row {
            background: #579787;
        }

        .orbit-slide:nth-child(3) .orbit-row {
            background: #ffa866;
        }

        .orbit-bullets {

            button {
                width: 12px;
                height: 12px;
                border: 1px solid #9e9ea2;
                background: $white;

                &.is-active {
                    background: #9e9ea2;
                }
            }
        }
    }
}

@media print, screen and (min-width: 40em) {

    #page-ourstory #missiontext img {
        margin-bottom: 0;
    }

    #page-ourstory section#scientific-vision img {
        margin: 0;
    }

    #page-ourstory section#embark-with-us .red-background {
        padding: 150px 130px 150px 50px;
    }

    #page-ourstory section#embark-with-us .embark-photo-container {
        margin-top: 0;
        position: relative;
    }

    #page-ourstory section#embark-with-us .embark-photo-container > img {
        position: absolute;
        left: -90px;
        top: 50%;
        transform: translateY(-50%);

        @include breakpoint(medium only) {
            left: -43px;
        }
    }

    #page-ourstory section#who-we-are .row {
        margin-bottom: 50px;
    }

    #page-ourstory section#who-we-are .button-learn-more {
        margin-top: 4rem;
        margin-bottom: 3rem;
    }

    #page-ourstory #research-carousel .carousel-text {
        padding: 0;
    }

    #page-ourstory section#who-we-are .profile {
        text-align: left;
        margin-bottom: 0;
    }
}

@media screen and (max-width: 639px) {

    #page-ourstory section#embark-with-us .embark-photo-container {
        transform: translate(0, 0);
    }
}

@media print, screen and (max-width: 40em) {

    #page-ourstory section#scientific-vision .grid-x {
        justify-content: center;
    }

    #page-ourstory section#scientific-vision .grid-x.flex-dir-row-reverse {
        flex-direction: row;
    }
}
