.section-media-object .accent-image {
    max-width: 80%;
}

.section-media-object .primary-image-container img {
    margin: 1rem auto;
}

@media screen and (min-width: 64em) {

    .section-media-object .accent-image {
        max-width: 60%;
    }
}

@media screen and (max-width: 39.9375em) {

    .primary-image-container {
        text-align: center;
    }

    .primary-image-container img {
        max-width: 80%;
    }
}

.slider-container {
    padding: 0;
}

.section-media-object-with-modal .primary-image-container img,
.section-media-object-with-modal .button-large,
.section-media-object .button-large {
    margin: 2.5rem auto;
}
