.inline-products {
    border-top: 1px solid #ccc;
    margin: 1rem 0;

    .inline-product {
        padding: 1rem 0;
        border-bottom: 1px solid #ccc;
    }
}

.inline-product {
    display: grid;
    grid-template-columns: 2fr 3fr 1fr;
    align-items: center;
    gap: 10px;

    @include breakpoint(medium up) {
        grid-template-columns: 2fr 8fr auto;
    }

    &__image {

        img {
            border-radius: 0;
        }
    }

    &__body {

        h5 {
            margin-bottom: 8px;
        }

        @include breakpoint(small only) {

            h5,
            .price {
                font-size: 1rem;
            }
        }
    }

    .price {
        display: inline-block;

        &--sale {
            color: $black;
            font-weight: 600;
            margin-right: 8px;
        }
    }
}
