/*
* Styles for partner page
* Template: temp-partner.php
*/

.embark-partner {
    position: relative;
}

.embark-partner > section:nth-child(n+2) {
    margin-top: 80px;
}

.embark-partner .hero {
    height: 300px;
    position: relative;
    color: $white;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-top: 0;
}

.embark-partner .hero-text {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    height: 190px;
    width: auto;
}

.embark-partner .hero h1 {
    text-transform: none;
    margin-bottom: 0;
    font-weight: bold;/* stylelint-disable-line font-weight-notation */
}

.embark-partner .hero .hero-text > h1,
.embark-partner .hero .hero-text > h4 {
    text-shadow: 0 2px 14px rgba(0, 0, 0, 0.5);
}

.embark-partner .section-for-partners .partner-bg {
    background-position: center;
    background-size: cover;
    margin-bottom: 100px;
    padding-top: 1rem;
}

.embark-partner .section-for-partners .column-right > img {
    width: 210px;
    transform: translateY(20px);
    display: inline-block;
}

.embark-partner .section-for-partners .column-right {
    max-height: 210px;
    text-align: center;
}

@media screen and (min-width: 40em) {

    .embark-partner .hero-text > h1 {
        font-size: 50px;
    }

    .embark-partner .hero-text {
        width: 100%;
        top: 25%;
        left: auto;
        right: auto;
        bottom: auto;
        height: auto;
        transform: translateY(-25%);
    }

    .embark-partner .section-for-partners {
        height: auto;
        margin-bottom: 100px;
        padding-top: 0;
    }

    .embark-partner .section-for-partners .column-right {
        text-align: center;
        max-height: 280px;
    }

    .embark-partner .section-for-partners .column-right > img {
        width: auto;
        height: 420px;
        position: relative;
        transform: none;
        display: inline-block;
    }
}

@media screen and (min-width: 64em) {

    .embark-partner .section-for-partners .partner-bg {
        height: 333px;
        margin-bottom: 60px;
    }

    .embark-partner .section-for-partners .column-right {
        max-height: none;
        text-align: right;
    }
}

@media screen and (max-width: 40em) {

    .embark-partner > section:nth-child(n+2) {
        margin-top: 2rem;
    }
}
