.discover-cta-block {

    background: $blue-purple;
    background-image: linear-gradient(284deg, $purple 100%, $blue-purple 0%);
    color: $white;
    margin-top: rem(32);
    margin-bottom: rem(32);
    padding-top: rem(48);
    padding-bottom: rem(48);

    &__heading {
        line-height: 1.2;

        @include breakpoint(small only) {
            margin-top: rem(32px);
        }
    }

    &__features {
        display: table;
        margin: 0 auto 1.5rem auto;
        text-align: left;
        font-size: rem(21px);
        line-height: 1.3;
        font-weight: 800;

        @include breakpoint(medium up) {
            margin: 0 0 1.5rem 1.5rem;
        }

        .plus-indicator {
            list-style-type: none;

            &::before {
                content: "+";
                margin-left: rem(-24);
                padding-right: 0.65rem;
            }
        }
    }
}
