.category-nav {

    nav.large-screen-subnav {
        margin-bottom: rem(16px);

        a {
            color: $black;
            font-weight: 600;

            &:hover,
            &:focus {
                color: $black;
            }
        }

        ul {
            justify-content: center;
        }

        li {
            flex-grow: 1;
            text-align: center;
            max-width: 280px;

            p,
            a {
                padding: rem(16px);
            }
        }

        .current-cat {
            border-color: $black;

            p {
                margin: 0;
                font-size: rem(15px);
                line-height: 1;
            }

        }
    }

    .small-screen-subnav {
        margin-top: rem(8px);
    }

    .accordion {
        display: block;
        margin: 0 rem(16px);
        border: 1px solid #000;
        border-radius: 8px;
        margin-bottom: rem(16px);

        a {
            color: $black;

            &:hover,
            &:focus {
                color: $black;
            }
        }

        .is-active .accordion-title {

            &::before {
                transform: rotate(0deg);
            }

        }

        :last-child:not(.is-active) > .accordion-title {
            border-bottom: none;
            border-radius: rem(8px);
        }

        &-title {
            border: none;
            font-size: 1rem;
            font-weight: 700;
            padding: rem(16px);

            &::before {
                content: url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.41 7.41L6 2.83L10.59 7.41L12 6L6 3.8147e-06L0 6L1.41 7.41Z' fill='%23070000'/%3E%3C/svg%3E%0A");
                font-weight: 300;
                transition: all 0.5s ease;
                transform: rotate(-180deg);
            }

        }

        &.menu .is-active > a {
            background: none;
            border: none;
            color: $black;
        }

        &-content {
            border: none;
            padding: 0 rem(16px);
            background-color: inherit;

            &:last-child {
                border: none;
            }
        }

        .category--mobile-nav-items {
            margin-left: 0;
            list-style: none;

            li:first-child a {
                border-top: 1px solid $mid-gray;
            }

            li:not(:last-child) {
                border-bottom: 1px solid $mid-gray;
            }

            a {
                padding: rem(16px) 0;

                &:hover,
                &:focus {
                    text-decoration: underline;
                }
            }

            p {
                line-height: 1;
                margin: 0;
                padding: rem(16px) 0;
                font-weight: 700;
            }
        }
    }
}

.category--conclusion {
    margin: rem(32px) 0;
    padding: rem(24px) 0 rem(16px);
    background: $light-gray;

    .column-rounded-shadows {
        background: $white;
    }

}

.category {

    &--articles-list {

        &--subhead {

            margin: rem(32px);
        }
    }
}

.category-icon-nav {

    &--bottom {

        margin-top: rem(64px);

        h3 {
            text-align: center;
            font-weight: 600;
        }
    }

    &--top {

        position: relative;
        z-index: 2;
        margin: -80px auto auto auto;

        @include breakpoint(large) {
            margin: -150px auto auto auto;
        }
    }

    .badge--icon {

        @include breakpoint(small only) {
            font-size: 1.125rem;
            width: 9rem;
            min-height: 9rem;
            height: auto;
        }

        @media only screen and (max-width: 325px) {
            font-size: rem(16px);
            width: rem(112px);
            height: auto;

            img {
                height: rem(44px);
                width: rem(44px);
            }
        }
    }
}

.category-description {
    max-width: rem(760px);
    margin: 0 auto;
    text-align: center;
}

.badge-nav {
    margin: 0 auto;
    text-align: center;

    .badge--text {
        background-color: #e0e0e0;
    }

    a {
        text-decoration: none;
    }
}
