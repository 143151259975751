@import "../partials/pages/marketplace-pages/_product-details-drawer.scss";

.marketplace-page {
    max-width: 1200px;
    margin: 0 auto;

    .marketplace-header {
        padding: 1rem;
        text-align: center;

        &__copy {
            max-width: 485px;
            margin: 0 auto;
        }
    }

    &__section {
        max-width: 1200px;
        margin: 0 auto;
        padding: 8px;
    }

    .marketplace-content {

        &__headline {
            font-weight: 600;
        }

    }

    .marketplace-lifestage {
        max-width: 375px;

        @include breakpoint(medium) {
            max-width: 575px;
        }

        &__headline {
            font-weight: 600;
            text-align: left;

            @include breakpoint(medium) {
                text-align: center;
            }
        }

        &__items {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 8px;

            .lifestage-item {
                text-decoration: none;

                img {
                    width: 100%;
                    max-width: 300px;
                    height: auto;
                    border-radius: 8px;
                }

                &-headline {
                    font-weight: 600;
                    padding: 0.5rem 0 1rem;
                }
            }
        }
    }

    .marketplace-footer {
        padding: 0.75rem;

        &__container {
            padding: 24px 24px 32px 24px;
            background-color: #feedb3;
            border-radius: 8px;
            max-width: 1200px;
            margin: 0 auto;
            text-align: center;

            h2 {
                font-weight: 600;
            }

            .text {
                max-width: 594px;
                margin: 0 auto 1.5rem;
            }

            .link {
                color: #000;
                background-color: #fff;
                border: 2px solid #000;
                padding: 8px 14px;
                border-radius: 24px;
                text-decoration: none;
                font-weight: 700;
            }
        }
    }

    &__meta {
        display: grid;
        grid-template-columns: 1fr 1fr;
        justify-content: space-between;
        align-items: center;

        :last-child {
            margin-left: auto;
        }
    }
}


.section-content {

    &--nutrition-marketplace {
        margin: 50px auto;
    }

    &-back-nav {
        margin-bottom: 0;
    }

    &__intro {
        max-width: 640px;
    }

    &--articles {
        margin-bottom: 40px;

        a {
            text-decoration: none;
        }

        .article {
            display: flex;
            flex-direction: row;
            background-color: #f5f5f5;
            align-items: center;
            border-radius: 8px;
            padding: 14px;
            width: fit-content;

            &__image {

                img {
                    max-height: 100px;
                    border-radius: 8px;
                }
            }

            &__copy {
                font-weight: 600;
                padding: 8px 32px 8px 8px;
            }
        }
    }

}

.product-cards-grid {
    display: grid;
    gap: 16px;
    grid-template-columns: 1fr 1fr;
    flex-wrap: wrap;
    margin: 24px auto;

    @include breakpoint(medium) {
        gap: 32px;
        grid-template-columns: repeat(3, 1fr);
    }

    &--scrollable-on-mobile {

        @include breakpoint(small only) {
            display: flex;
            flex-wrap: nowrap;
            overflow-x: auto;
            overflow-y: hidden;
            scroll-snap-type: x mandatory;

            &::-webkit-scrollbar {
                width: 0;
                background: transparent;
            }

            .product-card {
                min-width: calc(100vw / 2.4);
                max-width: calc(50vw - 16px);
                scroll-snap-align: start;
            }
        }
    }
}


@import "scss/partials/marketplace-filters-drawer";


// Guides
.guides-breed-size-switcher {
    width: 100%;
    margin: 25px auto;

    @include breakpoint(medium) {
        display: flex;
        justify-content: center;
        max-width: 425px;
    }
}

.pills-group {
    display: flex;
    margin: 0;
    background: #fff;
    color: #000;
    border-radius: 25px;
    list-style: none;
    overflow: hidden;
    border: 2px solid #000;
    width: 100%;

    a {
        color: #000;
        text-decoration: none;
        font-weight: 600;
        font-size: rem(14px);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 9px 4px;
    }

    .item {
        border-radius: 25px;
        text-align: center;
        flex: 1;

        &:last-child {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }

        &:first-child {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }

        &--selected {
            background: #e0e0e0;

            a {
                font-weight: 400;
                color: #626262;
            }
        }
    }

}

.marketplace-disclaimer {
    padding: 16px;
    text-align: center;

    p {
        font-size: rem(14px);
    }

    &--blue {
        border-radius: 8px;
        background: rgba(0, 129, 142, 0.1);
    }
}

.guides-disclaimer {
    font-size: rem(14px);
    text-align: center;
    padding: 2px 14px;
    line-height: 125%;
    letter-spacing: 0.021px;
    margin: auto;

    &--left {
        text-align: left;
        padding-left: 0;
        margin-bottom: 8px;

        @include breakpoint(medium) {
            margin-bottom: 32px;
        }
    }
}
