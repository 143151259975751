.elementor-widget-button {

    .elementor-button-wrapper {

        .elementor-button-link {

            @extend .refresh-button;
            font-size: 1rem;

            &.elementor-size-hero {

                @extend .refresh-button-hero;

            }

        }

    }

    &.elementor-button-primary .elementor-button {

        @include refresh-button-primary;
    }

    &.elementor-button-primary-light .elementor-button {

        @include refresh-button-secondary;
    }

    &.elementor-button-primary--shop .elementor-button {

        @include refresh-button-primary--shop;
    }

    &.elementor-button-primary--vet .elementor-button {

        @include refresh-button-primary--vet;
    }

    &.elementor-button-treat-variant .elementor-button,
    .breeder-section &.elementor-button-primary .elementor-button {

        @include refresh-button-primary;
    }

    &.elementor-button-secondary .elementor-button {

        @include refresh-button-secondary;
        padding: 8px rem(14);
    }

    &.elementor-button-secondary-alt .elementor-button {

        @include refresh-button-secondary-alt;
        padding: 8px rem(14);
    }

}

.jet-tabs {

    [tabindex]:focus {

        @include reset-focus-ring-to-browser-default;
    }

    &__content {
        display: none;

        &.active-content {
            display: block;
        }
    }
}

// elementor specificallity overrides this style so instead of putting
// an !important I am adding this more specific rule.

.elementor a:hover {
    text-decoration: underline;
}
