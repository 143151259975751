
/* 404 pages */
@media screen and (min-width: 40em) {

    .error-404 .error-messages > h1 > b {
        font-size: 80px;
        margin: 0;
    }

    .error-404 .error-messages > h1 {
        font-size: 50px;
        margin: 0;
        font-weight: 400;
        line-height: 1.36;
    }
}

.error-404 .error-messages > a {
    margin-top: 1em;
    font-weight: 400;
}
