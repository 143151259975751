/** START CSS for Default Page Template **/

.page-template-default .site-main article {
    padding: 3rem 0.75rem;
    text-align: center;
}

.page-template-default iframe {
    width: 100%;
    padding: 20px;
}

/** END CSS for Default Page Template **/
