@import "../variables";

.wavy-bottom-divider {
    position: relative;
    width: 100%;
    margin-bottom: 10px;

    @include breakpoint(medium) {
        margin-bottom: 25px;
    }

    @include breakpoint(large) {
        margin-bottom: 45px;
    }

    @include breakpoint(xlarge) {
        margin-bottom: 90px;
    }

    svg {
        position: absolute;
        width: 100%;
        margin-top: -1px;
    }

    &__white > svg path {
        fill: $white;
    }

    &__yellow > svg path {
        fill: $yellow;
    }

    &__soft-yellow > svg path {
        fill: $soft-yellow;
    }

    &__light-blue > svg path {
        fill: $soft-blue;
    }
}
