.trending-topics {
    margin-top: 20px;

    @include breakpoint(medium) {
        margin-top: 32px;
    }

    &--breed-pages {
        margin: 32px;
    }

    &__links {

        .badge {
            text-align: left;

            @include breakpoint(medium up) {
                text-align: center;
            }
        }
    }
}
