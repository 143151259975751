@import "../sections/section-breed-list-chunks";

.section-head {
    max-width: 550px;
    margin-left: auto;
    margin-right: auto;
}

.consumer-breed-conditions-hero {
    background: $blue-purple;
    color: $white;
    margin-top: -1rem;
    padding: rem(32) 0 rem(24);
}

.consumer-breed-search {
    background: $very-light-gray;

    .conditions-search,
    .featured-list,
    .got-questions-block {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
}


.featured-list {

    .popular-breed {
        padding: 0.2rem 2rem;
    }
}

#breeds-list-autocomplete {

    &,
    .popular-item {
        padding: 0;
    }

    .breed-list-group {

        &__items {
            position: static !important;
            width: 100% !important;
            display: block;
            padding: 1rem 0.5rem;
            margin-top: 0.5rem;
        }

        &__item-wrapper {
            padding: 0;
            border: none;
        }

        &__item {
            border-bottom: 1px solid #676767;
            padding: 1rem;

            &.ui-state-active {
                background: $very-light-gray;
            }
        }

    }


}
