/* Top banner section */

.section-banner .section-bg {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 0;
}

.section-banner .section-bg .has-ribbon {
    position: relative;
}

.section-banner .section-bg .banner-ribbon {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.section-banner .section-bg .image-columns {
    height: 100%;
}

.section-banner .section-bg .image-columns .column-left {
    position: relative;
}

.section-banner .hero h1 {
    font-weight: 800;
}

@media screen and (min-width: 40em) {

    .section-banner .hero {
        height: 412px;
    }

    .section-banner h1.title {
        text-align: center;
        font-size: 50px;
    }

    .section-banner .section-bg .image-columns .column-left > img {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        margin: 0;
    }

    .section-banner .hero h1 {
        display: block;
        width: 100%;
        position: relative;
        margin: 0;
    }
}
