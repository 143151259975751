$blockquote-muted-text: #585858;

.article {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    font-family: $font-family;

    &__body {
        max-width: 600px;
        width: 100%;

        @include breakpoint(xlarge) {
            max-width: 800px;
        }

        @include breakpoint(medium down) {
            padding: 0 1rem;
        }
    }

    &__content {

        p {

            @include breakpoint(xlarge) {
                font-size: rem(20px);
                margin-bottom: rem(30px);
            }
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            font-weight: 600;
        }

        img,
        .elementor img {
            width: 100%;
            border-radius: 14px;
        }

        ul {

            li {
                margin-bottom: rem(10px);

                @include breakpoint(xlarge) {
                    font-size: rem(20px);
                }

            }
        }

        blockquote {

            &,
            p {
                color: $blockquote-muted-text;
            }
        }

        .entry-content-asset {
            position: relative;
            padding-bottom: 56.25%;

            iframe {
                border-radius: 24px;
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
            }
        }

    }
}

.related-articles {
    padding: 2rem 1rem;

    &__heading {
        text-align: center;
        margin: 2rem 0 1rem;
    }

    .article-card {
        padding: 0 1rem;
    }
}
