/** START Accordions Style **/

a.accordion-title {

    @include no-text-decoration;

    .faq-question {
        margin-bottom: 0;
    }
}

.lists.accordion {

    .accordion-title::before {
        content: "\203A";
        font-size: 50px;
        margin-top: -1.75rem;
        transition: all ease 0.5s;
    }

    .is-active .accordion-title::before {
        transform: rotate(90deg);
    }

    .accordion-content {
        border: 0;
    }

    .accordion-title {
        border: 0;
        padding: 1rem 2rem 1rem 2rem;

        h1,
        h2,
        h3,
        h4,
        h5 {
            margin-bottom: 0;
        }

        h5 {
            width: 85%;
        }
    }

    li {
        border-bottom: 1px solid #e6e6e6;
    }

}

.health-list {

    .accordion-content {
        padding-left: 2rem;
    }

    .subdisorder-name {
        font-style: italic;
    }

    .accordion-content .disorder:not(:last-child) {
        border-bottom: 1px solid #e6e6e6;
        margin-bottom: 1rem;
    }
}

@media print, screen and (max-width: 40em) {

    .health-list .accordion .accordion-content,
    .health-list .accordion .accordion-title {
        padding-left: 1rem;
    }

}

/** END Accordions Style **/
