.database-health {

    .health-list-items {
        display: grid;
        grid-template-columns: 1fr;

        @include breakpoint(medium) {
            grid-template-columns: 1fr 1fr;
            column-gap: 10px;
        }
    }
}
