.market-dialog {
    max-width: 500px;
    background: #fff;
    padding: rem(24px);
    width: 95%;
    position: fixed;
    bottom: 80px;
    left: 10px;
    border-radius: 8px;
    box-shadow: 0 0 20px rgb(0 0 0 / 10%);
    animation: slideInLeft ease 500ms forwards;
    display: none;
    z-index: 99;

    &.show {
        display: block;
    }

    button {
        position: absolute;
        top: 5px;
        right: 5px;
        padding: 5px;
        border-radius: 100%;
        width: 44px;
        height: 44px;
        color: $gray;
        background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzNDguMzMzIiBoZWlnaHQ9IjM0OC4zMzMiIHZpZXdCb3g9IjAgMCAzNDguMzMzIDM0OC4zMzQiPjxwYXRoIGZpbGw9IiM1NjU2NTYiIGQ9Ik0zMzYuNTU5IDY4LjYxMUwyMzEuMDE2IDE3NC4xNjVsMTA1LjU0MyAxMDUuNTQ5YzE1LjY5OSAxNS43MDUgMTUuNjk5IDQxLjE0NSAwIDU2Ljg1LTcuODQ0IDcuODQ0LTE4LjEyOCAxMS43NjktMjguNDA3IDExLjc2OS0xMC4yOTYgMC0yMC41ODEtMy45MTktMjguNDE5LTExLjc2OUwxNzQuMTY3IDIzMS4wMDMgNjguNjA5IDMzNi41NjNjLTcuODQzIDcuODQ0LTE4LjEyOCAxMS43NjktMjguNDE2IDExLjc2OS0xMC4yODUgMC0yMC41NjMtMy45MTktMjguNDEzLTExLjc2OS0xNS42OTktMTUuNjk4LTE1LjY5OS00MS4xMzkgMC01Ni44NWwxMDUuNTQtMTA1LjU0OUwxMS43NzQgNjguNjExYy0xNS42OTktMTUuNjk5LTE1LjY5OS00MS4xNDUgMC01Ni44NDQgMTUuNjk2LTE1LjY4NyA0MS4xMjctMTUuNjg3IDU2LjgyOSAwbDEwNS41NjMgMTA1LjU1NEwyNzkuNzIxIDExLjc2N2MxNS43MDUtMTUuNjg3IDQxLjEzOS0xNS42ODcgNTYuODMyIDAgMTUuNzA1IDE1LjY5OSAxNS43MDUgNDEuMTQ1LjAwNiA1Ni44NDR6Ii8+PC9zdmc+) no-repeat center center;
        background-size: 12px;
    }

    span {
        padding-right: rem(8px);
    }
}

@keyframes slideInLeft {

    from {
        transform: translateX(-100%);
    }

    to {
        transform: translateX(0);
    }
}
