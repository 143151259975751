@mixin float($direction, $width: 40%, $min-width: 430px) {

    @if not ($direction == left or $direction == right) {

        @error "Unkown float direction provided: '#{$direction}'";

    }

    float: $direction;
    width: $width;
    min-width: $min-width;
}

$spacer-margins: (1,2);

@each $space in $spacer-margins {
    .margin--top-#{$space} {
        margin-top: #{$space}rem;
    }
    .margin--bottom-#{$space} {
        margin-bottom: #{$space}rem;
    }
}
