.featured-list {

    .list-legend {

        p {

            font-size: rem(12);
            margin-bottom: rem(8);
            margin-right: rem(4);
            text-align: center;

            @include breakpoint(medium) {

                margin-right: 0;
                text-align: right;
            }
        }

        img.logo-marker {

            max-width: 25px;
            max-height: 25px;
        }
    }

    .featured-list-button-container {

        @include breakpoint(medium) {
            text-align: center;
        }
    }

    .popular-list-container {

        background: $white;
        border: 1px solid $gray;
        border-radius: 10px;
        margin-bottom: rem(32);
        padding-left: rem(16);
        padding-right: rem(16);

        .popular-item {

            border-top: 1px solid $gray;
            padding: 1rem;

            &:first-child,
            &:not(.hide) {

                border-top: none;
            }

            &:not(.hide) ~ .popular-item {

                border-top: 1px solid $gray;
            }

            a {
                color: inherit;
                text-decoration: none;
                align-items: center;
            }

            h3,
            h4 {
                margin-bottom: 0;
            }

            .chevron {
                font-size: rem(48);
                margin-top: 0;
                padding-left: 10px;
            }

            .logo-marker {
                height: 32px;
                width: 32px;
            }
        }

        .breed-image {

            background: $light-gray;
            border-radius: 50px;
            margin-right: 10px;
            width: 48px;
            height: 48px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .breed-name,
        .condition-name {

            flex: 1 1 0;
        }
    }

    &.breed-list {

        .popular-list-container {

            .popular-item {

                &:nth-child(2) {

                    @include breakpoint(medium) {

                        border-top: none;
                    }
                }

                &:nth-child(odd) {

                    @include breakpoint(medium) {

                        border-right: 1px solid $gray;
                    }
                }
            }
        }
    }
}
