.ui-product {
    flex-basis: 100%;

    a {
        text-decoration: none;
        color: inherit;
    }

    &__image {
        overflow: hidden;
        border-radius: 8px;
        position: relative;
        padding: 0.4rem 0;

        .badge {
            position: absolute;
            top: 14px;
            left: 14px;
            padding: 4px 8px;
            line-height: 1;
        }

    }

    &__description {
        padding-top: rem(4px);
        font-size: rem(14px);
    }


    @include breakpoint(small only) {
        min-height: unset;

        &__description {
            display: none;
        }
    }

    &__image,
    &__info {

        .ui-product__wrapping_link {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }

    &__title-and-price {
        display: flex;
        flex-direction: column;
        gap: 6px;
        align-items: flex-start;

        @include breakpoint(medium) {
            gap: 12px;
        }
    }

    &__info {
        padding: 0;

        h2.ui-product__title {
            margin-bottom: 0;
        }

        h3,
        h4 {
            margin-bottom: unset;
        }

        h3 {
            font-size: rem(16px);
            margin-bottom: unset;

            @include breakpoint(medium) {
                font-size: rem(20px);
            }
        }

        p {
            font-size: 1rem;

            &.ui-product__price--actual {
                font-weight: 500;
                color: $gray;
            }

            &.ui-product__price--discounted {
                font-weight: 600;
            }
        }

        .ui-product__wrapping_link {
            padding-top: 8px;

            @include breakpoint(medium) {
                padding-top: 16px;
                padding-bottom: 16px;
            }
        }
    }

    .ui-product__image {
        background: var(--card-theme-color);
    }

    &__price {
        display: grid;
        grid-template-columns: auto 1fr;
        grid-gap: 8px;
    }

    &--tall {

        @include breakpoint(medium) {
            background: var(--card-theme-color);
            border-radius: 8px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }


        .ui-product__image {

            @include breakpoint(medium) {
                display: flex;
                justify-content: center;
            }
        }

        img {
            aspect-ratio: 4/3;
            translate: 0 20px 0;

            @include breakpoint(medium) {
                aspect-ratio: unset;
                width: 130px;
                translate: none;
            }
        }

        .ui-product__title-and-price {
            gap: 0;
        }

        .ui-product__title,
        .ui-product__description {
            width: 100%;
            max-width: 230px;

            @include breakpoint(medium) {
                text-align: center;
            }
        }
    }
}
