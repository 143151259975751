.training-corner {

    .diva-hero .container {
        justify-content: left;
    }

    .diva-hero--content + .diva-hero--image {
        width: 50%;
        align-self: flex-start;
        margin: rem(16) 0 0;
    }
}

.training-corner-2 .section-media-object[style="background-color: #fdf9ee"] {

    @include breakpoint(medium) {

        background-image: url("https://embarkvet.com/wp-content/uploads/2020/05/Promo_BG_large-1-scaled.jpg");
        background-size: cover;
        width: 100%;
        max-width: calc(100% - 8rem);
        border-radius: rem(8);
        margin: 0 rem(64);
    }
}
