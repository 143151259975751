.isDisclaimerAccordion {

    .health-header & {

        @include breakpoint(medium down) {
            margin: rem(12) 0 rem(12) rem(6);
        }

        .accordion-title,
        .accordion-content {

            @include breakpoint( large ) {
                padding: 0 rem(16) rem(16) 0;
            }
        }

        .accordion-title {

            @include breakpoint(medium down) {
                padding: 0;
                text-align: left;
            }

        }

        .accordion-content {
            color: $white;
            padding-left: 0;
        }
    }

    a {

        @extend .link--dotted--green;
    }

    &,
    .accordion-item.is_active,
    .accordion-title,
    .accordion-content {
        background: none;
        border: none;
    }

    .accordion-title {

        text-decoration: underline dotted;

        &::before,
        .is-active > &::before {
            content: unset;
        }

        &::after {
            content: $spaced_plus_sign;
        }

        &:hover,
        &:focus {
            background: none;
            text-decoration: underline solid;
        }
    }

    .is-active > .accordion-title::after {
        content: $spaced_minus_sign;
    }

    :last-child > .accordion-content:last-child,
    :last-child:not(.is-active) > .accordion-title {
        border-bottom: 0;
    }

}

.disclaimerDescriptionText {
    color: $gray;

    @extend small;

    p {
        margin-bottom: rem(8);
    }

    .health-list & {
        padding: 0 2rem;
    }

}

.disclaimerDescriptionText--header {
    text-transform: uppercase;
}
