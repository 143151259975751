/* START Hero Banner For All Pages */

.top-page-banner {
    background-color: #24262a;
    margin-top: 0;
}

.top-page-banner h1.title,
.top-page-banner .sub-title {
    color: $white;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.top-page-banner img.hero-category--image {
    width: 100%;
    height: auto;
}

@media print,
    screen and (min-width: 40em) {

    .top-page-banner img.hero-category--image {
        width: 100%;
        height: auto;
    }

    .top-page-banner h1.title,
    .top-page-banner .sub-title {
        margin-top: 0;
    }
}

/* END Hero Banner For All Pages */
