
.breed-reveal__slides {

    @include breakpoint(small only) {
        width: 100vw;

        &--slider {
            overflow: hidden;
        }
    }

    > h2 {

        @include breakpoint(medium) {
            margin-bottom: rem(48px);
        }
    }

    &--card-stack {
        position: relative;
        min-height: 350px;
        display: flex;
        justify-content: center;

        @include breakpoint(small only) {
            margin: 1rem 0 3rem;
        }
    }

    blockquote {
        padding-left: 0;
        border-left: none;
        color: #0a0a0a;
        text-align: center;
        font-feature-settings: "clig" off, "liga" off;
        font-style: normal;
        font-weight: 600;
        line-height: 31.98px;
        letter-spacing: 0.039px;

        cite {
            color: $black;
            font-size: unset;
        }
    }

    button {
        padding: 0 18px;
        min-height: 44px;
    }

    .swiper {

        @include breakpoint(medium) {
            padding: 0 56px 200px;
        }

        &__buttons {
            position: relative;
            display: flex;
            min-height: 44px;
            max-width: 140px;
            align-items: center;
            justify-content: center;
            margin-left: auto;
            margin-right: auto;
        }

        &-slide {
            text-align: center;
        }

        &-slide:not(.swiper-slide-active) {
            visibility: hidden;
        }

        &-button-next::after,
        &-button-prev::after {
            font-size: rem(16px);
        }

        &-button-prev,
        &-container-rtl,
        &-button-next {
            background-image: none;
        }

        &-pagination-bullet {
            margin: 0 4px;
            width: 8px;
            height: 8px;
        }
    }

    .breed-card {
        width: 200px;
        height: 300px;
        border: 2px solid;
        border-radius: 12px;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background: $soft-yellow;
        position: absolute;

        @include breakpoint(small only) {
            top: 20px;
        }

        &:nth-child(1) {
            z-index: 5;
            transform: rotate(-10deg) translate(0, 25px);
        }

        &:nth-child(2) {
            z-index: 4;
            transform: rotate(5deg) translate(85px, 0);
        }

        &:nth-child(3) {
            z-index: 3;
            transform: rotate(20deg) translate(170px, -25px);
        }

        img {
            max-width: 100px;
            margin: 0 auto 24px;
        }
    }

}
