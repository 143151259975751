.product-details-drawer {

    div:not(.drawer__header) {
        margin-bottom: 0.5rem;
    }

    .headline4 {
        margin-bottom: 0;
    }

    .headline6 {
        margin-bottom: 0.5rem;
    }

    .product-ratings {

        .product-rating {
            display: flex;
            padding: 8px;
            align-items: flex-start;
            gap: 4px;
            width: fit-content;
            border-radius: 4px;
            background: #f5f5f5;

            .star-rating {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
            }
        }
    }

    .product-image {

        img {
            height: 144px;
            width: auto;
            margin: 0.5rem 0;
        }
    }

    .product-pills {

        .product-pill {
            background: rgba(252, 195, 0, 0.3);
            padding: 0 0.5rem;
            border-radius: 64px;
        }
    }

    .shop-buttons {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}
