@import "../../components/product-card";

.conclusion-block {

    &__products {

        display: flex;
        gap: 20px;

        @include breakpoint(small only) {
            flex-direction: column;

            //! adding only because of elementor. Delete on cleanup
            .ui-product h3 {
                font-size: rem(16px);
            }
        }

        .ui-product__description {
            display: block;
        }

        //! adding because of elementor. Delete on cleanup
        .ui-product h4 {
            font-size: rem(16px);
        }

        .ui-product h3 {
            font-size: rem(20px);
        }
    }

    &__copy,
    &__image {
        width: 50%;

        @include breakpoint (small only) {
            width: unset;
        }
    }

    &__copy {
        border-radius: 0 24px 24px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 30px 30px 30px 50px;

        a {
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }

        @include breakpoint (small only) {
            padding: 30px 40px;
            border-radius: 0 0 24px 24px;
        }

        //! adding because of elementor. Delete on cleanup
        h2.headline5 {
            font-size: rem(20px);
        }

        .copy-block {
            max-width: 420px;

            &:not(:last-child) {
                border-bottom: 1px solid #ccc;
                padding-bottom: 10px;
            }

            &:not(:first-child) {
                padding-top: 28px;
            }
        }
    }

    &__image {
        display: flex;
        align-items: stretch;

        img {
            object-fit: cover;
        }
    }
}
