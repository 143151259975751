.camp-embark {

    .button-full-width {

        @include breakpoint(medium) {
            display: inline-block;
            width: auto;
            padding: 1rem 1.5rem 1.0625rem;
        }
    }

    .sectionCardGrid--overviewParagraph {

        @include breakpoint(medium) {
            margin: 0 auto;
            text-align: center;
        }
    }

    .sectionCardGrid--article {

        background: transparent !important;
    }

    .sectionCardGrid--articleQuote.h4 {

        line-height: 1.25;
        text-align: center;
    }

    .grid-y {

        .custom-block-container:nth-child(3) {

            @include breakpoint(large) {
                background: -webkit-linear-gradient(top, $white 0%, $white 50%, #b5cfce 50%, #b5cfce 50%, #b5cfce 100%);
            }
        }

        > *:first-child .section-media-object {

            &:nth-child(1) {

                @include breakpoint(large) {
                    max-width: 64rem;
                    margin: 0 auto;
                }
            }

            .medium-5 {

                @include breakpoint(large) {
                    width: 30%;
                }
            }

            .medium-6 {

                @include breakpoint(large) {
                    width: 70%;
                    margin-top: 2rem;
                    padding-right: 3rem;
                }
            }
        }
    }

    .diva-hero {

        .container {

            @include breakpoint(small only) {

                justify-content: left;
            }
        }

        &--image-dog {

            @include breakpoint(medium) {

                max-height: 20rem;
                margin: -1rem auto 1rem;
            }

            @include breakpoint(large) {

                max-height: 22rem;
            }
        }

        > .grid-x {

            @include breakpoint(small only) {

                background-size: 110% !important;
                width: 100%;
                max-width: 68rem;
                margin: 0 auto;
            }
        }
    }

    .section-media-object {

        &[style="background-color: #fdf9ee"] {

            @include breakpoint(medium) {
                background-image: url("https://embarkvet.com/wp-content/uploads/2020/05/Promo_BG_large-1-scaled.jpg");
                background-size: cover;
                width: 100%;
                max-width: calc(100% - 8rem);
                border-radius: 0.5rem;
                margin: 0 4rem;
            }

            @include breakpoint(large) {
                max-width: 64rem;
                margin: 1rem auto 0;
                border-radius: 0.5rem;
            }
        }

        &:last-child .section-media-object .medium-6,
        &:nth-last-child(2) .section-media-object .medium-6 {

            @include breakpoint(medium) {
                width: 100% !important;
            }
        }
    }
}
