.sectionVideoGrid {

    .orbit-previous,
    .orbit-next {
        color: $black;
    }

    &-videoCell {

        @include xy-gutters($grid-margin-gutters, "margin", "bottom", false);
        position: relative;

        &Overlay {
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
        }

        &Thumb {
            min-width: 250px;
            min-height: 250px;
        }
    }
}
