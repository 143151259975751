section.grid-container.full.get-started-sidebar {
    background-color: #040404;
}

section.grid-container.get-started-sidebar {
    background-color: #0a0a0a;
    color: $white;
    padding-bottom: 0;
    padding-top: 0;
    margin-top: 3rem;

    h1 {
        color: $white;
    }
}

section.get-started-sidebar .get-started-dog-bg {
    background: url("../img/breeders/get-started-img.jpg") bottom right no-repeat;
    background-size: contain;
    height: 400px;
}

section.get-started-sidebar .get-started-dog-bg > .grid-x {
    height: 100%;
}

section.get-started-sidebar .get-started-dog-bg .get-started-cta {
    margin-top: 2rem;
}

@media screen and (max-width: 39.9375em) {

    section.get-started-sidebar h1 {
        text-transform: capitalize;
    }
}

/* Medium only */
@media screen and (min-width: 40em) and (max-width: 63.9375em) {

    section.get-started-sidebar .get-started-dog-bg {
        height: 250px;
    }
}

/* Large and up */
@media screen and (min-width: 64em) {

    section.get-started-sidebar .get-started-dog-bg .get-started-cta {
        margin-top: 6rem;
    }
}
