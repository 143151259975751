.hero-category {

    &.no-image &__grid {
        min-height: 10em;
        padding: 2.5em 0;

    }

    &__cta {
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
    }

    position: relative;

    &--extra-section {
        margin-top: 32px;

        @include breakpoint(large) {

            text-align: center;
        }

        ul {
            display: flex;
            margin-left: 0;
            list-style: none;
            flex-direction: column;

            @include breakpoint(large) {
                flex-direction: row;
            }

            li {
                flex-grow: 1;
                padding-right: rem(8px);

                line-height: 44px;

                @include breakpoint(large) {
                    line-height: revert;
                }
            }
        }
    }
}

.hero-category.category-blog .hero-text {
    color: $black;
}

.hero-category.category-resources .hero-text {
    color: $white;
}

.hero-category.category-resources .hero-text .diva-hero--headline {
    letter-spacing: initial;
}

.category-resources .hero-category--bg {
    background-color: #413c39;
}

.category-blog .hero-category--bg {
    background-color: $purple-dark;

    .hero-text {
        color: $white;
    }
}


.category-blog img.hero-category--image {
    position: absolute;
    top: 0;
    right: 0;
    width: 600px;
}

.breeder-partnership .section-banner img.hero-category--image,
.category-resources img.hero-category--image {
    position: absolute;
    bottom: 0;
}

.breeder-partnership .section-banner img.hero-category--image,
.published-research .section-banner img.hero-category--image {
    height: 100%;
    width: auto;
}

.breeder-page  .hero-category--info {
    z-index: 1;
}

.hero-category--info {
    z-index: 10;
    position: relative;
    height: 380px;
    display: flex;
    align-items: center;
}

.hero-category .diva-hero--headline {
    text-transform: uppercase;
    letter-spacing: 4.17px;
    font-weight: 800;
}

.hero-category .diva-hero {
    height: 100%;
    position: relative;
    align-items: center;
}

.hero-category .diva-hero--sub {
    font-weight: 300;
    text-transform: none;
}

.hero-category .hero-text {
    width: 100%;
}

.hero-category .hero-text > h1,
.hero-category .hero-text > h2 {
    margin: 0;
}

@media screen and (min-width: 40em) {

    .hero-category .diva-hero--sub {
        font-size: 24px;
    }

    .category-resources img.hero-category--image {
        right: 0;
        width: 500px;
    }

    .breeder-partnership .section-banner img.hero-category--image {
        right: 15%;
        width: auto;
    }

    .category-blog img.hero-category--image {
        width: 45%;
        top: auto;
        bottom: 0;
    }
}


@media screen and (min-width: 75em) {

    .hero-category .diva-hero--headline {
        font-size: 50px;
    }

    .breeder-partnership .section-banner img.hero-category--image,
    .category-resources img.hero-category--image {
        right: 20%;
    }

    .category-blog img.hero-category--image {
        width: 600px;
    }
}

@media screen and (max-width: 39.9375em) {

    .hero-category {
        margin-top: 0;
        height: auto;
    }

    .hero-category--bg {
        position: relative;
    }

    .hero-category.category-resources {
        background-color: #413c39;
        overflow: hidden;
    }

    .hero-category.category-blog {
        background-color: #58c2ea;
    }

    .hero-category .hero-category--info {
        height: auto;
        margin: 0;
        padding-top: 12px;
        transform: none;
    }

    img.hero-category--image {
        position: relative;
        width: 100%;
        display: block;
        height: auto;
    }

    .breeder-partnership .section-banner img.hero-category--image {
        width: auto;
        max-height: 350px;
        margin: 0 auto;
        position: relative;
    }

    .category-blog img.hero-category--image,
    .category-resources img.hero-category--image {
        display: block;
        position: relative;
        width: 100%;
        bottom: 0;
        right: 0;
    }

    .hero.scale-up-on-small {
        overflow: hidden;
        padding-bottom: 40px;
    }
}
