
@import "../buttons";
@import "../wavy-divider";

.why-embark {

    .badge {
        border: 1px solid #000;
        background-color: transparent;
    }

    img {
        width: 100%;
        height: auto;
    }

    .tabs {
        padding: 48px 0;


        @include breakpoint(medium) {
            padding: 72px 0;
        }

        @include breakpoint(large) {
            padding: 96px 0;
        }

        @include breakpoint(medium down) {
            padding-left: 28px;
        }
    }

    &__header,
    &__how-it-started,
    &__discoveries,
    &__professionals,
    &__numbers {
        padding-top: 48px;
        padding-bottom: 48px;

        @include breakpoint(medium) {
            padding: 100px 72px;
        }

        @include breakpoint(large) {
            padding: 100px 10%;
        }
    }

    .testimonials__header h2,
    .discoveries__header h2 {
        max-width: 720px;
    }

    &__header {
        background-color: $yellow;

        p {
            max-width: 630px;
        }
    }

    &__nav {
        margin-top: 32px;

        .pill-nav {
            display: flex;
            gap: 16px;
            overflow: scroll;
            -ms-overflow-style: none; /* for Internet Explorer, Edge */
            scrollbar-width: none; /* for Firefox */
            padding: 8px 20px 0 20px;
            margin-bottom: 1rem;

            &::-webkit-scrollbar {
                display: none; /* for Chrome, Safari, and Opera */
            }

            a {
                flex-shrink: 0;

                @include refresh-button-secondary;

                svg {
                    margin-right: 12px;
                }
            }
        }
    }

    &__how-it-started {


        h2 {
            margin-top: 20px;
        }

        .image-wrapper {
            margin-bottom: 16px;

            .accent-image {
                display: inline-block;
                width: 135px;
                height: 14px;
                background-image: url(https://res.cloudinary.com/embark/images/v1668096505/embarkvet.com/uploads/squig/squig.svg?_i=AA);
            }
        }

        @include breakpoint(large) {
            padding: 100px 10% 0;

            .grid-container-1400 {
                display: flex;
                gap: 100px;
            }

            h2,
            p {
                max-width: 752px;
            }

            col--right {
                padding-top: 88px;
            }
        }

        figure {
            margin-top: 32px;
            margin-bottom: 36px;

            figcaption {
                margin-top: 8px;
                font-size: rem(14px);
            }
        }

        a {
            text-decoration: none;

            svg {
                margin-left: 8px;
            }
        }
    }

    &__discoveries {
        background-color: $soft-yellow;

        h2 {
            margin: 24px 0 40px;
            max-width: 450px;
        }

        .discoveries__list {
            margin: 32px auto;
            margin-top: auto;
            margin-bottom: auto;

            p {
                padding-left: 24px;
                position: relative;
                margin-bottom: 24px;

                svg.paw {
                    position: absolute;
                    top: 8px;
                    left: 0;
                }
            }

            a {
                text-decoration: none;
            }

            @include breakpoint(medium) {
                margin-right: -32px;
            }

        }

        .discoveries__cta {
            margin-top: 40px;

            a {
                text-decoration: underline;
            }
        }

        @include breakpoint(medium) {

            .grid-container-1400 {
                padding-top: 32px;
            }
        }
    }

    &__testimonials {
        padding-top: 48px;
        padding-bottom: 48px;

        h2 {
            margin: 24px auto 20px auto;
        }

        @include breakpoint(medium) {
            padding-top: 72px;
            padding-bottom: 72px;
        }

        @include breakpoint(large) {
            padding-top: 100px;
            padding-bottom: 120px;
        }

        .magazine-testimonials {
            padding-top: 40px;
            display: grid;
            grid-template-columns: 1fr;

            @include breakpoint(medium) {
                padding: 40px;
                grid-template-columns: repeat(3, 1fr);
                gap: 30px;
            }

            @include breakpoint(large) {
                padding: 60px;
                gap: 50px;
            }

            img {
                width: unset;
                height: 24px;
            }

            .magazine-testimonial:not(:first-child) {
                margin-top: 60px;

                p {
                    margin-bottom: 16px;
                }

                @include breakpoint(medium) {
                    margin-top: 0;
                }
            }

            p {
                margin-top: 24px;
            }

            a {
                text-decoration: none;
            }
        }

        .tabs {
            padding-top: 0;
            border: none;
            display: flex;
            gap: 16px;
            overflow: scroll;
            -ms-overflow-style: none; /* for Internet Explorer, Edge */
            scrollbar-width: none; /* for Firefox */

            @include breakpoint(large) {
                justify-content: center;
            }

            &::-webkit-scrollbar {
                display: none; /* for Chrome, Safari, and Opera */
            }

            background: linear-gradient(180deg, transparent 0%, transparent 60%, #ffe8a6 60%, #ffe8a6 100%);
        }

        .tabs-content {
            border: none;
            background-color: #ffe8a6;
            padding-bottom: 60px;
            margin-top: -140px;

            @include breakpoint(large) {
                padding-left: 56px;
                padding-right: 56px;
                border-radius: 24px;
                margin-top: -170px;
            }

            h3 {
                margin-top: 24px;
            }

            .testimonial--image {
                z-index: 1;

                img {
                    border-radius: 16px 16px 16px 4px;
                }

                @include breakpoint(large) {

                    @include xy-gutters($gutter-type: padding, $gutter-position: right, $gutters: 50px);
                }
            }

            .tabs-panel {
                padding: 130px 0 0 0;

                > div {
                    align-items: center;
                    justify-content: center;
                }
            }

            .testimonial--image img,
            .testimonial--text h3,
            .testimonial--text p {
                max-width: 660px;
                display: block;
                margin-left: auto;
                margin-right: auto;
            }

            .testimonial--text {

                @include breakpoint(large) {

                    @include xy-gutters($gutter-type: padding, $gutter-position: left, $gutters: 50px);
                }

                a {
                    font-weight: 600;
                    text-decoration: none;
                }
            }
        }

        .tabs-title {

            [data-tabs-target] {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 16px;
                text-decoration: none;
                color: $black;
                background-color: #ffce34;
                width: 170px;
                height: 206px;
                border-radius: 8px;
                font-size: 16px;

                &[aria-selected="true"] {
                    color: $black;
                    border: 2px solid #0a0a0a;
                    position: relative;

                    &::after {
                        content: "";
                        display: inline-block;
                        width: 30px;
                        height: 30px;
                        background: #ffce34;
                        position: absolute;
                        bottom: -15px;
                        transform: rotate(45deg);
                        border: 2px solid;
                        border-left-color: transparent;
                        border-top-color: transparent;
                    }

                }

                img {
                    width: 106px;
                    height: 106px;
                    object-fit: cover;
                    object-position: center;
                    border-radius: 50%;
                }
            }

            #Guthealth-label {

                img {
                    object-position: right;
                }
            }

            #Relatives-label {

                img {
                    object-position: left;
                }
            }

            #Personalizedcare-label {

                img {
                    object-position: 25%;
                }
            }

        }
    }

    &__professionals {
        background-color: $soft-yellow;
        position: relative;

        .image-wrapper {
            margin-bottom: 16px;

            .accent-image {
                display: inline-block;
                width: 135px;
                height: 14px;
                background-image: url("https://res.cloudinary.com/embark/images/v1673990159/embarkvet.com/uploads/bluesquig_596950f772/bluesquig_596950f772.svg?_i=AA");
            }
        }

        a.professional-link {
            text-decoration: none;
        }

        .image-container {
            margin: 20px 0;
        }

        .grid-x {
            align-items: center;
        }

        .col--left {

            @include breakpoint(large) {

                @include xy-gutters($gutter-type: padding, $gutter-position: right, $gutters: 40px);
            }
        }

        .col--right {

            @include breakpoint(large) {

                @include xy-gutters($gutter-type: padding, $gutter-position: left, $gutters: 40px);
            }
        }

        .get-embarked-stamp {
            width: 200px;
            height: 200px;
            position: absolute;
            top: -100px;
            right: 10%;

            img.animate {
                animation: rocking 2s 3;
            }

            @keyframes rocking {

                0%,
                50%,
                100% {
                    transform: rotate(0deg);
                }

                25% {
                    transform: rotate(-15deg);
                }

                75% {
                    transform: rotate(15deg);
                }
            }
        }

        @media print, screen and (max-width: 63.99875em) {

            .get-embarked-stamp {
                width: 150px;
                height: 150px;
                top: -75px;
            }
        }
    }

    &__numbers {
        background: $yellow;

        .numbers-header {

            h2,
            p {
                margin-left: auto;
                margin-right: auto;
            }

            h2 {
                max-width: 670px;
            }

            p {
                max-width: 768px;
            }
        }

        table {
            padding: 0;
            border-collapse: separate;
            border-radius: 24px;
            overflow: hidden;
            max-width: 880px;
            margin-left: auto;
            margin-right: auto;
            background: $soft-yellow;
            border-spacing: 0;

            &.table--science-and-tech {
                margin-top: 60px;
            }

            thead,
            tbody {
                border: none;
            }

            thead {
                background: $soft-yellow;
            }

            tr:last-child {

                td:nth-child(2) {
                    border-bottom: 2px solid $black;
                    border-radius: 0 0 8px 8px;
                }
            }

            th,
            td {
                padding: rem(16px);
                max-width: 35%;

                &:first-child,
                &:last-child {
                    width: 35%;
                }

                &:not(:first-child) {
                    text-align: center;
                }

                &:nth-child(2) {
                    border-left: 2px solid $black;
                    border-right: 2px solid $black;
                    width: 30%;
                }
            }

            td:first-child {
                font-weight: 600;
            }

            td .wrapper,
            th .wrapper {
                display: flex;
                flex-direction: column;
                gap: 3px;

                small {
                    font-weight: 400;
                    color: #626262;
                }
            }

            th:nth-child(2) {
                border-top: 2px solid $black;
                border-radius: 8px 8px 0 0;
            }

            th[scope="row"] {
                text-align: left;
            }

            tr:not(:last-child) td,
            tr:not(:last-child) th {
                border-bottom: 1px solid #ccc;
            }
        }
    }

    &__conclusion {
        position: relative;
        padding-top: 48px;
        padding-bottom: 150px;
        margin-bottom: 72px;

        @include breakpoint(medium) {
            padding-bottom: 100px;
            padding-top: 100px;
        }

        h2,
        p {
            max-width: 600px;
            margin-left: auto;
            margin-right: auto;
        }

        p {
            margin-bottom: 24px;
        }

        a {

            @include refresh-button-secondary;

            svg {
                margin-left: 8px;
            }
        }

        .new-dog-overlay {
            width: 275px;
            height: 150px;
            position: absolute;
            bottom: 50px;
            right: 10%;
            z-index: 9;
        }

        @media print, screen and (max-width: 39.99875em) {

            .new-dog-overlay {
                right: 50%;
                transform: translateX(50%);
                bottom: 0;
            }
        }
    }
}

.has-divider {
    padding-top: 83px;
    padding-bottom: 83px;
}
