.ui-autocomplete {
    border: 2px solid $purple;
    margin: 0;
    list-style: none;
    background: $white;

    &-input {
        padding-left: 20px;
    }

    .ui-state-active {
        background: transparentize($purple, 0.5);
        cursor: pointer;
    }

    .ui-menu-item-wrapper {
        padding: 7px 20px;
    }

    .autocomplete-list-header {

        pointer-events: none;
        color: $gray;
        font-weight: 600;
        display: block;
        padding: 7px 20px 0 20px;

        &::after {
            content: "";
            border-bottom: 2px solid $gray;
            display: block;
            width: 95%;
            margin: rem(10) 0 auto;
            padding-top: 0;
        }
    }
}

.widget--breed-health-search {

    #breeds-list,
    #health-list {
        height: 42px;
    }

    .breed-search-form {

        display: flex;
        flex-flow: row wrap;


        &__input {
            display: inline-block;
            flex-grow: 1;
            margin-right: 0;
            margin-bottom: 1rem;

            @include breakpoint(large up) {
                margin-right: 0.5rem;
                margin-bottom: 0;
            }

            @include breakpoint(large only) {
                margin-right: 0;
                margin-bottom: 1rem;
            }

            @include breakpoint(medium only) {
                margin-right: 0.5rem;
                margin-bottom: 0;
            }

            @include breakpoint(small only) {
                flex-basis: 100%;
            }
        }

        &__search-buttons {
            display: inline-block;
            margin-top: -2px;
        }
    }

    .breed-health-results {
        margin-top: 2rem;
    }

    .a-underlined {
        text-decoration: underline;
    }

    .disease {

        &__icons {
            margin: 0 10px;

            @include breakpoint(small only) {
                margin: 0;

                img {
                    width: 25px;
                }
            }
        }

        .embark-logo {
            margin-right: 6px;
        }

        &__citations {
            color: #0a0a0a;
            text-decoration: underline;
            cursor: pointer;
        }
    }

    .breed-health-list {
        margin-top: 1.5em;
    }

    .accordion {
        border-radius: 5px;
        border: 1px solid #e6e6e6;
        overflow: hidden;

        .accordion-title {
            display: flex;
            color: #0a0a0a;
            border: none;
            padding: 10px 10px;

            h5 {
                margin-bottom: 0;
                margin-top: rem(4px);
            }

            @at-root .accordion-item:not(:first-child) .accordion-title {
                border-top: 1px solid #e6e6e6;
            }

            &::before {
                content: "\203A";
                font-size: 50px;
                transition: all ease 0.5s;
                top: 5px;
                color: #7a7e83;
            }
        }

        .is-active .accordion-title::before {
            transform: rotate(90deg);
        }

        .accordion-content {
            border: none;
        }

    }

    .breed-suggestions {
        list-style: none;
        margin: 0;
    }

    .logos-description {

        @include breakpoint(medium down) {
            margin-bottom: 3rem;
        }

        .embark-logo,
        .ofa-logo {
            width: 20px;
        }
    }

}
