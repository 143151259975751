.page-template-temp-refresh-reviews {

    .blockTestimonials {
        background: $white;
        margin-top: 0;
        padding: 1.5rem 1rem;

        &__copy {
            max-width: 500px;
            margin: 0 auto 3rem;
        }
    }

    .conclusion-block__products {
        display: grid;
        grid-gap: 20px;
        grid-template-columns: 1fr 1fr;

        @include breakpoint(medium) {
            width: 80%;
            margin: 0 auto;
        }

        @include breakpoint(large) {
            width: 100%;
            display: flex;
        }
    }

}
