/*•• AURAL ••*/

@media speech {

    .a11y-phone {
        speak-as: digits;
    }

}

.a11y-email {
    speak: never;
    speak-as: spell-out;
}

.bypass-block-container {
    background: lighten($blue, 20%);
    padding: 20px 15px 25px;
    position: fixed;
    transition: all 0.3s;
    z-index: 10;
    left: 0;
    top: -500px;
    max-width: 75%;

    @include breakpoint(medium) {
        max-width: 25%;
    }

    &:focus-within {
        top: 0;

        .logged-in.admin-bar & {
            top: 46px;

            @include breakpoint(large) {
                top: 32px;
            }
        }
    }

    .skip-link {
        display: block;
        padding: 5px;

        &:focus,
        &:hover {
            color: #000;
        }
    }

}

@media (prefers-reduced-motion) {

    .bypass-block-container {
        transition: none;
    }
}
