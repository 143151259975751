.section-products {
    padding: 2rem 0 3rem;
}

.section-product-blocks {
    max-width: 75rem;
    margin: 2rem auto;
}

.best-value-label {
    font-weight: 700;
    color: $white;
    border-radius: 0 0 0 0.75rem;
    width: 8rem;
    margin-left: auto;
    margin-right: 0;
    min-height: 1rem;
}

.block-product {
    background-color: $white;
    border-radius: 0.5rem;
    border: 1px solid #767676;
    max-width: 23rem;
    height: 28rem;
    min-height: 28rem;
    margin: 0 auto;
}

.block-header {
    margin-bottom: 1rem;
}

.block-product-title {
    margin: -1rem auto 0;
    width: 15rem;
}

.block-header-icon-container {
    margin-top: -2rem;
    width: 6rem;
}

.block-header-icon-container img {
    width: 64px;
}

.block-product-separator {
    width: 13rem;
}

.bullet-icon {
    width: 24px;
    height: 20px;
}

.bullet-text {
    padding-left: 0.5rem;
    font-weight: 700;
}

.feature-bullets {
    margin-left: 1.5rem;
}

.bullet-container {
    padding: 0 0 0.5rem 0;
}

.block-product-footer {

    h3 {
        display: flex;
        align-items: baseline;
        justify-content: center;

        .product-price-strikethrough {
            font-size: 1.2rem;
            text-decoration: line-through;
            color: $gray;
            margin-left: 0.25rem;
        }
    }
}

@media screen and (max-width: 64em) {

    .block-product-container {
        padding: 1rem 0;
    }

    .block-header-icon-container {
        margin: 0 0 0 1rem;
    }

    .section-products {
        padding: 2rem 1rem 3rem;
    }

    .block-product {
        height: auto;
        min-height: auto;
    }

    .block-product-footer {
        padding: 1rem 0 2rem;
    }

    .block-product-title {
        margin: 0;
    }
}
