.breed-reveal {

    &__hero {

        &__root-grid {

            @include breakpoint(medium) {
                max-height: 600px;
            }

            @include breakpoint(large) {
                max-height: 550px;
            }
        }

        .hero-copy {
            margin-top: 10%;
        }

        .breed-reveal__bubbles {
            min-height: 440px;

            @include breakpoint(small only) {

                &,
                .bubbles-grid {
                    width: 100%;
                }
            }
        }

        .bubbles-grid {
            margin: 24px -24px 0 16px;
            grid-template-rows: repeat(4, 1fr);
            height: 125%;
            position: relative;
            display: block;

            > div {
                position: absolute;
                width: 175px;
            }

            &__item {

                &-0 {
                    left: -18%;
                }

                &-1 {
                    left: 65%;
                    top: 10%;
                }

                &-2 {
                    top: 35%;
                }

                &-3 {
                    display: none;
                }

                &-4 {
                    bottom: 10%;
                    right: -9%;
                }
            }

            @include breakpoint(medium) {
                height: 100%;

                &__item {

                    &-0 {
                        left: 25%;
                    }

                    &-1 {
                        left: unset;
                        top: unset;
                        right: -39%;
                    }

                    &-2 {
                        right: 0;
                        bottom: 10%;
                        top: unset;
                    }

                    &-3 {
                        display: block;
                        left: 0;
                        top: 60%;
                    }

                    &-4 {
                        right: 0;
                        bottom: -48%;
                    }
                }

            }

            img {
                max-height: 175px;
            }

            &.large-image {

                .bubbles-grid__item-0 {

                    @include breakpoint(small only) {
                        bottom: 25%;
                    }

                }

                .bubbles-grid__item-1 {

                    @include breakpoint(small only) {
                        display: none;
                    }
                }

                .bubbles-grid__item-2 {

                    width: 300px;
                    transition: all 0.3s ease-in-out;
                    bottom: 0%;

                    @include breakpoint(small only) {
                        top: -25px;
                        right: 25px;
                    }

                    @include breakpoint(medium) {
                        right: -10%;
                    }

                    img {
                        width: 300px;
                        max-height: 300px;
                        transition: all 0.3s ease-in-out;
                    }

                }

            }
        }

        .wavy-bottom-divider svg {
            z-index: -1;
        }

    }
}
