.single-post {

    .post-content {

        // Ensures elementor based posts align the inner containers with the
        // left and right sides of the post container for consistncy.
        .elementor {
            margin-left: -10px;
            margin-right: -10px;
        }
    }
}
