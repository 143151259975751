/*
* Styles for Traits page
* Template: temp-traits.php
*/

.traits-list-nav {
    margin: 3rem auto;
}

.traits-list-nav .tabs {
    border: 0;
}

.traits-list-nav .tabs a {
    display: block;
}

.traits-list-nav .tabs li a:hover,
.traits-list-nav .tabs li a:focus,
.traits-list-nav .tabs li a:active {
    background: transparent;
}

.traits-list-nav .tabs .title {
    font-weight: 700;
    font-size: 1rem;
    margin-top: 1.25rem;
    text-transform: uppercase;
}

.traits .cta {
    margin-top: 60px;
    justify-content: space-around;
}

section.traits .cta h2 {
    margin-top: 20px;
}

.traits .cta a.button {
    color: $black;
    display: inline-block;
    padding-right: 30px;
    padding-left: 30px;
}

.traits .cta a.button.black-outline {
    background: #fff;
    box-shadow: inset 0 0 0 3px $black;
}

.section-media-object-row-icons,
.section-media-object-row-icons-responsive {
    background-color: #f9fafb;
}

.section-media-object-row-icons .section-icon {
    margin-top: -2rem;
}

.section-media-object-row-icons .cell-content,
.traits .cell-content {
    margin: 2em 0;
}

.traits .cell-content .footer-cta {
    font-weight: 400;
    padding: 0 3rem;
}

.row-icons {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-around;
    margin-top: 2rem;
}

.row-icons .offset {
    margin-top: 2rem;
}

.row-icons .icon-description {
    margin-top: 1rem;
    font-size: 1rem;
    hyphens: auto;
}

.row-icons .icon {
    width: 24%;

    a {
        display: inline-block;
        text-decoration: none;
        color: currentColor;
        vertical-align: top;
    }
}

.mirror .cell-image {
    order: 2;
}

.mirror .cell-content {
    order: 1;
}

.row-icons img {
    width: 80px;
}

.traits-list .tabs {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.traits-list .tabs li {
    border: 0;
}

.traits-list .tabs li img {
    opacity: 1;
    transition: all ease 0.2s;
}

.traits-list .trait-item-description,
.traits-list .accordion-content p {
    word-break: break-word;
}

.traits-list .trait-citations {
    font-style: normal;
    font-size: 1rem;
    color: $black;
}

.traits-list .trait-citations::before {
    content: none;
}

.traits-list .trait-category {
    margin-bottom: 1rem;

    &:focus {

        @include reset-focus-ring-to-browser-default;
    }
}

@media print, screen and (min-width: 40em) {

    .traits .tabs-content p {
        width: 60%;
        margin: 30px auto;
    }

    .traits-list .trait-category {
        margin-bottom: 3rem;
    }
}

@media screen and (min-width: 64em) {

    .traits-list-nav .tabs li img {
        opacity: 0.6;
    }

    .traits-list-nav .tabs li:hover img {
        transform: translateY(-1rem);
        opacity: 1;
    }
}

@media print, screen and (max-width: 39.9375em) {

    .traits-list-nav .tabs li {
        margin: 1rem auto;
    }
}

@media print, screen and (max-width: 40em) {

    .trait-cta .cell.cta-image {
        order: 2;
    }

    .trait-cta .cell.cell-content {
        order: 1;
    }

    .trait-cta .cta {
        margin: 0;
    }

    .section-media-object-row-icons-responsive .cell {
        padding: 0 1rem;
    }

    .row-icons img {
        width: 64px;
    }

    .section-media-object-row-icons-responsive .cell-content {
        margin: 1rem 0;
        padding: 0;
    }

    .section-media-object-row-icons-responsive .image-container .section-icon {
        margin-top: -2.5rem;
    }

    .section-media-object-row-icons-responsive .image-container {
        padding: 0;
    }

    .section-media-object-row-icons-responsive .cta .image-container {
        padding-bottom: 1rem;
    }
}
