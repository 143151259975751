/* START two column text block  */
.two-columns-text {
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.two-columns-text h2 {
    margin-bottom: 1.25rem;
}

.section-cta [class^="button-"] {
    margin: 2.5rem auto;
}

/* END two column text block  */
