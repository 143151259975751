@import "../common_configuration";
@import "../components/divider";
@import "../components/article";

.how-to-swab__instruction-steps {
    counter-reset: howToSwabInstructionStep;

    .how-to-swab__instruction-step__image {
        width: 50%;

        // The cheek swab image assets are too small so we are putting a cap
        // on the image width
        // TODO: remove this when we redesign the images
        max-width: 300px;
    }
}

.how-to-swab__instructions-step-name {
    counter-increment: howToSwabInstructionStep;

    &::before {
        content: counter(howToSwabInstructionStep) ". ";
    }
}

.how-to-swab-instructions {
    margin-top: 6rem;
}
