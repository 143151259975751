.widget--compare-block {

    @include starSvgFills(#compare-half-star-small-p1, #compare-half-star-p1, #compare-half-star-p1);
    @include starSvgFills(#compare-half-star-small-p2, #compare-half-star-p2, #compare-half-star-p2);

    .compare-block--content {

        @include breakpoint(small only) {
            max-width: 22rem;
        }
    }

    .text {

        &--pricing-values {

            font-weight: 500 !important;

            @include breakpoint(medium up) {
                font-size: rem(28px);
                margin-bottom: 1rem;
            }

            sup {

                font-size: 60%;
                top: -0.5rem;
            }

            &--sale {

                color: #ff0029;

                small {

                    text-decoration: line-through;
                    color: $gray;
                    font-size: 1rem;
                    font-weight: 500;
                }
            }
        }

    }

    .compare-block-product-img {
        margin: 1rem auto;

        @include breakpoint(medium up) {
            margin-bottom: 2rem;
        }

        img {
            max-width: 100%;

            @include breakpoint(medium up) {
                max-width: 200px;
            }
        }
    }

    .stars .star.filled polygon {
        fill: $black;
    }

    .review-count {
        font-size: 0.875rem;
        font-style: italic;
        color: #6d6c6c;

        @include breakpoint(medium) {
            display: inline-block;
            vertical-align: middle;
        }
    }

    .compare-block--headline {

        @include breakpoint(small only) {
            // matches the h2 original size.
            font-size: rem(30px);
        }

        margin-bottom: 0;

        &--medium-up {

            @include breakpoint(medium up) {
                margin-bottom: 0;
            }
        }
    }

    .compare-block--subheader {

        @include breakpoint(medium) {
            margin-bottom: 2rem;
        }
    }

    .compare-block--product-titles {
        // matches h5.
        font-size: rem(18px);

        @include breakpoint(medium up) {
            // matches h3.
            font-size: rem(28px);
            margin-bottom: 0;
        }
    }

    .individual-product-feature-list {

        @include breakpoint(large) {
            max-width: 300px;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .compare-block--feature-callout {
        padding: 2px 6px;
        border-radius: 7px;
        background: #d1cfc773;
    }

    .traits-row-list--circle {
        width: 5.5rem;
        height: 5.5rem;
        padding: 1.25rem 0.75rem;
        margin: 0.5rem auto;
        background: #fff;
        border-radius: 50%;

        p {
            color: $gray;
            line-height: 1.25;
            font-size: rem(14px);
            font-weight: 500;

            strong {
                font-weight: 800;
            }
        }
    }

    .compare-more-link {

        font-weight: 600;
        margin-left: 1rem;
        margin-right: 1rem;
    }

    .table--traits-row-list {

        max-width: 22rem;
        margin-left: auto;
        margin-right: auto;

        tbody {

            border: 2px solid #fff;

            tfoot,
            thead {
                border: 2px solid #fff;
            }

            td,
            th {
                padding: 0.6rem 0.6rem 0.8rem 0.6rem;
            }

            td {
                padding-top: 1rem;
                padding-bottom: 1rem;
            }

            tr {

                @include breakpoint(medium up) {
                    background: #fff;
                    background: linear-gradient(90deg, #fff 0%, $light-gray 60%);
                    border-bottom: 2px solid #fff;
                }

                &:nth-child(2n) {
                    background: $light-gray;

                    @include breakpoint(medium up) {
                        background: #f1f1f1;
                        background: linear-gradient(90deg, $light-gray 0%, #fff 60%);
                    }
                }
            }

        }

    }
}

/* Fix for unusual spacing on nowrap text at a specific size. */
@media screen and (min-width: 40em) and (max-width: 63.9375em) {

    .widget--compare-block .show-for-medium .review-count ~ p .nowrap {
        white-space: normal;
    }

    .widget--compare-block .show-for-medium .review-count ~ p .nowrap .text--wrapper--more-rounded {
        white-space: nowrap;
    }
}
