.product-box-container {

    .product-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: solid 1px #cccc;
        height: 105px;

        .product-image-container {
            width: 30%;
            text-align: center;

            .product-image {
                width: 130px;
            }
        }

        .product-description {
            width: 45%;
            margin: auto;

            p {
                margin-bottom: 0;
            }
        }

        .shop-btn {
            width: 20%;
            text-align: center;
            margin: auto;
        }
    }

    .price-strikethrough {
        text-decoration: line-through;
    }

    .discounted-price {
        color: #3b873e;
        font-weight: 600;
    }
}
