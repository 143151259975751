@import "../star-rating";

.refresh.conclusion-block-wrapper {
    background: linear-gradient(rgba(246, 246, 246, 0) 0%, $light-gray 100%);
    width: 100%;
}

.refresh-conclusion-block {

    .image-wrapping-link {
        display: inline-block;
        max-width: 49%;
    }

    .left-image,
    .right-image {
        display: inline-block;
    }

    .product-title {

        margin-bottom: 1rem;

        @include breakpoint(small only) {
            min-height: 3rem;
        }
    }

    .price {

        font-family: $font-family;

        sup {
            font-size: 60%;
            top: -0.5rem;
        }

        s {
            font-size: 1rem;
            font-weight: 500;
            color: $gray;

        }
    }

    .sale-price {
        color: $red;
    }

    @include starSvgFills(#half-star-small, #half-star-small, #half-star);

    .review-count {
        font-size: 0.875rem;
        font-style: italic;
        color: #6d6c6c;

        @include breakpoint(small only) {

            margin-top: 0.5rem;
        }

        @include breakpoint(medium) {

            margin-left: 0.5rem;
        }
    }

    .product-info {
        text-align: center;
        display: flex;
        flex-direction: column;

        &.dnb {

            .buy-now {

                margin-top: 2.5rem;
            }
        }

        &--link-features,
        &--link-title-price {
            color: $black;
        }

    }

    .feature-list {

        margin: 1.5rem 0.5rem 1rem;

        li {
            list-style: none;
            padding: 0.5rem 0;

        }
    }

    .all-base-features-callout {
        background-color: rgba(209, 207, 199, 0.45);
        border: #d1cfc7 1px solid;
        border-radius: 8px;
        font-style: italic;


    }

    .buy-now {

        padding: 0.5rem 1rem;
        display: block;
        max-width: 65%;
        margin: auto;
        align-self: flex-end;
    }

    &.hide-for-large {
        margin: rem(40px) auto 4rem;

        .left-image,
        .right-image {
            max-width: 175px;

            @include breakpoint(medium only) {
                max-width: 200px;
            }
        }

        .left-image {
            transform: translate(15px);

            @include breakpoint(medium only) {
                transform: translate(-70px);
            }
        }

        .right-image {
            transform: translate(-15px);

            @include breakpoint(medium only) {
                transform: translate(75px);
            }
        }

        .kits-image {
            margin-bottom: 3rem;
            display: flex;
            justify-content: center;
            align-content: center;
        }

        .buy-now {
            margin-bottom: 0;
        }

        .all-base-features-callout {
            padding: 0;
        }
    }

    &.show-for-large {
        margin: rem(40px) auto 4.5rem;
        padding: 4.5rem 0 5rem;

        .right-image {
            transform: translate(-50px);
        }

        .product-info {
            text-align: left;
        }

        .price {

            font-weight: 500;
        }

        .review-count {
            display: inline-block;
            vertical-align: middle;
        }

        .all-base-features-callout {
            padding: 0.5rem;
            line-height: 3;
            border-radius: 4px;
        }

        .buy-now {
            text-align: center;
            margin-left: 0;
            margin-bottom: 0;
        }
    }
}

.column-rounded-shadows {

    @include flex-full-height-column;
    @include light-box-shadows;

    border-radius: 8px;
    margin: 0;
    padding: rem(32px);
    position: relative;

    .stack-to-bottom {
        margin-top: auto;
    }

    .button-primary {
        justify-content: center;
        margin-top: rem(16);

        &.display-block {
            min-width: 100% !important;
        }
    }

    .info-badge {
        position: absolute;
        top: 0;
        left: 0;
        background: $black;
        border-top-left-radius: 8px;
        color: $white;
        font-size: 0.8rem;
        font-weight: 600;
        padding: rem(2) rem(4);

        @include breakpoint(medium) {
            padding: rem(4) rem(8);
        }
    }

}


.conclusion-block-swiper {

    &.swiper {
        --swiper-pagination-color: #000;
        padding-bottom: 50px;
        padding-top: 25px;
    }

    &.swiper-autoheight .swiper-wrapper {
        align-items: stretch;
    }
}
