@font-face {
    font-family: Gilroy;
    font-weight: 400;
    font-display: swap;
    src:
        url(//front.embarkvet.com/fonts/gilroy/Gilroy-Regular.otf) format("opentype"),
        url(//front.embarkvet.com/fonts/gilroy/Gilroy-Regular.woff2) format("woff2"),
        url(//front.embarkvet.com/fonts/gilroy/Gilroy-Regular.woff) format("woff");
}

@font-face {
    font-family: Gilroy;
    font-weight: 400;
    font-style: italic;
    font-display: swap;
    src:
        url(//front.embarkvet.com/fonts/gilroy/Gilroy-RegularItalic.woff2) format("woff2"),
        url(//front.embarkvet.com/fonts/gilroy/Gilroy-RegularItalic.woff) format("woff");
}

@font-face {
    font-family: Gilroy;
    font-weight: 600;
    font-display: swap;
    src:
        url(//front.embarkvet.com/fonts/gilroy/Gilroy-SemiBold.woff2) format("woff2"),
        url(//front.embarkvet.com/fonts/gilroy/Gilroy-SemiBold.woff) format("woff");
}

@font-face {
    font-family: Gilroy;
    font-weight: 600;
    font-style: italic;
    font-display: swap;
    src:
        url(//front.embarkvet.com/fonts/gilroy/Gilroy-SemiBoldItalic.woff2) format("woff2"),
        url(//front.embarkvet.com/fonts/gilroy/Gilroy-SemiBoldItalic.woff) format("woff");
}

@font-face {
    font-family: Gilroy;
    font-weight: 700;
    font-display: swap;
    src:
        url(//front.embarkvet.com/fonts/gilroy/Gilroy-Bold.otf) format("opentype"),
        url(//front.embarkvet.com/fonts/gilroy/Gilroy-Bold.woff2) format("woff2"),
        url(//front.embarkvet.com/fonts/gilroy/Gilroy-Bold.woff) format("woff");
}

@font-face {
    font-family: Gilroy;
    font-weight: 700;
    font-style: italic;
    font-display: swap;
    src:
        url(//front.embarkvet.com/fonts/gilroy/Gilroy-BoldItalic.woff2) format("woff2"),
        url(//front.embarkvet.com/fonts/gilroy/Gilroy-BoldItalic.woff) format("woff");
}

.large-body-text {
    font-size: rem(20px);
    line-height: 1.25;
    letter-spacing: 0.15px;
}

p {
    font-size: rem(16px);
    line-height: 1.5;
    letter-spacing: 0.15px;
}

small {
    font-size: rem(14px);
}

a {

    @extend .link--dotted--green;

    &.underlined-bold-hover {
        color: $black;
        text-decoration: underline;
        white-space: nowrap;

        &:hover,
        &:focus {
            font-weight: 700;
        }

        // elementor specificallity overrides this style so instead of putting
        // an !important I am adding this more specific rule.
        .elementor & {
            text-decoration: underline;
        }
    }
}

.header-link {
    text-decoration: none;
    color: inherit;

    &:hover,
    &:focus {
        text-decoration: underline;
        color: inherit;
    }
}

/*
 * These overrides are needed to prevent the underline on links in undesired
 * places.
 */
.blog-blog .item a,
.blog-blog .featured-category a,
.post-entry .post-header a,
.widget.rss-feed-button a,
a.sectionCardGrid--articleLinkText {
    color: inherit;
    text-decoration: inherit;

    &:hover,
    &:focus {
        color: inherit;
    }
}

.util-links-mobile .button:hover,
.util-links-secondary .button:hover {
    color: $black;
    background-color: $white;
}

@each $headline_size in 1,
    2,
    3,
    4,
    5,
    6 {

    .headline#{$headline_size},
    h#{$headline_size} {
        text-transform: none;
        margin-bottom: 1rem;

        @extend .weight--semibold;
    }

    // This allows us to change heading sizes from Elementor heading widget,
    // for example changing h3 to size h1 or .headline1.
    .elementor-heading-title.elementor-size-headline#{$headline_size} {

        @extend .headline#{$headline_size};
    }

    .h#{$headline_size} {
        margin-bottom: 1rem;
    }
}

.headlineHero,
.elementor-heading-title.elementor-size-headlineHero {
    font-size: rem(48px);
    line-height: 1;
    letter-spacing: -0.4px;

    @extend .weight--semibold;

    @include breakpoint(medium) {
        font-size: rem(60px);
    }
}

.elementor-heading-title.elementor-size-display-1 {
    font-family: Recoleta, serif;
    font-size: 3rem;
    line-height: 120%;
    letter-spacing: -0.4px;
    margin-bottom: 1rem;

    @include breakpoint(small only) {
        font-size: 2.5rem;
    }
}

.headline1,
.h1,
h1 {
    font-size: rem(40px);
    line-height: 1.1;
    letter-spacing: -0.4px;

    @include breakpoint (medium) {
        font-size: rem(48px);
    }
}

.headline2,
.h2,
h2 {
    font-size: rem(34px);
    line-height: 1.2;
    letter-spacing: 0;

    @include breakpoint (medium) {
        font-size: rem(40px);
    }
}

.headline3,
.h3,
h3 {
    font-size: rem(28px);
    line-height: 1.22;
    letter-spacing: 0;

    @include breakpoint (medium) {
        font-size: rem(32px);
        line-height: 1.25;
    }
}

.headline4,
.h4,
h4 {
    font-size: rem(24px);
    line-height: 1.25;
    letter-spacing: 0.15px;

    @include breakpoint (medium) {
        font-size: rem(26px);
        line-height: 1.23;
    }
}

.headline5,
.h5,
h5 {
    font-size: rem(20px);
    line-height: 1.25;
    letter-spacing: 0.15px;
    font-weight: 600;
}

.headline6,
.h6,
h6 {
    font-size: rem(16px);
    line-height: 1.5;
    letter-spacing: 0.15px;

}

.caption-text {
    font-size: rem(14px);
    line-height: 18px;
    letter-spacing: 0.15px;

    @include breakpoint(medium up) {
        line-height: 21px;
    }
}

.link {

    &--dotted--green {
        text-decoration: underline;

        &:hover {
            text-decoration: underline;
        }
    }
}

.text-color {

    &--dark {
        color: $black;
    }

    &--light {
        color: $white;
    }
}

$weights: (
    "regular": "400",
    "medium": "500",
    "semibold": "600",
    "strong": "700",
    "extra-heavy": "800"
);

@each $name,
    $weight in $weights {
    .weight--#{$name} {
        font-weight: #{$weight};
    }
}

@each $size,
    $value in $breakpoints {
    .text-center-#{$size} {

        @include breakpoint(#{$size}) {
            text-align: center;
        }
    }
}

// mixins for headlines

@mixin h($size) {

    @if $size == 5 {
        font-size: rem(20px);
        line-height: 1.25;
        letter-spacing: 0.15px;
    }
}
