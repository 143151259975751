.breed-reveal {
    overflow-x: hidden;

    section {
        margin-bottom: rem(48px);

        @include breakpoint(medium) {
            margin-bottom: rem(64px);
        }
    }

    @import "../partials/pages/breed-reveal/_hero.scss";
    @import "../partials/pages/breed-reveal/_slides.scss";
    @import "../partials/pages/breed-reveal/_confidence-bar.scss";

    &__flip {

        .bubbles-grid {

            background-image: url("https://embarkvet.com/wp-content/uploads/2023/08/Willie.png");
            background-repeat: no-repeat;
            background-position: center;
            display: block;
            width: 100vw;

            @include breakpoint(medium) {
                width: 100%;
            }
            position: relative;
            min-height: 800px;

            &__item-0 {
                grid-area: 1 / 1 / 2 / 2;
                right: 15%;
            }

            &__item-1 {
                grid-area: 1 / 5 / 2 / 6;
                left: 5%;
                bottom: 0;
                --size: 80px;
            }

            &__item-2 {
                grid-area: 3 / 2 / 4 / 3;
                left: 40%;
                top: 20%;
                --size: 80px;
            }

            &__item-3 {
                grid-area: 3 / 5 / 4 / 6;
                left: 40%;
                bottom: 6%;
                --size: 80px;
            }

            &__item-4 {
                grid-area: 5 / 2 / 6 / 3;
                --size: 80px;
            }

            &__item-5 {
                grid-area: 5 / 4 / 6 / 5;
                right: 18%;
                bottom: 20%;
            }

            @include breakpoint(medium) {

                &__item-0 {
                    right: unset;
                    left: 1%;
                    top: 25%;
                }

                &__item-1 {
                    left: 22%;
                    bottom: 40%;
                }

                &__item-2 {
                    left: unset;
                    right: 13%;
                    top: 18%;
                    --size: 80px;
                }

                &__item-3 {
                    left: unset;
                    right: 0;
                    bottom: 40%;
                }

                &__item-4 {
                    bottom: 10%;
                    left: 8%;
                }

                &__item-5 {
                    right: 22%;
                    bottom: 20%;
                }
            }

            img {
                max-height: 100px;
            }

        }

        &--copy {
            margin-bottom: 2rem;
        }

    }


    &__halo {
        min-height: 50vh;
        display: flex;
        align-items: center;
        justify-content: center;

        @include breakpoint(small only) {
            flex-direction: column-reverse;
            margin: 100px auto 0;
        }

        @include breakpoint(medium) {
            margin-bottom: -80px;
            margin-top: 110px;
        }

        &--dog svg {
            max-width: 100%;
            overflow: visible;
        }

        &__copy {

            @include breakpoint(medium) {
                padding: 0 54px;
            }

            p {
                margin-bottom: 0;
            }
        }

    }

    &__chat {
        padding: 24px 16px;
        flex-direction: column-reverse;

        @include breakpoint(medium) {
            padding: 108px 0;
            flex-direction: row;
        }

        .chat {
            max-width: 625px;
            min-height: 500px;
            padding: 24px;
            background-color: #feedb3;
            border-radius: 20px;
            border: 3px solid #000;
            margin-top: 30px;
            position: relative;
            z-index: 1;

            &::before {
                content: "";
                background-color: inherit;
                position: absolute;
                left: 20%;
                top: -27px;
                width: 50px;
                height: 50px;
                border-top: 3px solid #000;
                border-right: 3px solid #000;
                transform: rotate(-45deg) skew(-20deg, -20deg);
                border-top-right-radius: 15px;

                @include breakpoint(medium) {
                    right: -32.4px;
                    left: unset;
                    top: 15%;
                    width: 60px;
                    height: 60px;
                    transform: rotate(45deg) skew(-15deg, -15deg);
                    border-top-right-radius: 15px;
                }
            }

            @include breakpoint(medium) {
                margin-right: 30px;
            }

            &__bubble {
                display: grid;
                grid-template-columns: 90px auto;
                gap: 8px;
                align-items: center;
                margin-bottom: 14px;

                @include breakpoint(medium) {
                    margin-bottom: 18px;
                }

                &-typing {
                    position: absolute;
                    display: none;
                }

                &-message {

                    p {
                        background: #fff;
                        border-radius: 8px;
                        padding: 10px 16px;
                        margin: 0;
                    }
                }

                &--them {
                    grid-template-columns: auto 90px;

                    .chat__bubble-avatar {
                        order: 2;
                    }

                    .chat__bubble-message {
                        order: 1;

                        p {
                            background: $yellow;
                        }
                    }
                }
            }

        }

        &__content {
            margin-top: 45px;
        }

    }

    .wavy-top-divider--box-open path {
        fill: #f9d96c;
    }

    &__box-open {
        padding: rem(48px) 0;
        border-radius: 0 0 24px 24px;
        background: linear-gradient(180deg, #f9d96c 0%, #fcde70 54.04%, #f5d563 83.05%, #fbdb68 100%);
    }

    #yotpo-testimonials-custom-tab {
        padding: 0 24px;

        @include breakpoint(medium) {
            margin-top: rem(24px);
        }
    }

    .shop-block {

        &__item {

            img {
                border-radius: 8px;
            }

            .price-display {

                del {
                    color: #626262;
                }

                font-weight: 600;
            }

            &--internal {
                height: auto;
            }

            .shop-cta {
                margin-top: unset;
            }
        }

        @include breakpoint(small only) {
            display: block;
        }
    }


}

.page-template-temp-breed-reveal {

    .drawer img {
        display: block;
        margin: 0 auto rem(16px);
    }
}

.bubbles-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(5, 1fr);
    grid-column-gap: 0;
    grid-row-gap: 0;

    &__item-0 {
        grid-area: 1 / 2 / 2 / 2;
    }

    &__item-1 {
        grid-area: 1 / 5 / 2 / 6;
    }

    &__item-2 {
        grid-area: 2 / 4 / 3 / 5;
    }

    &__item-3 {
        grid-area: 3 / 1 / 4 / 3;
    }

    &__item-4 {
        grid-area: 4 / 3 / 6 / 4;
    }

    img {
        max-height: 100px;
    }
}

.icon {

    &--container {
        text-align: center;
        margin-bottom: 18px;
    }

    &--circle {
        display: inline-grid;
        padding: 8px;
        background: $white;
        border-radius: 100px;
        border: 2px solid $black;
    }
}

.show-more-about-breed {
    cursor: pointer;
    padding: 8px;
}

.flipable {
    position: absolute;
    width: 100px;
    height: 100px;

    .flip-front,
    .flip-back {
        position: absolute;
        backface-visibility: hidden;
        width: var(--size, 100px);
        height: var(--size, 100px);
    }

    .flip-front {

        img {
            width: 100%;
            z-index: 5;
            position: relative;
        }

        &__ring {
            position: absolute;
            top: 0;
            left: 0;
            background-color: #71bfe5;
            height: 100%;
            width: 100%;
            max-width: 100px;
            max-height: 100px;
            border-radius: 100%;
            opacity: 0.8;
        }
    }

    &__tip {
        position: absolute;
        left: 100%;
        top: 40%;
        width: 125px;

        &-text {
            color: #000;
            font-feature-settings: "clig" off, "liga" off;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            letter-spacing: 0.021px;
        }
    }

    .flip-back {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background: #fff;
        border: 1px dashed #ccc;
        border-radius: 50%;
        padding: 10px;
        text-align: center;
        width: 180px;
        height: 180px;
        visibility: hidden;

        p,
        h5 {
            margin: 0;
        }

        p {
            font-size: 14px;
        }

    }
}

.ring {
    width: 28px;
    height: 28px;
    border: 5px solid #000;
    border-radius: 50%;
    position: absolute;
    z-index: -1;

    &--yellow {
        border-color: #f9d056;

        &--1 {
            left: 50%;
            top: 13%;
        }

        &--2 {
            bottom: 22%;
            left: 3%;
        }
    }

    &--orange {
        border-color: #eda241;
        left: 25%;
        top: 20%;
    }

    &--red {
        border-color: #e06562;
        right: 12%;
        bottom: 13%;
    }

    &--green {
        border-color: #a3d442;
        left: 2%;
        top: 16%;
    }

    &--blue {
        border-color: #71bfe5;
        right: 17%;
        top: 18%;
    }

    &--purple {
        border-color: #a079a3;
        bottom: 3%;
        right: 24%;
    }

    &--gray {
        border-color: #7a7d81;
        bottom: 17%;
        left: 30%;
    }

    @include breakpoint(medium) {

        &--yellow {

            &--1 {
                left: unset;
                right: 17%;
                top: 33%;
            }

            &--2 {
                bottom: unset;
                top: 60%;
                left: 10%;
            }
        }

        &--orange {
            left: 1%;
            top: 44%;
        }

        &--red {
            bottom: unset;
            right: 3%;
            top: 35%;
        }

        &--green {
            top: unset;
            left: 0%;
            bottom: 16%;
        }

        &--blue {
            right: unset;
            left: 16%;
            top: 39%;
        }

        &--purple {
            bottom: 34%;
            right: -10%;
        }

        &--gray {
            left: unset;
            bottom: 45%;
            right: 22%;
        }
    }
}
