input[type="checkbox"].styled {
    --size: 20px;
    appearance: none;
    background-color: #fff;
    font: inherit;
    color: currentColor;
    transition: 0.12s background ease-in-out;
    width: var(--size);
    height: var(--size);
    border: 2px solid #000;
    border-radius: 20%;
    padding: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 0 8px 0 0;
}


input[type="checkbox"].styled:checked {
    background: #000;
}

input[type="checkbox"].styled:checked::before {
    transform: scale(1);
}

input[type="checkbox"].styled::before {
    content: "";
    width: calc(var(--size) - var(--size) / 2);
    height: calc(var(--size) - var(--size) / 2);
    border-radius: 10%;
    transform: scale(0);
    transition: 0.12s transform ease-in-out;
    transform-origin: center;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    background: #fff;
}
