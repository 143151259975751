.breadcrumbs {

    margin: rem(16px) 0;

    p {
        padding: 0 rem(10px);

        @include breakpoint(medium) {
            padding: 0 rem(20px);
        }
    }

    a {
        color: $black;
        text-decoration: none;

        &:hover,
        &:focus {
            color: $black;
            text-decoration: underline;
        }
    }
}
