$color-hark-green: #00444b;

.announcement-bar {
    display: grid;
    grid-template-columns: 1fr auto;
    padding: 0.0625rem 1.5rem;
    font-weight: 700;
    text-align: left;
    align-items: center;
    width: 100%;

    @media screen and (max-width: 64em) {
        text-align: center;
    }

    .elementor-page & {
        margin-bottom: 0;
    }

    a {
        color: $white;

        @include no-text-decoration;

        &:hover,
        &:focus {
            color: $white;
        }
    }

    &--yellow {
        color: $black;
        background-color: $yellow;

        a {
            color: $black;

            &:hover,
            &:focus {
                color: $black;
            }
        }
    }

    &--blue {
        color: $black;
        background-color: $blue;

        a {
            color: $black;

            &:hover,
            &:focus {
                color: $black;
            }
        }
    }

    &--purple {
        background-color: $purple;
        color: $white;
    }

    &--teal {
        background-color: $color-hark-green;
        color: $white;
    }

    &--brand-teal {
        background-color: $brand-teal;
        color: $white;
    }

    &--pink {
        background-color: $mojo;
        color: $white;
    }
}
