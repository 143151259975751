/*
* Styles for three-part-faq template
* Template: temp-three-part-faq.php
*/

.three-part-faq {
    margin-bottom: 60px;
}

.three-part-faq > section:nth-child(n+2) {
    margin-top: 60px;
}

.three-part-faq .hero {
    text-align: center;
    height: 250px;
}

.three-part-faq .hero-text {
    color: $black;
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translateY(-50%);
    padding-right: 15px;
}

.three-part-faq .section-banner .section-bg {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 0;
}

.three-part-faq .section-compare img {
    width: 100%;
    margin-bottom: 2rem;
}

.three-part-faq .section-relatives-info {
    margin-top: 60px;
}

.three-part-faq .section-relatives-image .desktop-image {
    display: none;
}

.three-part-faq .section-relatives-image .mobile-image {
    display: block;
}

.three-part-faq .section-relatives-image .mobile-image img {
    width: 100%;
}

.three-part-faq .section-compare h4,
.three-part-faq .section-relatives h4 {
    margin: 1rem 0;
}


@media print, screen and (min-width: 40em) {

    .three-part-faq .hero {
        height: 600px;
    }

    .three-part-faq .section-faq .faq {
        padding: 50px;
    }

    .three-part-faq .section-compare h4,
    .three-part-faq .section-relatives h4 {
        margin-top: 0;
    }

    .three-part-faq .section-compare img {
        width: auto;
    }

    .three-part-faq .section-relatives-image .desktop-image {
        display: block;
    }

    .three-part-faq .section-relatives-image .mobile-image {
        display: none;
    }
}
