
header.diva-hero {

    .diva-hero--image img.lazyload {
        min-height: 800px;
    }

    .grid-x {
        background-position: bottom center !important;
    }
}

.diva-hero--content {
    padding: 2rem 1.5rem 0 1.5rem;

    @media screen and (max-width: 40em) {
        text-align: center;
    }
}

.diva-hero-carousel {
    overflow-x: hidden;
}


.endorsement-hero {

    > .grid-x.large-flex-dir-row {
        background-position: 15% 50% !important;
        background-size: cover !important;

        @media screen and (min-width: 64em) {
            background-position: 50% 50% !important;
        }
    }

    .diva-hero--content {
        align-items: left;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    h1 {
        margin-left: 0;
        order: 2;
    }

    h2 {
        display: none;
    }

    &.small-order-1.medium-order-3 {
        order: 1;
    }

    .diva-hero--link-wrapper {
        margin-left: 0;
        order: 3;

        &.show-for-medium {
            display: inline-block !important;

            a {
                background-color: $yellow;
            }
        }
    }

    .diva-hero--logo {
        order: 1;
        padding: 0;

        img {
            max-width: 450px;
        }

        &.show-for-medium {
            display: block !important;
        }

        &.hide-for-large {
            order: 2;
        }
    }

    .medium-order-3 {
        order: 1;
    }

    &::after {
        background-color: $white;
        background-image: url("/wp-content/themes/embarkvet-theme/img/Cornell-University-Research-Partner.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: 50% 50%;
        content: "";
        display: block;
        height: 100px;
    }

}

.endorsement-hero .university-logo.show-for-small-only {
    display: none;
}

.endorsement-hero .hero-mobile-cta.show-for-small-only {
    display: none;
}

.endorsement-hero .diva-hero--image.hide-for-large {
    padding: 1em;
}

.diva-hero--logo {
    padding: 20px 0;
    margin: 0 auto;
    max-width: 80%;

    @include breakpoint(medium) {
        margin: 0;
    }

    img.lazyloaded {

        @include breakpoint(medium) {
            width: 400px !important;
            max-width: 400px !important;
        }
    }
}

.diva-hero--link {
    font-size: 1.3rem;
    font-weight: 700;
    color: $black;
    background-color: $yellow;
    padding: 15px 30px;
    display: inline-block;
}

.diva-hero--link-wrapper {
    margin: 1rem 0;

}

.diva-hero--link:hover {
    background-color: #fefefe;
    transition: background-color 0.25s ease-out, color 0.25s ease-out;
}

img.diva-hero--image-product {
    max-width: 420px;
    position: absolute;
    bottom: 50px;
    left: -68%;
    width: 70%;

}

@media screen and (min-width: 40em) {

    .diva-hero--link-wrapper {
        text-align: left;
    }
}


@media print, screen and (min-width: 64em) {

    .diva-hero--logo img {
        max-width: 300px;
    }

    .diva-hero--title {
        font-size: 40px;
    }

    .diva-hero--link {
        margin-bottom: 0;
    }

    .diva-hero--content {
        padding: 1rem 0 0.5rem 3rem;
    }
}

@media screen and (min-width: 75em) {

    header.diva-hero h1,
    header.diva-hero h2,
    .diva-hero--link-wrapper,
    .diva-hero--logo {
        margin-left: auto;
        max-width: 800px;
    }

    header.diva-hero.endorsement-hero h1,
    header.diva-hero.endorsement-hero h2,
    header.diva-hero.endorsement-hero .diva-hero--logo {
        margin-left: 0;
    }

    .diva-hero--logo img {
        margin-left: auto;
    }

    .diva-hero--title {
        font-size: 50px;
    }

    .hero-category .diva-hero--headline {
        font-size: 50px;
    }

    .breeder-partnership .section-banner img.hero-category--image,
    .category-resources img.hero-category--image {
        right: 20%;
    }

    .category-blog img.hero-category--image {
        width: 600px;
    }
}

@media screen and (max-width: 39.9375em) {

    header.hero-video h2 {

        /*
         We must deviate from the default font-size
         here to prevent weird wrapping on iPhone 5
        */
        font-size: 1.2rem;
    }
}
