/**
* Fixes the miss-alignment of swiper slides when centered mode is enabled.
*
* Note: This is kept here instead of `_mixins.scss` because it's  very specific
* and probably not reusable.
*/
@mixin swiper-centered-slides-alignment-fix {

    @include breakpoint(small only) {

        .swiper-slide {

            &-active {
                transform: translateX(-20px);
            }

            &-next {
                transform: translateX(-40px);
            }
        }


        &.swiper-dragging {

            .swiper-slide-next + .swiper-slide {
                transform: translateX(-60px);
            }

        }

    }
}


#decoded-media-slider {

    .swiper-container {
        height: 400px;

        @include swiper-centered-slides-alignment-fix;

        @include breakpoint(medium) {
            height: 500px;
        }

        @include breakpoint(large) {
            height: 260px;
        }
    }

    .swiper-slide {
        list-style: none;
        width: 85%;
        padding: rem(16px);

        @include breakpoint(medium) {
            width: 60%;
        }

        @include breakpoint(large) {
            width: 50%;
        }
    }

    .media-slider-slide {
        background: $white;
        color: $black;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        min-height: 320px;
        padding: rem(30px) rem(25px) rem(16px);

        @include breakpoint(medium) {
            padding: rem(27px) rem(16px);
            min-height: 400px;
        }

        @include breakpoint(large) {
            padding: 0;
            flex-direction: row;
            min-height: 167px;
        }
    }

    .slider-card-body {

        @include breakpoint(medium) {
            padding: 0 rem(50px) 0 rem(30px);
        }

        &-copy {
            text-align: center;
            font-weight: 500;
            margin-bottom: 0;
            font-family: $font-family;
        }
    }

    .slider-card-logo {

        @include breakpoint(small only) {

            &::before {
                content: "";
                border-top: 1px solid darken($light-gray, 3%);
                display: block;
                width: 85%;
                margin: auto;
                padding: rem(8px) 0;
            }
        }

        @include breakpoint(large) {
            padding: rem(16px) 0;
            border-left: 2px solid darken($light-gray, 3%);
        }
    }

    .swiper-pagination {
        bottom: 20px;

        &-bullet {
            box-sizing: border-box;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            text-indent: 100%;
            overflow: hidden;
            white-space: nowrap;
            border: 1px solid rgba(102, 102, 102, 0.4);
            transition: 0.2s ease-in-out;
            transition-property: background-color, border-color;

            &-active {
                background-color: rgb(10, 159, 149);
                border-color: rgb(10, 159, 149);
            }
        }
    }
}
