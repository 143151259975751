/* START Products block on homepage */
.block-products {
    background: #fafafa;
    padding: 3rem 0 2rem 0;
}

.block-products .products-container {
    margin-top: 1rem;
}

.block-products .product {
    background: $white;
    padding: 2rem;
    border-radius: 0.4rem;
    box-shadow: 0 4px 16px 0 hsla(0, 0%, 0%, 0.1);
}


.block-products .product-info {
    margin: 1rem auto;
}

.block-products .product-info a[class^="button-"] {
    margin-top: 1rem;
}

@media screen and (max-width: 25em) {

    .diva-hero--logo {
        display: block;
        top: 1.5em;
        left: 0;
    }
}

@media only screen and (max-width: 40em) {

    .block-products {
        padding: 1rem 0 2rem;
    }

    .block-products .product {
        box-shadow: none;
        padding: 0.8rem;
    }

    .block-products .product h3 > span {
        display: block;
    }

    .block-products .product .button-large {
        padding: 15px 25px;
    }

    .block-products .product:first-child {
        border-right: 1px solid #f1f1f1;
    }

    .block-products .products-container {
        box-shadow: 0 4px 16px 0 hsla(0, 0%, 0%, 0.1);
    }
}

/* END Products block on homepage */
