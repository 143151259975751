/*
* Styles for Manifesto page
* Template: temp-manifesto.php
*/

.manifesto .section-promise {
    background-color: $black;
    color: $white;
    min-height: 70vh;
    overflow: hidden;
    padding-top: 1em;
    position: relative;

    @include breakpoint(large) {
        padding-top: initial;
    }
}

.manifesto .section-promise .promise-image {
    margin-top: 2rem;
    max-height: 300px;
    max-width: 630px;
}

.manifesto .section-promise .embark-logo {
    text-align: left;
}

.section-embark-with-us {
    padding: 1rem 0;
}

@media screen and (min-width: 64em) {

    .manifesto .section-promise {
        background-size: 40%;
        padding: 2rem 0 4rem;
        min-height: 100vh;
        background-position: bottom left;
    }

    .manifesto .section-promise .promise-image {
        position: absolute;
        left: 0;
        width: 40%;
        height: auto;
        bottom: 0;
        margin-top: 0;
        max-height: none;
    }

    .manifesto .manifesto-content {
        padding-right: 8rem;
    }

    .manifesto .manifesto-content .text-footer {
        white-space: pre-wrap;
        line-height: 1.8;
    }

    .section-embark-with-us {
        padding: 5rem 0;
    }

    .section-embark-with-us .button-large {
        margin-top: 3rem;
    }

    .manifesto .section-promise .embark-logo {
        text-align: right;
    }
}
