.database-page {
    display: grid;
    grid-template-areas: "hero hero hero" "filters content content";
    grid-template-columns: max-content 1fr;
    max-width: 1366px;
    margin: 0 auto;

    @include breakpoint(medium) {
        grid-template-columns: minmax(max-content, 343px) 1fr;
        padding: 16px;
    }

    &__hero {
        padding: 32px 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        grid-area: hero;
    }

    .database-hero {

        &__description {
            max-width: 490px;
            margin-bottom: 24px;
            text-align: center;
        }

        &__more {
            text-align: center;

            > p {
                font-size: rem(14px);
                margin-bottom: 5px;
            }

            .button-primary {
                background-color: $yellow;
                color: $black;
                display: inline-flex;
                gap: 5px;
            }
        }

        &__search {
            width: 100%;
            display: block;

            @include breakpoint(medium) {
                display: flex;
                justify-content: center;
            }
        }
    }

    @include breakpoint(medium) {
        grid-column-gap: 50px;
    }

    .filters-toggle {
        width: 100%;

        button {
            display: flex;
            justify-content: center;
            width: 100%;
        }

        @include breakpoint(medium) {
            display: none;
        }
    }

    &__filters {
        grid-area: filters;
        padding: 0 24px;
        display: none;

        .section-drawer-header,
        .section-drawer-footer {
            display: none;
        }

        &--active {
            position: fixed;
            top: 0;
            z-index: 9999; // this is to put the modal above navigation.
            background: #fff;
            width: 100%;
            height: 100vh;
            height: 100dvh; /* dvh is not supported by Firefox yet. */
            display: block;
            padding: 0;

            form {
                display: flex;
                height: 100vh;
                height: 100dvh; /* dvh is not supported by Firefox yet. */
                flex-direction: column;
                justify-content: space-between;
            }

            .section-drawer-header {
                border-bottom: 1px solid #ccc;

                h4 {
                    margin-bottom: 0;
                }
            }

            .section-drawer-footer {
                border-top: 1px solid #ccc;

                button {
                    width: 100%;
                    justify-content: center;
                }
            }

            .section-drawer-body {
                flex: 1;
                overflow: scroll;

                &__details {
                    display: none;
                }
            }

            .section-drawer-header,
            .section-drawer-body,
            .section-drawer-footer {
                padding: 16px 24px;
            }

            .section-drawer-header,
            .section-drawer-footer {
                display: flex;
                justify-content: space-between;
            }
        }

        .starts_with-filter {
            display: grid;
            grid-auto-columns: 1fr;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
            grid-gap: 2px;

            .filter_counter {
                display: none;
            }

            li {
                outline: 2px solid $mid-gray;
                text-align: center;

                &.active:not(.disabled) {
                    background: $black;
                    color: $white;

                    label {
                        color: $white;
                    }

                }

                &.disabled {
                    background: #e0e0e0;

                    label {
                        color: $gray;
                        cursor: default;
                    }
                }

                &:first-child {
                    border-radius: 5px 0 0 0;
                }

                &:last-child {
                    border-radius: 0 0 5px 0;
                }

                &:nth-child(7) {
                    border-radius: 0 5px 0 0;
                }

                &:nth-child(21) {
                    border-radius: 0 0 5px 0;
                }

                &:nth-child(22) {
                    border-radius: 0 0 0 5px;
                }

                label {
                    font-weight: 600;
                    font-size: 1.25rem;
                    padding: rem(4px);

                    input[type="checkbox"].styled {
                        display: none;
                    }
                }
            }
        }

        @include breakpoint(medium) {
            display: block;
            padding: 0;
        }

        .section-drawer-body {

            &__filters {

                @include breakpoint(medium) {
                    padding: 18px;
                    border-top: 1px solid #ccc;
                }
            }
        }

        ul {
            list-style: none;
            margin-left: 0;

            li label {
                padding: 8px 0;
                cursor: pointer;
                position: relative;
            }

        }
    }

    &__content {
        grid-area: content;
        padding: 0 20px;

        @include breakpoint(medium) {

            .section-drawer-body__details {
                display: none;
            }
        }
    }

    .filter-details,
    .search-details {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 24px;
        gap: 10px;

        button {
            text-decoration: underline;
            color: $black;
        }
    }
}

.database-item {
    padding: 16px;
    background: #f7f7f7;
    margin-bottom: 10px;
    border-radius: 8px;

    > div {
        flex: 1;
    }

    &__title {
        display: grid;
        grid-template-columns: 1fr auto;
        align-items: center;
        column-gap: 10px;
    }

    a {
        text-decoration: none;
    }

    &__details {
        display: grid;
        grid-template-columns: 1fr max-content;
        grid-gap: 16px;

        .badge {
            margin-bottom: 6px;
            text-align: left;
        }
    }

    &__image {
        width: 90px;
        height: 90px;
        border-radius: 8px;
        overflow: hidden;
    }

    &__action {
        background: none;
        border: none;
        border-bottom: 1px solid currentColor;
        display: block;
        margin-top: 24px;
        color: $black;
    }

    &__body {
        opacity: 0;
        transition: max-height ease-in-out 0.8s, opacity ease 0.8s, padding ease 0.6s;
        max-height: 0;
        overflow: hidden;

        &.expanded {
            padding: 16px 0;
            opacity: 1;
            max-height: 1000px;

            @include breakpoint(medium) {
                max-height: 320px;
            }

        }

        &:not(.expanded) {
            display: none;
        }
    }
}

.search-input {
    display: flex;
    margin: 30px 0 20px 0;
    border: 1px solid #949494;
    border-radius: 30px;
    overflow: hidden;
    max-width: 100%;
    width: 100%;

    ::placeholder {
        color: #626262;

        @include breakpoint(small only) {
            font-size: 14px;
        }
    }

    @include breakpoint(medium) {
        margin: 40px 0;
        width: 370px;
    }

    label {
        flex: 1;
    }

    input[type="search"] {
        margin-bottom: 0;
        border: 0;
        border-right: 1px solid #949494;
        padding: 8px 18px;

        ::placeholder {
            color: #949494;
        }

    }

    button {
        padding: 0 13px;
        display: inline-flex;
        align-items: center;
    }
}

.database-page .no-results-message {
    display: flex;
    height: 150px;
    border: 3px dashed #95949426;
    align-items: center;
    justify-content: center;

    &__line {
        color: #959494ed;
    }
}

// database conclusion
.conclusion-cards-title {
    text-align: center;
}

.conclusion-cards {
    display: grid;
    grid-template-columns: 1fr;
    gap: 32px;

    @include breakpoint(medium) {
        grid-template-columns: 1fr 1fr;
    }
}

.conclusion-card {
    background: #ffce34;
    border-radius: 8px;
    padding: 32px;

    &--breeder {
        background: var(--primary-parchment, #f6f6f6);
    }

    &__primary-cta,
    &__secondary-cta {
        display: inline-block;
        margin-bottom: 24px;
    }

    &__secondary-cta {
        font-weight: 600;
        text-decoration: none;
    }
}

// disable scrolling when the filters modal/drawer is open
body:has(.database-page__filters--active) {
    overflow: hidden;
}
