@use "sass:math";

.breadcrumbs {

    &__resource-center {

        p {

            font-size: rem(14px);

            @include breakpoint(medium) {
                font-size: rem(16px);
            }
        }
    }
}

.numbered-pagination {

    margin: rem(8px) rem(8px) rem(24px);

    .page-numbers {
        $pagination_container_size: 40px;
        $pagination_item_outline_size: 2px;
        border-radius: 50px;
        color: $black;
        display: inline-block;
        line-height: rem($pagination_container_size - 8px);
        margin: 0 rem(4px);
        min-width: rem($pagination_container_size - 8px);
        min-height: rem($pagination_container_size - 8px);
        outline: $pagination_item_outline_size solid transparent;
        text-decoration: none;

        @include breakpoint(414px) {
            line-height: rem($pagination_container_size);
            min-width: rem($pagination_container_size);
            min-height: rem($pagination_container_size);
            margin: rem(-24px) rem(4px);
        }

        &:not(.dots):not(.current):hover,
        &:not(.dots):not(.current):focus {
            background: $yellow;
            color: $black;
            outline-color: $yellow;
            outline-width: $pagination_item_outline_size;
        }

        &.current,
        &:not(.dots):not(.current):hover,
        &:not(.dots):not(.current):focus {
            font-weight: 700;
        }

        &.current {
            background: $yellow;
            outline-color: $black;
        }

        &.dots {
            // in future version of sass this is deprecated
            // in favor of math.div()
            min-width: math.div($pagination_container_size, 2);
            margin: 0;
        }
    }
}
