.page-id-36622 {

    .top-navigation {
        display: none;
    }
}

.postid-40926 .single-post .hero {

    @include breakpoint(1200px) {
        max-height: 566px;
    }
}
