/* START Block Our Research */

.block-our-research {
    padding: 2rem 0;
}

.block-our-research .block-our-research-title {
    margin-bottom: 2rem;
}

@media only screen and (max-width: 48em) {

    /*Tablet Only*/
    .block-our-research {
        background-position-x: -7rem !important;
    }
}

/* END Block Our Research */
