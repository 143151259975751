/*
* Styles for Breed ancestry page
* Template: temp-ancestry.php
*/
#breed-ancestry .small {
    text-transform: uppercase;
    font-size: 12px;
    color: #666;
    text-align: center;
    margin-top: 6px;
}

@media print, screen and (min-width: 40em) {

    #breed-ancestry .small {
        margin-top: 16px;
    }

    #breed-ancestry ul.orbit-dog li {
        margin-top: 100px;
    }
}

#breed-ancestry ul.orbit-dog {
    margin-left: 0;
    height: auto !important;
    overflow: hidden !important;
}

#breed-ancestry .int {
    margin-top: 50px;
    text-align: center;
}

#breed-ancestry .int p {
    padding: 0 20px;
}

@media print, screen and (min-width: 64em) {

    #breed-ancestry .int p {
        padding: 0 150px;
    }
}

#breed-ancestry .breed-tab {
    display: none;
}

#breed-ancestry .breed-tab.is-active {
    display: block;
}

#breed-ancestry .dark-blue-background {
    background: #51c0eb;
    padding-top: 50px;
    padding-bottom: 50px;
    font-size: 16px;
    color: $white;
    text-align: center;
}

#breed-ancestry .dark-blue-background .breed2 {
    font-size: 20px;
}

#breed-ancestry .dark-blue-background .breed {
    font-size: 24px;
    font-weight: 700;
    line-height: 20px;
}

#breed-ancestry .dark-blue-background .description {
    margin-top: 40px;
    margin-bottom: 40px;
    text-align: justify;
}

#breed-ancestry .dark-blue-background .breed-families {
    font-size: 14px;
    font-weight: 700;
    margin-top: 40px;
    text-align: center;
}

#breed-ancestry .dark-blue-background .breed-families .relative {
    font-size: 12px;
    text-transform: uppercase;
    line-height: 12px;
}

#breed-ancestry .dark-blue-background .breed-families .family {
    width: 170px;
    display: inline-block;
}

#breed-ancestry .blue-background {
    background: #43abd3;
    color: $white;
    padding-bottom: 50px;
    padding-top: 50px;
}

#breed-ancestry .blue-background h3 {
    text-align: center;
    margin-top: 0;
}

#breed-ancestry .blue-background .dna-dog {
    text-align: center;
}

#breed-ancestry .blue-background .dna p {
    text-align: center;
}

@media print, screen and (min-width: 40em) {

    #breed-ancestry .blue-background .dna p {
        text-align: left;
    }
}

#breed-ancestry .blue-background .dna h4 {
    text-align: center;
    margin-top: 20px;
}

@media print, screen and (min-width: 40em) {

    #breed-ancestry .blue-background .dna h4 {
        margin-top: 0;
        text-align: left !important;
    }
}

#breed-ancestry .tabs-nav {
    margin-top: 50px;
    background: #f7f7f7;
    padding: 30px 0 20px 0;
}

#breed-ancestry .tabs-nav ul {
    list-style: none;
    padding: 0;
    margin-left: 0;
    text-align: center;
}

#breed-ancestry .tabs-nav ul li {
    display: inline-block;
    margin: 0 20px;
    text-transform: uppercase;
    color: #848484;
    font-weight: 700;
    border-bottom: 4px solid #848484;
    padding-bottom: 6px;
    cursor: pointer;
}

#breed-ancestry .tabs-nav ul li.purple.active {
    color: #a776a6;
    border-bottom: 4px solid #a776a6;
}

#breed-ancestry .tabs-nav ul li.green.active {
    color: #95d600;
    border-bottom: 4px solid #95d600;
}

#breed-ancestry .tabs-nav ul li.purple:hover {
    border-bottom: 4px solid #a776a6;
}

#breed-ancestry .tabs-nav ul li.green:hover {
    border-bottom: 4px solid #95d600;
}

#breed-ancestry .tab {
    background: $red;
    display: none;
    padding: 40px 20px;
    color: $white;
}

#breed-ancestry .tab img {
    margin-bottom: 20px;
}

@media print, screen and (min-width: 40em) {

    #breed-ancestry .tab {
        padding: 40px 100px;
    }
}

#breed-ancestry .tab.green {
    background: #95d600;
}

#breed-ancestry .tab.green p {
    color: $black;
}

#breed-ancestry .tab.green .ancestry-header {
    color: $black;
}

#breed-ancestry .tab.purple {
    background: #a776a6;
}

#breed-ancestry .tab.purple b {
    color: $black;
}

#breed-ancestry .tab.active {
    display: block;
}

#breed-ancestry .tab .map {
    margin-top: 50px;
}

#breed-ancestry .tab .map img {
    background-color: $white;
}

#breed-ancestry .nav-placeholder {
    height: 180px;
    display: none;
}

#breed-ancestry .orbit-bullets {
    width: 100%;
    height: 178px;
    z-index: 100;
    background: rgba(255, 255, 255, 0.95);
    border-bottom: 1px solid #ccc;
    padding-bottom: 0;
    margin: 0;

    &,
    * {
        transition: all ease 0.3s;
    }

}

#breed-ancestry .orbit-bullets.fixed {
    position: fixed;
    top: 72px;
    height: 91px;
    font-size: 0;
    text-align: center !important;
    max-width: 1920px;
    margin: 0 auto;
}

@media print, screen and (min-width: 40em) {

    #breed-ancestry .orbit-bullets.fixed {
        font-size: 16px;
        text-align: left;
    }
}

@media screen and (max-width: 1030px) {

    #breed-ancestry .orbit-bullets.fixed {
        top: 66px;
        left: 0;
    }

    #breed-ancestry .orbit-bullets {
        z-index: 99;
    }
}

#breed-ancestry .orbit-bullets.fixed .small {
    margin-bottom: 10px;
    margin-top: -10px;
}

#breed-ancestry .orbit-bullets.fixed button {
    padding-bottom: 0;
    display: inline-block;
}

@media print, screen and (min-width: 40em) {

    #breed-ancestry .orbit-bullets.fixed button {
        margin-right: 10px;
        margin-left: 10px;
    }
}

#breed-ancestry .orbit-bullets.fixed button img {
    width: 35px;
    height: auto;
    display: block;
    margin: 20px auto;
}

@media print, screen and (min-width: 40em) {

    #breed-ancestry .orbit-bullets.fixed button img {
        display: inline-block;
        margin-right: 0.5rem;
    }
}

#breed-ancestry .orbit-bullets button {
    width: 20%;
    height: auto;
    overflow: hidden;
    background: transparent;
    border-radius: 0;
    padding-bottom: 10px;
    margin-bottom: 0;
}

@media print, screen and (min-width: 40em) {

    #breed-ancestry .orbit-bullets button {
        width: 100px;
        margin-right: 20px;
        margin-left: 20px;
    }
}

#breed-ancestry .orbit-bullets button img {
    margin-bottom: 20px;
    width: 45px;
}

@media print, screen and (min-width: 40em) {

    #breed-ancestry .orbit-bullets button img {
        width: auto;
    }
}

#breed-ancestry .orbit-bullets button.is-active {
    border-bottom: 4px solid $yellow;
}

#breed-ancestry .orbit-bullets button:focus {
    outline: none;
}

#breed-ancestry .module.ancestors {
    margin-bottom: 50px;
}
