/*
* Styles for health page
* Template: temp-health.php
*/

.page-template-temp-health .entry-content {
    margin-top: 1rem;
}

.page-template-temp-health .entry-content h1,
.page-template-temp-health .entry-content h2,
.page-template-temp-health .entry-content h3,
.page-template-temp-health .entry-content h5,
.page-template-temp-health .entry-content h4 {
    margin: 1rem auto;
}

.page-template-temp-health .section-media-object {
    margin: 1.5rem auto;
}

.body_content_a_section_media_object .primary-image-container img {
    max-width: unset;
}

.page-template-temp-health .accented-bg {
    background-color: #f7f7f7;
    padding: 1rem 0;
}

.quotes_section_slider_stylized_quotes {
    margin: 4rem auto;
}
