.orbit-next.outside {
    padding-right: 0;
    transform: translateX(50%);
}

.orbit-previous.outside {
    padding-left: 0;
    transform: translateX(-50%);
}

.orbit-container:focus {
    outline: none;
}

.page-template-temp-research .orbit-container,
.page-template-temp-our_story .orbit-container,
.page-template-temp-ancestry .orbit-container {
    height: auto;
    overflow: visible;
}

section.slider-media-object .orbit-container,
section.slider-quotes .orbit-container,
section.slider-tabbed .orbit-container {
    overflow: hidden;
}

// The following orbit styles overrides are potentially not used anywhere.
.orbit-bullets button.orbit-bullets-pretty {
    margin-left: 15px;
    margin-right: 15px;
    opacity: 0.5;
    width: 0.8rem;
    height: 0.8rem;
}

.orbit-bullets button.orbit-bullets-pretty:hover {
    opacity: 1;
}

.orbit-bullets button.orbit-bullets-pretty.is-active {
    opacity: 1;
    width: 1.2rem;
    height: 1.2rem;
}

.orbit-bullets button.orbit-bullets-green {
    background-color: #95d600;
}

.orbit-bullets button.orbit-bullets-purple {
    background-color: #a776a6;
}

.orbit-next-pet-carousel {
    color: #797d82;
    right: -10px;
}

.orbit-previous-pet-carousel {
    color: #797d82;
    left: -10px;
}
// end - The following orbit styles overrides are potentially not used.


/* START Quote Slider Image Height fix */

.slider-quotes .orbit .lazyload {
    height: 300px;
}

/* END Quote Slider Image Height fix*/

.orbit-previous,
.orbit-next {
    width: 47px;
    height: 47px;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 100%;
    background-color: transparentize($white, 0.4); //hsla(0,0%,100%,.6);

    &.orbit-next {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='currentColor' viewBox='0 0 16 16'%3E%3Cpath d='m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z'%3E%3C/path%3E%3C/svg%3E");
    }

    &.orbit-previous {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='currentColor' viewBox='0 0 16 16'%3E%3Cpath d='m3.86 8.753 5.482 4.796c.646.566 1.658.106 1.658-.753V3.204a1 1 0 0 0-1.659-.753l-5.48 4.796a1 1 0 0 0 0 1.506z'/%3E%3C/svg%3E");
    }

    &:hover,
    &:focus {
        background-color: $white;
    }

    &.inside {

        &.orbit-previous {
            margin-left: rem(16);

        }

        &.orbit-next {
            margin-right: rem(16);
        }
    }
}
