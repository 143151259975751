.breed-directory {
    padding: 3rem 0;
}

.breed-list-group {

    @include breakpoint(medium up) {
        margin-top: 3rem;
    }

    &,
    .accordion-content {
        background: transparent;
    }

    .accordion-title {

        @include breakpoint(medium up) {
            border: none;
            border-bottom: none !important; // todo: figure out how to avoid !important here.
        }

        &:hover,
        &:focus {
            background: transparent;
        }

        @include breakpoint(medium up) {

            &::before {
                display: none;
            }
        }

    }

    &.accordion {

        .accordion-item {
            margin: 0 0.5rem;

            &.is-active {

                &,
                .accordion-title {
                    border: none;

                    &::before {
                        transform: rotate(-90deg);
                    }
                }
            }

            .accordion-title {
                padding: 1rem 0.1rem;
                border-bottom: 1px solid $gray;

                &::before {
                    content: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='chevron-right' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512' class='svg-inline--fa fa-chevron-right fa-w-10 fa-9x'%3E%3Cpath fill='currentColor' d='M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z' class=''%3E%3C/path%3E%3C/svg%3E");
                    width: 12px;
                    line-height: 1;
                    transform: rotate(90deg);
                    transform-origin: center center;
                    font-size: unset;
                    margin-top: 0;
                    top: 28%;
                    right: 7px;
                }
            }

            .accordion-content {
                padding: 0;
            }
        }
    }

    @include breakpoint(medium up) {
        border: none;
    }

    &__index {
        padding: 0 1rem 0 0;
        color: $black;
    }

    &__items {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        background: $white;

        @include breakpoint(medium up) {
            display: grid;
            grid-template-columns: 50% 50%;
            place-items: stretch;
        }

        border: 1px solid #959593;
        border-radius: 1rem;
        padding: 0 0.5rem;
    }

    &__item-wrapper {
        flex: 1;

        @include breakpoint(medium up) {

            &:nth-child(odd) {
                padding-right: 1rem;
                border-right: 1px solid $gray;
            }

            &:nth-child(even) {
                padding-left: 1rem;
            }

            &:last-child,
            &:nth-last-child(2) {

                .breed-list-group__item {
                    border: none;
                }
            }
        }

        a {
            color: $black;
            text-decoration: none;
        }
    }

    &__item {
        border-bottom: 1px solid $gray;
        position: relative;
        padding: 1rem;

        &::after {
            content: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='chevron-right' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512' class='svg-inline--fa fa-chevron-right fa-w-10 fa-9x'%3E%3Cpath fill='currentColor' d='M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z' class=''%3E%3C/path%3E%3C/svg%3E");
            position: absolute;
            top: 20px;
            right: 10px;
            line-height: 1;
            width: 12px;

            @include breakpoint(medium up) {
                right: 5px;
            }
        }

        &-name {
            margin-bottom: 0;
        }

    }

}
