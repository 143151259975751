$threshold-size: 1023px;
$next-size: 1024px;

.breed-conclusion-wrapper {
    display: grid;
    grid-column-gap: 50px;
    grid-template-columns: 343px 1fr;
    padding: 0 20px;
    max-width: 1366px;
    margin: 0 auto;

    @media (min-width: 640px) {
        padding: 16px;
    }

    @media print, screen and (max-width: $threshold-size) {
        grid-template-columns: 1fr;
    }


    #breed-conclusions-data {

        padding: 0;
        margin-top: 4rem;

        @media (min-width: 640px) {
            padding: 0 20px;
            margin-top: 1rem;
        }


        @media print, screen and (min-width: $next-size) {
            grid-column: span 2;
            grid-column-start: 2;
        }

    }
}

.database-breed {
    grid-template-areas: "hero hero hero" "internal internal internal";

    .filter-group-section {
        display: grid;
        grid-area: internal;
        grid-template-areas: "search search search" "filters content content";
        grid-column-gap: 50px;
        grid-template-columns: max-content 1fr;


        @include breakpoint(medium) {
            grid-template-columns: minmax(max-content, 343px) 1fr;
        }

        .database-hero__search {
            width: 100%;

            @media print, screen and (min-width: $next-size) {
                display: flex;
                justify-content: center;
            }


            form,
            .search-input {
                width: 100%;

                @media print, screen and (min-width: $next-size) {
                    width: 370px;
                }
            }

            .search-input {
                margin: 30px 0 20px;

                @media print, screen and (min-width: $next-size) {
                    margin: 40px 0;
                }
            }
        }
    }

    .search {
        grid-area: search;
        padding: 0 16px;
    }

    .database-page__hero {
        padding-bottom: 8px;
    }

    @media print, screen and (max-width: $threshold-size) {
        display: block;

        .filter-group-section {
            display: block;
        }
    }

    .breed-list-items {
        display: grid;
        grid-template-columns: 1fr;

        @include breakpoint(medium) {
            grid-template-columns: 1fr 1fr;
            column-gap: 10px;
        }
    }

    .database-item {

        &__title {
            display: grid;
            grid-template-columns: 1fr auto auto;
            align-items: center;
            column-gap: 10px;
            width: 100%;
        }
    }

    .accordion {

        &-mobile-title {
            display: none;

            @media print, screen and (max-width: $threshold-size) {
                display: block;
            }
        }

        &-title {
            cursor: pointer;
            display: flex;
            position: relative;
            border: none;
            padding: 16px;
            color: $black;

            &:focus,
            &:hover {
                background: $white;
            }

            &::before {
                content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEyIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMC41OSAwLjU4OTk2Nkw2IDUuMTY5OTdMMS40MSAwLjU4OTk2NkwwIDEuOTk5OTdMNiA3Ljk5OTk3TDEyIDEuOTk5OTdMMTAuNTkgMC41ODk5NjZaIiBmaWxsPSIjMDcwMDAwIi8+Cjwvc3ZnPgo=);
                font-weight: 300;
                transition: all 0.5s ease;
                position: absolute;
                top: 50%;
                right: 1rem;
                margin-top: -0.5rem;
            }

            &[aria-expanded="true"]::before {

                transform: rotate(-180deg);
            }

            h5 {
                margin-bottom: 0;
            }

        }

        &-content {
            border: none;
            padding-top: 0;
        }

        &-item {

            a:focus,
            a:hover {
                color: $black;
            }

            @media print, screen and (max-width: $threshold-size) {
                display: none;
            }

            &--starts_with {
                display: block;

                .accordion-title {

                    @media print, screen and (max-width: $threshold-size) {
                        display: none;
                    }
                }

                .accordion-content {
                    padding-top: 16px;
                }
            }
        }

    }


    .database-page__filters {
        display: block;

        @media (max-width: $threshold-size) {
            padding: 0 24px;
        }

        .starts_with-filter {
            display: grid;
            grid-auto-columns: 1fr;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
            grid-gap: 2px;

            .filter_counter {
                display: none;
            }

            li {
                outline: 2px solid $mid-gray;
                text-align: center;

                &.active:not(.disabled) {
                    background: $black;
                    color: $white;

                    label {
                        color: $white;
                    }

                }

                &.disabled {
                    background: #e0e0e0;

                    label {
                        color: $gray;
                        cursor: default;
                    }
                }

                &:first-child {
                    border-radius: 5px 0 0 0;
                }

                &:last-child {
                    border-radius: 0 0 5px 0;
                }

                &:nth-child(7) {
                    border-radius: 0 5px 0 0;
                }

                &:nth-child(21) {
                    border-radius: 0 0 5px 0;
                }

                &:nth-child(22) {
                    border-radius: 0 0 0 5px;
                }

                label {
                    font-weight: 600;
                    font-size: 1.25rem;
                    padding: rem(4px);
                    position: relative;

                    input[type="checkbox"].styled {
                        display: initial;
                        appearance: none;
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                        background: none;
                        border: 0;
                    }
                }
            }
        }

        &.has-mobile-styles {

            .filter-details,
            .search-details {

                @media print, screen and (max-width: $threshold-size) {
                    display: none !important;
                }
            }

            .accordion-item--starts_with {

                .accordion-content {

                    @media print, screen and (max-width: $threshold-size) {
                        padding: 0;
                    }
                }
            }
        }

        &--active {

            .accordion-item {
                display: block;


                .accordion-content {
                    padding: 0 16px 16px 16px;
                }

                &--starts_with {

                    .accordion {

                        &-mobile-title {
                            display: none;
                        }

                        &-title {
                            display: block;
                        }

                        &-content {
                            padding: 16px;
                        }
                    }
                }
            }

            .section-drawer-body {
                padding: 0;
            }
        }

        .section-drawer-body {

            &__filters {
                padding: 0;

                @media print, screen and (max-width: $threshold-size) {
                    border: none;
                }
            }
        }
    }

    .filters-toggle {
        padding: 16px;
        margin-bottom: 32px;
        display: block;

        @media print, screen and (min-width: $threshold-size) {
            display: none;
        }
    }
}
