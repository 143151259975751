.conditions-directory {

    .accordion {

        background: none;

        a {

            color: $black;
            text-decoration: none;
        }

        .disorder-name {

            margin-bottom: 0;
        }

        &.lists {

            li {

                border-bottom: 2px solid $gray;
            }

            .accordion-content {

                border: 1px solid $gray;
                border-radius: 8px;
                margin: rem(16) 0;
            }

            .accordion-title {

                border: 0;
                padding: 1rem 0;

                &::before {

                    transform: rotate(90deg);
                }
            }

            .is-active {

                .accordion-title::before {

                    transform: rotate(-90deg) translate(0, -7.5px);
                }
            }
        }

        .disorder {

            border-bottom: 1px solid $gray;
            margin-bottom: rem(16);

            &:last-child {

                border-bottom: none;
                margin-bottom: 0;
            }
        }
    }
}
