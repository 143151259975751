/*====================================================
	main content area
====================================================*/
.main-content-area {
    margin-top: -42px;
    margin-bottom: 42px;
}

.main-content-area .inner-wrap {
    background: #f3f3f3;
    box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.2), 2px -2px 6px 0 rgba(0, 0, 0, 0.12), -2px -2px 6px 0 rgba(0, 0, 0, 0.12);
}

.content-wrap {
    background: $white;
    padding: 42px 15px;
}

.post-entry {
    margin-bottom: 42px;
    position: relative;
    overflow: hidden;
}

.post-entry .featured {
    position: absolute;
    top: -30px;
    right: -30px;
    width: 60px;
    height: 60px;
    background: #ff5722;
    transform: rotate(45deg);
    z-index: 9;
}

.post-entry .featured-media-container {
    height: 220px;
    width: 100%;
    background-size: cover;
    background-position: center;
    background-color: #666;
}

.post-entry .featured-media-container.media-embeded {
    background-color: $black;
}

.post-entry .featured-media-container iframe {
    max-height: 100%;
    vertical-align: middle;
}

.post-entry .title {
    margin-top: 0;
    margin-bottom: 7px;
}

.post-entry .title a {
    color: #262626;
}

.post-entry .title a:hover {
    color: #ff5722;
}

.post-entry .meta {
    font-size: 14px;
    color: $gray;
    margin-bottom: 14px;
}

.post-entry .meta a {
    color: $gray;
}

.post-entry .meta a:hover,
.post-entry .meta a:focus {
    color: #ff5722;
}

.post-entry .meta span {
    margin-right: 14px;
}

.post-entry .meta span:last-child {
    margin-right: 0;
}

.post-entry .meta span i {
    margin-right: 4px;
}

.post-entry .meta span.date,
.post-entry .meta span.comment {
    white-space: nowrap;
}

.post-entry .meta span.tag-list a {
    white-space: nowrap;
}

.post-entry .post-content {
    margin-bottom: 0;
}

/*====================================================
	pagination
====================================================*/
.pagination-wrap {
    padding-top: 32px;
    border-top: 1px solid $very-light-gray;
}

.pagination {
    display: block;
    min-height: 24px;
    margin: 0;
    text-align: center;
    position: relative;
}

.pagination .newer-posts {
    position: absolute;
    left: 0;
    top: 0;
}

.pagination .newer-posts i {
    margin-right: 7px;
}

.pagination .older-posts {
    position: absolute;
    right: 0;
    top: 0;
}

.pagination .older-posts i {
    margin-left: 7px;
}

.pagination a {
    color: $white;
    background: #181f23;
    padding: 0 14px;
    height: 35px;
    line-height: 35px;
}

.pagination a:hover {
    background: #ff5722;
    color: $white;
}

/*====================================================
	sidebar
====================================================*/
.sidebar {
    padding: 42px 15px;
}

.widget {
    margin-bottom: 28px;
    padding-bottom: 28px;
    border-bottom: 1px solid #e3e3e3;
}

.widget:last-child {
    margin-bottom: 0;
    border-bottom: 0;
}

.widget .title {
    margin-bottom: 28px;
    text-transform: uppercase;
    line-height: 14px;
    letter-spacing: 1px;
    padding-left: 7px;
    border-left: 14px solid #262626;
}

.widget .tag-cloud a {
    font-size: 14px;
    color: $white;
    background-color: #181f23;
    margin: 0 4px 4px 0;
    display: inline-block;
    padding: 2px 14px;
}

.widget .tag-cloud a:hover,
.widget .tag-cloud a:focus {
    background: #ff5722;
}

.widget .recent-post .recent-single-post {
    margin-bottom: 14px;
}

.widget .recent-post .recent-single-post a .post-thumb {
    width: 80px;
    height: 80px;
    margin-right: 14px;
    background-size: cover;
    background-position: center;
    background-color: #e6e8e9;
}

.widget .recent-post .recent-single-post a .post-thumb i {
    font-size: 24px;
    text-align: center;
    width: 80px;
    line-height: 80px;
}

.widget .recent-post .recent-single-post a .post-info h4 {
    margin-bottom: 0;
    transition: all 0.25s;
}

.widget .recent-post .recent-single-post a .post-info .date {
    font-size: 14px;
    color: #b0b0b0;
    transition: all 0.25s;
}

.widget .recent-post .recent-single-post a .post-info .date i {
    margin-right: 7px;
}

.widget .recent-post .recent-single-post a:hover .post-info h4 {
    color: #ff5722;
}

.widget .recent-post .recent-single-post a:hover .post-info .date {
    color: #ff5722;
}

.widget .ad-2 {
    text-align: center;
}

.widget .ad-2 img {
    max-width: 100%;
}

.widget .fb {
    text-align: center;
}

.widget .tweets {
    text-align: center;
}

.widget .social {
    padding: 0;
    margin: 0;
}

.widget .social li {
    list-style: none;
    display: inline-block;
    margin: 0 4px 7px 0;
}

.widget .social li a i {
    width: 28px;
    height: 28px;
    line-height: 28px;
    color: #707070;
    text-align: center;
    transition: all 0.25s;
    font-size: 18px;
}

.widget .social li a:hover i {
    color: #ff5722;
}

.widget .newsletter .input-group {
    display: block;
    margin-top: 14px;
}

.widget .newsletter .input-group input[type="email"] {
    width: 100%;
}

/*====================================================
	single post
====================================================*/
.single-wrap {
    padding: 0 15px;
}

.single-post-entry {
    margin-bottom: 0;
}

.single-post-entry .title {
    margin-top: 0;
    margin-bottom: 7px;
    font-weight: 700;
}

.single-post-entry .meta {
    margin-bottom: 28px;
}

.single-post-entry .featured-media-container {
    height: auto;
    margin-bottom: 28px;
}

.single-post-entry .featured-media-container img {
    width: 100%;
}

.single-post-entry .post-content::first-letter {
    float: left;
    font-size: 3.5em;
    margin-right: 7px;
    text-transform: uppercase;
    font-weight: 700;
    color: #262626;
    line-height: 1;
}

.single-post-entry img {
    max-width: 100%;
}

.share-wrap {
    margin-bottom: 42px;
    padding-bottom: 42px;
    border-bottom: 1px solid $very-light-gray;
}

.share-wrap-inner {
    text-align: center;
}

.share-wrap-inner ul {
    padding: 0;
    margin: 0;
}

.share-wrap-inner ul li {
    list-style: none;
    display: inline-block;
    margin: 0 0 4px 0;
}

.share-wrap-inner ul li .facebook {
    background: #3b5998;
}

.share-wrap-inner ul li .twitter {
    background: #55acee;
}

.share-wrap-inner ul li .google-plus {
    background: #dd4b39;
}

.share-wrap-inner ul li .pinterest {
    background: #cc2127;
}

.share-wrap-inner ul li a {
    display: block;
    color: $white;
    padding: 2px 0;
    width: 120px;
    white-space: nowrap;
}

.share-wrap-inner ul li a i {
    margin-right: 7px;
}

.share-wrap-inner ul li a:hover {
    background: #181f23;
}

.about-author {
    margin-bottom: 42px;
}

.about-author .avatar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
}

.about-author .details .author a {
    color: #262626;
}

.about-author .details .author a:hover,
.about-author .details .author a:focus {
    color: #ff5722;
}

.about-author .details .bio {
    margin-top: 7px;
}

.about-author .details .meta-info {
    margin-top: 7px;
}

.about-author .details .meta-info span {
    white-space: nowrap;
    margin-right: 14px;
    color: #b0b0b0;
}

.about-author .details .meta-info span:last-child {
    margin-right: 0;
}

.about-author .details .meta-info span i {
    margin-right: 7px;
}

.about-author .details .meta-info span a {
    color: #b0b0b0;
}

.about-author .details .meta-info span a:hover,
.about-author .details .meta-info span a:focus {
    color: #ff5722;
}

.prev-next-wrap {
    padding-bottom: 42px;
    margin-bottom: 42px;
    border-bottom: 1px solid $very-light-gray;
    overflow: hidden;

    .prev-next-wrap-item {
        padding: 0 rem(7px);
    }

    a {
        color: $white;
        text-decoration: inherit;
        margin-top: rem(16px);
        background-color: $black;

        &:hover,
        &:focus {

            .title {
                text-decoration: underline;
            }
        }

        .blog-nav-image {
            height: 150px;
            overflow: hidden;

            img {
                width: 100%;
            }
        }

        .blog-nav-excerpt {
            word-break: break-all;
        }

        .prev-next-inner {
            padding: rem(28px);
        }

        &.next-post {
            text-align: right;
        }
    }
}


.single-post-entry.page .title {
    margin-bottom: 28px;
}

.single-post-entry.page p:last-child {
    margin-bottom: 0;
}

.cover-wrap {
    padding: 0 15px;
    margin-bottom: 48px;
}

.cover-inner {
    border-bottom: 1px solid $very-light-gray;
    text-align: center;
    background: #181f23;
    background-size: cover;
    background-position: center;
    color: $white;
}

.cover-inner .overlay {
    padding: 42px 15px;
}

.cover-inner.has-image .overlay {
    background: rgba(0, 0, 0, 0.6);
}

.tag-title {
    color: $white;
}

.tag-description {
    margin-top: 14px;
    margin-bottom: 0;
}

.avatar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: $white;
    padding: 2px;

    &.tiny-avatar {
        width: 36px;
        height: 36px;
    }
}

.author-name {
    color: $white;
    margin-top: 7px;
}

.meta-info span {
    margin-right: 14px;
}

.meta-info span:last-child {
    margin-right: 0;
}

.meta-info span i {
    margin-right: 7px;
}

.meta-info span a {
    color: $white;
}

.meta-info span a:hover {
    color: #ff5722;
}

.bio {
    margin-top: 14px;
}

#back-to-top {
    position: fixed;
    bottom: 21px;
    right: 14px;
    display: none;
}

#back-to-top i {
    background: #ff5722;
    width: 35px;
    height: 35px;
    line-height: 35px;
    font-size: 16px;
    text-align: center;
    color: $white;
}

/*====================================================
	error page
====================================================*/
.error-block {
    text-align: center;
}

.error-block .error-code {
    margin-bottom: 28px;
    font-size: 56px;
}

.error-block .error-code span {
    background: #ff5722;
    color: $white;
    padding: 7px 14px;
}
