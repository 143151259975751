.disbelief-blocks {

    &__block {

        padding-top: 3rem;

        &--black {
            color: $black;
        }

        &-description {
            padding: 1rem;
            color: $white;
            border-radius: 0.2rem;

            @media screen and (max-width: 64em) {
                margin-top: 2rem;
            }
        }

        &-image {

            @media screen and (max-width: 40em) {
                padding: 0.5rem;
            }
        }
    }

    &__separator {
        padding-bottom: 3rem;
        border-bottom: 10px dotted $black;
    }
}

.bulleted-section {

    padding: 3rem 0;

    &__header {
        color: $white;
        background-color: $black;
        padding: 1rem 0.5rem;
        border-radius: 1rem;
        text-align: center;
        position: relative;
        margin-bottom: 3rem;

        &::before {
            content: "";
            position: absolute;
            right: 10rem;
            top: 100%;
            width: 0;
            height: 0;
            border-top: 15px solid transparent;
            border-right: 0 solid $black;
            border-bottom: 15px solid transparent;
            border-left: 60px solid $black;
            transform: rotate(35deg) translateY(-14px);

            @media screen and (max-width: 64em) {
                right: 4rem;
            }
        }

    }

    &__list {
        background-color: $white;
        border-radius: 1.5rem;
        padding: 1rem;
        margin: 1rem 0 2rem;

        @media screen and (max-width: 64em) {
            margin-top: 4rem;
        }

        &--image {
            margin-top: -6rem;
        }
    }

    &__item {
        color: #a17bb0;
        margin-left: 1.5rem;

        &::before {
            content: "•";
            color: #a0c75f;
            margin-left: -1.5rem;
            font-size: 3rem;
            line-height: 28px;
            position: absolute;
        }
    }

    &__description,
    &__conclusion {

        @media screen and (max-width: 64em) {
            text-align: center;
        }
    }
}

.disbelief-conclusion {

    &__subheader {
        color: #a17bb0;
    }
}
