/* Section Card Grid */

.sectionCardGrid {
    margin: 0.5rem auto;

    &--article {
        background: #fff;
        border-radius: 0.25rem;
        margin-bottom: 1.5rem;
        padding: 1rem;

        &Image {
            display: inline-block;
            height: auto;
            min-height: 3.5rem;
            min-width: 3.5rem;
            width: 100%;

            &[src=""] {
                border: 0.125rem dotted $teal;
            }
        }

        &Headline {
            margin-top: 1rem;
        }

        &Link {

            @include refresh-text-link;
        }

    }

    .sectionCardGrid--overview {
        margin: 1rem auto;
    }
}

.outlinedArticle {
    border: 1px solid $gray;
}
