.drawer {
    display: none;

    &__header {
        padding: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #ddd;
    }

    &__close {
        margin: 0;
        padding: 0;
        border: none;
        background-color: transparent;
        cursor: pointer;
        background-image: url("data:image/svg+xml,%0A%3Csvg width='15px' height='16px' viewBox='0 0 15 16' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='2.-Menu' transform='translate(-15.000000, -13.000000)' stroke='%23000000'%3E%3Cg id='Group' transform='translate(15.000000, 13.521000)'%3E%3Cpath d='M0,0.479000129 L15,14.2971819' id='Path-3'%3E%3C/path%3E%3Cpath d='M0,14.7761821 L15,-1.24344979e-14' id='Path-3'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
        width: 15px;
        height: 15px;
    }

    &__wrapper {
        position: fixed;
        top: 0;
        right: 0;
        left: auto;
        bottom: 0;
        height: 100%;
        width: 100%;
        max-width: 500px;
        z-index: 9999;
        overflow: auto;
        transition: transform 0.3s;
        will-change: transform;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        -webkit-transform: translateX(103%);
        transform: translateX(103%); /* extra 3% because of box-shadow */
        -webkit-overflow-scrolling: touch; /* enables momentum scrolling in iOS overflow elements */
        box-shadow: 0 2px 6px #777;
    }

    &__content {
        position: relative;
        overflow-x: hidden;
        overflow-y: auto;
        height: 100%;
        flex-grow: 1;
        padding: 1.5rem;
    }

    &.is-active {
        display: block;
    }

    &.is-visible {

        .drawer__wrapper {
            -webkit-transform: translateX(0);
            transform: translateX(0);
        }

        .drawer__overlay {
            opacity: 0.5;
        }
    }

    &__overlay {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 200;
        opacity: 0;
        transition: opacity 0.3s;
        will-change: opacity;
        background-color: #000;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    &__title {

        max-width: 320px;

        @include breakpoint(medium) {

            max-width: 425px;
        }
    }

    .affected-breeds-drawer {

        &__breed-list {
            list-style: none;
            margin-left: 0;
        }
    }
}
