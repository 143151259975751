.article-card {

    &.cell {

        @include breakpoint(medium) {

            margin-bottom: rem(40px);
        }
    }

    &--meta {
        color: $gray;
        margin-bottom: rem(4px);
        display: flex;
        gap: 6px;
        align-items: center;

        &-blue {

            .badge--text {
                background: #9edede;
            }
        }

        &-green {

            .badge--text {
                background: #96c950;
            }
        }

        &-purple {

            .badge--text {
                background: $purple-white;
            }
        }

        .badge--text {
            padding-bottom: rem(4px);
            margin-right: rem(16px);
        }
    }

    &--author {
        color: $gray;

        a {
            color: inherit;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    &--content {

        p.line-clamp {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            word-wrap: break-word;
        }

        .no-line-clamp {
            -webkit-line-clamp: unset;
        }

        a.readmore--link,
        a.shop_url {
            color: $black;
            font-weight: 600;
            text-decoration: none;

            &:hover,
            &:focus {
                text-decoration: underline;
            }
        }
    }


    #resources-insights & {

        &--content {

            p.line-clamp {
                -webkit-line-clamp: 6;
            }
        }

        &--excerpt {

            @include breakpoint(large only) {
                margin-bottom: 32px;
            }
        }
    }

    &--title {


        a {
            color: $black;
            text-decoration: none;

            &:hover,
            &:focus {
                color: $black;
                text-decoration: underline;
            }
        }
    }

    &--image {
        aspect-ratio: 4/3;
        border-radius: 16px;
        overflow: hidden;
        margin-bottom: rem(32px);

        &--main-feature {
            margin-bottom: 0;
        }

        img {
            width: 100%;
            height: auto;

            @include breakpoint(large) {
                height: 100%;
                width: auto;
            }

        }
    }
}

.category--articles-list {

    .article-card {

        &--author,
        &--excerpt,
        .readmore--link {

            @include breakpoint(small only) {

                display: none;
            }
        }

        &--content {

            @include breakpoint(small only) {

                border-bottom: 1px solid $mid-gray;
            }
        }
    }
}
